import Vue from 'vue'
import Vuex from 'vuex'
import auth_users from '@/stores/auth_users'
import common from '@/stores/common'
import financing from '@/stores/modules/financing'
import news from '@/stores/modules/news'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth_users,
    common,
    financing,
    news
  }
})

export default store
