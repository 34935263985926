import { render, staticRenderFns } from "./ProfileSetting.vue?vue&type=template&id=f237b61c&scoped=true&"
import script from "./ProfileSetting.vue?vue&type=script&lang=js&"
export * from "./ProfileSetting.vue?vue&type=script&lang=js&"
import style0 from "./ProfileSetting.vue?vue&type=style&index=0&id=f237b61c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f237b61c",
  null
  
)

export default component.exports