const NO_INTERNET = "NO INTERNET RETRY"
const UNAUTHENTICATED = "Unauthenticated"
const FORBIDDEN = "No permission"
const LOGIN_FAILED = "Login failed"
const SERVER_ERROR = "Server error"
const INVALID_INPUT = "invalid input";


export {
  NO_INTERNET,
  UNAUTHENTICATED,
  FORBIDDEN,
  LOGIN_FAILED,
  SERVER_ERROR,
  INVALID_INPUT
}
