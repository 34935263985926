<template>
  <div>
    <div>
        <div class="title-bar d-flex align-center font-weight-bold m__bottom--28">
            <div v-if="hasBack" class="redirect-back fs-14" @click="$router.go(-1)">
                <img :src="require('@/assets/images/icons/ic_arrow_lefts.svg')" alt="">
                <div class="d-inline m__left--8">戻る</div>
            </div>
            <div class="fs-18 font-weight-bold" :class="{'m__left--12': hasBack}">
                {{ title }}
            </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TitleComponent",
  components: {

  },
  props: {
    title: String,
    hasBack: Boolean,
  },
  computed: {
    localStore() {
      return localStorage
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.redirect-back {
    cursor: pointer;
}
</style>
