<template>
  <v-card class="overflow-hidden rounded-0 nav-custom">
    <v-app-bar
      absolute
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <img :src="logo" alt="" style="cursor: pointer" @click="$router.push({ name : 'home'})">

      <v-spacer></v-spacer>

      <div class="list-menu d-flex align-center" v-if="isLogin">
        <div v-if="$store.getters['authUser'] && $store.getters['authUser'].role === 1">
          <v-btn
            v-for="menu, index in sideMenus"
            :key="'menu'+ index"
            class="btn-nav-custom"
            :class="[menu.class, menu.routes.includes($route.name) ? 'btn-nav-active' : '']"
            @click="redirectTo(menu.router)"
          >
            {{menu.text}}
          </v-btn>
        </div>

        <div v-if="$store.getters['authUser'] && $store.getters['authUser'].role === 2">
          <v-btn
            v-for="menu, index in investorMenus"
            :key="'menu'+ index"
            class="btn-nav-custom"
            :class="[menu.class, menu.routes.includes($route.name) ? 'btn-nav-active' : '']"
            @click="redirectTo(menu.router)"
          >
            {{menu.text}}
          </v-btn>
        </div>

        <v-btn class="btn-nav-custom m__right--8" style="width: 23px" @click="() => { $router.push({name: 'Notice list'}).catch(()=> {}); this.news = []}">
          <img :src="icBell" alt="" style="">
          <div v-show="news.length" class="badge-custom" :class="{ small : news.length < 100 ,large : news.length >= 100}">
            <span v-if="news.length >= 100">99+</span>
            <span v-else>{{news.length}}</span>
          </div>
        </v-btn>
        <div class="profile d-flex align-center" @click="reclick">
          <!-- <div>
            <img :src="avatar" alt="">
          </div> -->
          <div class="m__left--12" v-if="authUser">
            <div class="profile-name">
              {{ authUser.name }}
            </div>
            <div class="profile-name-company">
              {{ authUser.organization_name }}
            </div>
          </div>
        </div>
        <v-menu
          left
          bottom
          nudge-bottom="50px"
          v-model="isOpened"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              class="btn-nav-custom btn-arrow-down m__top--5"
              ref="dropdown"
            >
              <img :src="icArrowDown" alt="">
            </v-btn>
          </template>

          <v-list class="dropdown-menu font-weight-bold">
            <v-list-item @click="redirectTo('Account Setting')">
              <v-list-item-title>
                <span>アカウント設定</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="redirectTo('Notice list')">
              <v-list-item-title>
                <span>お知らせ一覧</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="authUser.role === 1" @click="connectFreee">
              <v-list-item-title>
                <span>Freeeと連携</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="redirectTo('contact')">
              <v-list-item-title>
                <span>お問い合わせ</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="redirectTo('Delete Account')">
              <v-list-item-title>
                <span>退会</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title>ログアウト</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
  </v-card>
</template>
<script>
import { plan_navbar, investor_navbar , news_status} from "./../../commons/plan_const";
import {
  ACTION_USER_LOGOUT
} from '@/stores/auth_users/actions'
export default {
  name: "NavBar",
  props: {
    isLogin: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      logo: require('@/assets/images/logo.svg'),
      icBell: require('@/assets/images/icons/ic_bell.svg'),
      icArrowDown: require('@/assets/images/icons/ic_arrow_down.svg'),
      avatar: require('@/assets/images/avatar.png'),
      sideMenus: plan_navbar,
      investorMenus: investor_navbar,
      isOpened: false,
      news : []
    }
  },
  computed: {
    authUser() {
      return this.$store.getters['authUser']
    }
  },
  methods: {
    redirectTo(routerName){
      if(routerName && this.$route.name != routerName) {
        this.getListNews()
        this.$router.push({name: routerName}).catch(()=>{})
      }
    },
    logout() {
      this.$store.dispatch(ACTION_USER_LOGOUT)
        .then(() => {
          this.$router.go(0)
          this.$toast.success('ログアウトしました')
        })
        .catch(() => {})
    },
    reclick() {
      this.isOpened = true
    },
    connectFreee() {
      window.location.href= process.env.VUE_APP_FREEE;
    },
    // api
    getListNews() {
      const isUpdate = true;
      const params = {
        status : news_status.unread
      };
      this.$store.dispatch('actionSetListNews', { isUpdate, params }).then((response) => {
          this.news = response;
      }).catch(()=> {});
    }
  },
  created() {
    if (this.isLogin) {
      this.getListNews();
    }
  }

}
</script>

<style lang="scss">
@import "@/assets/scss/index.scss";
.nav-custom {
  box-shadow: 0px 1px 0px #EAEDEB!important;
  height: 52px !important;
  header {
    height: 52px !important;
  }
  .v-toolbar__content {
    padding: 0 121px;
    background-color: $white !important;
    height: 52px !important;
  }
  .btn-nav-custom {
    background-color: $white !important;
    border: none !important;
    box-shadow: unset;
    color: $secondary_color !important;
    font-size: 14px !important;
    line-height: 20.27px !important;
    font-weight: 400 !important;
    border-radius: 0;
    height: 100% !important;
    padding: 12px 8px 16px 8px !important;
  }

  .btn-nav-custom:before {
    background-color: unset !important;
  }

  .btn-nav-active {
    font-weight: 700 !important;
    color: $primary_black !important;
    border-bottom: 3px solid $corporate_color_1 !important;
  }

  .badge-custom {
    position: absolute;
    left: 10px;
    bottom: 10px;
    background-color: $primary_red;
    height: 18px;
    min-width: 18px;
    font-weight: 700;
    font-size: 10px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    &.small {
      border-radius: 50%;
    }
    &.large {
      border-radius: 10px;
      padding: 0 4px;
    }
  }
  .profile {
    font-weight: 700;
    cursor: pointer;
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: solid 1px #ccc;
    }
    .profile-name {
      color: $primary_black;
      line-height: 20.27px;
      font-size: 14px;
    }
    .profile-name-company {
      color: $primary_secondary;
      line-height: 11.5px;
      font-size: 10px;
    }
  }

  .list-menu {
    height: 100%;
    & > div {
      height: 100%;
    }
  }

  .btn-arrow-down {
    width: 16px !important;
    margin-left: 8px;
    padding: 0 !important;
  }
}
@media only screen and (max-width: 768px) {
  .nav-custom {
    .v-toolbar__content {
      padding: 0 20px !important;
    }
  }
}

.dropdown-menu {
  .v-list-item__title {
    line-height: 48px !important;
  }
  .v-list-item__title a, div {
    cursor: pointer;
    color: #333333;
    text-decoration: none;
  }

  .v-list-item:hover {
    background-color: #F3F4F3;
    transition: 0.5s;
  }
}
</style>

