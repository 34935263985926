<template>
    <div class="content-popup">
        <div class="form-input m__top--16">
            <label>招待者のEメールアドレス</label>
            <div class="form-control">
                <div class="form-control-input">
                    <input class="form-control w-480-px" placeholder="carte@example.com" v-model.trim="shareHolder.inviteEmail" @input="v$.shareHolder.inviteEmail.$reset">
                </div>
            </div>
            <div v-if="v$.shareHolder.inviteEmail.required.$invalid" class="error-text">メールは必須です。</div>
            <div v-else-if="v$.shareHolder.inviteEmail.email.$invalid" class="error-text">有効なメールアドレスをご入力ください。</div>
            <div v-else-if="v$.shareHolder.inviteEmail.maxLength.$invalid" class="error-text">メールアドレスは255文字以内でご入力ください。</div>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import {required, email, maxLength} from "@vuelidate/validators"

export default {
    props: {
        shareholder: Object,
    },
    data() {
        return {
            shareHolder: this.shareholder
        }
    },
    setup: () => ({ v$: useVuelidate({$lazy: true}) }),
    validations() {
        return {
            shareHolder: {
                inviteEmail: {
                    required,
                    email,
                    maxLength: maxLength(255),
                },
            }
        }
    },
    methods: {
        validateInput() {
            this.v$.$touch()
            return !this.v$.$invalid;
        },
    },
}
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/_variables.scss";
    .content-popup {
        border-left: 4px solid #F3F4F3;
        padding-left: 24px;
        margin: 0 40px;
    }
    .search-result{
        position: absolute;
        top: 55px;
        border: 1px solid #EAEDEB;
        box-shadow: 0px 4px 8px rgba(173, 182, 177, 0.1);
        border-radius: 4px;
        overflow: auto;
        max-height: 344px;
        padding: 12px 28px;
        background: $white;
        z-index: 999;
        .item-result {
            padding: 4px;
            margin : 8px 0;
            text-align: left;
            cursor: pointer;
            &:hover {
                background-color: #F8F8F8;
            }
        }
    }
    .form-control-input {
        position: relative;
    }
    .popup-complete-content{
        font-weight: 500;
        font-size: 16px;
        line-height: 175%;
        text-align: center;
        margin-bottom: 24px;
    }
</style>
