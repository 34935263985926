<template>
    <div>
        <LoadingComponent v-if="loading"/>
        <div v-else class="main-container p__top--28 p__bottom--30">
            <TitleComponent :title="title" :hasBack="true" />
            <div class="main-content px-6 py-6">
                <div class="section-title pb-3">
                    <div class="inner-title fs-24 font-weight-bold pb-6">
                        {{ title }}
                    </div>
                </div>
                <div class="notice-content">
                    <v-tabs background-color="transparent" >

                        <v-tab href="#all" active-class="active-tab" >
                            全のお知らせ
                        </v-tab>
                        <v-tabs-slider color="#0D7B3F"></v-tabs-slider>
                        <v-tab-item value="all">
                            <table class="mt-4" v-if="noticeList.length">
                                <tr v-for="(item, idx) in noticeList" :key="idx">
                                    <th class="notice-date py-3 px-4 fs-14" :class="{ 'font-weight-bold': item.isRead === 0 }">{{ item.date }}</th>
                                    <th class="notice-tag px-4 fs-10"><span class="px-3 py-1" :style="{ background: findTag(item.tag).color }">{{ findTag(item.tag).tag }}</span></th>
                                    <!-- <th class="notice-title text-left fs-14"><router-link :to="{ name: 'Notice detail', query: { id: item.id, type: item.tag } }">{{ item.title }}</router-link></th> -->
                                    <th class="notice-title text-left fs-14" style="line-break: anywhere">{{ item.title }}</th>
                                </tr>
                            </table>
                            <div class="not-noti" v-else>
                                {{ $t("messages.have_not_notification") }}
                            </div>
                        </v-tab-item>

                        <v-tab :href="'#new-startup'" active-class="active-tab">
                            {{ findTag(tags.news_startup).text }}
                        </v-tab>
                        <v-tabs-slider color="#0D7B3F"></v-tabs-slider>
                        <v-tab-item value="new-startup">
                            <table class="mt-4" v-if="findNewsWithType(tags.news_startup) && findNewsWithType(tags.news_startup).length">
                                <tr v-for="(item, idx) in findNewsWithType(tags.news_startup)" :key="idx">
                                    <th class="notice-date py-3 px-4 fs-14" :class="{ 'font-weight-bold': item.isRead === 0 }">{{ item.date }}</th>
                                    <th class="notice-tag px-4 fs-10"><span class="px-3 py-1" :style="{ background: findTag(item.tag).color }">{{ findTag(item.tag).tag }}</span></th>
                                    <!-- <th class="notice-title text-left fs-14"><router-link :to="{ name: 'Notice detail', query: { id: item.id, type: item.tag } }">{{ item.title }}</router-link></th> -->
                                    <th class="notice-title text-left fs-14" style="line-break: anywhere">{{ item.title }}</th>
                                </tr>
                            </table>
                            <div class="not-noti" v-else>
                                {{ $t("messages.have_not_notification") }}
                            </div>
                        </v-tab-item>

                        <v-tab :href="'#new-alert'" active-class="active-tab">
                            {{ findTag(tags.news_alert).text }}
                        </v-tab>
                        <v-tabs-slider color="#0D7B3F"></v-tabs-slider>
                        <v-tab-item value="new-alert">
                            <table class="mt-4" v-if="findNewsWithType(tags.news_alert) && findNewsWithType(tags.news_alert).length">
                                <tr v-for="(item, idx) in findNewsWithType(tags.news_alert)" :key="idx">
                                    <th class="notice-date py-3 px-4 fs-14" :class="{ 'font-weight-bold': item.isRead === 0 }">{{ item.date }}</th>
                                    <th class="notice-tag px-4 fs-10"><span class="px-3 py-1" :style="{ background: findTag(item.tag).color }">{{ findTag(item.tag).tag }}</span></th>
                                    <!-- <th class="notice-title text-left fs-14"><router-link :to="{ name: 'Notice detail', query: { id: item.id, type: item.tag } }">{{ item.title }}</router-link></th> -->
                                    <th class="notice-title text-left fs-14" style="line-break: anywhere">{{ item.title }}</th>
                                </tr>
                            </table>
                            <div class="not-noti" v-else>
                                {{ $t("messages.have_not_notification") }}
                            </div>
                        </v-tab-item>

                        <v-tab :href="'#new-user'" active-class="active-tab">
                            {{ findTag(tags.news_user).text }}
                        </v-tab>
                        <v-tabs-slider color="#0D7B3F"></v-tabs-slider>
                        <v-tab-item value="new-user">
                            <table class="mt-4" v-if="findNewsWithType(tags.news_user) && findNewsWithType(tags.news_user).length">
                                <tr v-for="(item, idx) in findNewsWithType(tags.news_user)" :key="idx">
                                    <th class="notice-date py-3 px-4 fs-14" :class="{ 'font-weight-bold': item.isRead === 0 }">{{ item.date }}</th>
                                    <th class="notice-tag px-4 fs-10"><span class="px-3 py-1" :style="{ background: findTag(item.tag).color }">{{ findTag(item.tag).tag }}</span></th>
                                    <!-- <th class="notice-title text-left fs-14"><router-link :to="{ name: 'Notice detail', query: { id: item.id, type: item.tag } }">{{ item.title }}</router-link></th> -->
                                    <th class="notice-title text-left fs-14" style="line-break: anywhere">{{ item.title }}</th>
                                </tr>
                            </table>
                            <div class="not-noti" v-else>
                                {{ $t("messages.have_not_notification") }}
                            </div>
                        </v-tab-item>

                        <v-tab :href="'#new-admin'" active-class="active-tab">
                            {{ findTag(tags.news_admin).text }}
                        </v-tab>
                        <v-tabs-slider color="#0D7B3F"></v-tabs-slider>
                        <v-tab-item value="new-admin">
                            <table class="mt-4" v-if="findNewsWithType(tags.news_admin) && findNewsWithType(tags.news_admin).length">
                                <tr v-for="(item, idx) in findNewsWithType(tags.news_admin)" :key="idx">
                                    <th class="notice-date py-3 px-4 fs-14" :class="{ 'font-weight-bold': item.isRead === 0 }">{{ item.date }}</th>
                                    <th class="notice-tag px-4 fs-10"><span class="px-3 py-1" :style="{ background: findTag(item.tag).color }">{{ findTag(item.tag).tag }}</span></th>
                                    <!-- <th class="notice-title text-left fs-14"><router-link :to="{ name: 'Notice detail', query: { id: item.id, type: item.tag } }">{{ item.title }}</router-link></th> -->
                                    <th class="notice-title text-left fs-14" style="line-break: anywhere">{{ item.title }}</th>
                                </tr>
                            </table>
                            <div class="not-noti" v-else>
                                {{ $t("messages.have_not_notification") }}
                            </div>
                        </v-tab-item>
                    </v-tabs>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TitleComponent from "@/components/common/Title.vue"
import LoadingComponent from "@/components/common/Loading.vue"
import { UserService } from '@/services'
import { news_type, news_tag } from '@/commons/plan_const'
import { formatDateJp } from '@/commons/helpers'

export default {
    name: "NoticeList",
    components: {
        TitleComponent,
        LoadingComponent
    },
    data() {
        return {
            title: 'お知らせ',
            noticeList: [],
            notiType : news_type,
            tags : news_tag,
            loading: false
        }
    },
    methods : {
        findTag(type = null) {
            return this.notiType.find(item => item.type == type);
        },
        findNewsWithType(type = null) {
            return this.noticeList.filter(item => type == item.tag);
        },
            // api
        getListNews() {
            this.loading = true;
            const isUpdate = true;
            const params = {
                limit : 10000
            }
            this.$store.dispatch('actionSetListNews', { isUpdate, params }).then((response) => {
                this.noticeList = response.map((item) => {
                    let obj = {};
                    obj.id = item.id;
                    obj.title = item.body;
                    obj.date = formatDateJp(item.created_at);
                    obj.tag = item.type;
                    return obj;
                });
                this.loading = false;
                this.readNews();
            }).catch(() => {
                this.loading = false;
            })
        },
        readNews() {
            UserService.readNews()
            .then(() => {})
            .catch(() => {})
        }
    },
    created(){
        this.getListNews();
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.main-content {
    background: $white;
    position: relative;
    box-shadow: 0px 4px 8px rgba(173, 182, 177, 0.1);
    border-radius: 4px;
}
.notice-date {
    color: #858585;
    font-weight: normal;
    white-space: nowrap;
    vertical-align: text-top;
}
.notice-tag {
    color: white;
    font-weight: 700;
    white-space: nowrap;
    vertical-align: text-top;

}
.notice-tag span {
    border-radius: 4px;
}
.notice-title {
    vertical-align: text-top;
}
.notice-title a {
    color: #333333;
    text-decoration: none;
    font-weight: 500;
}
.active-tab {
    font-weight: bold;
    color: #333333 !important;
}
</style>
<style lang="scss">
.notice-content .v-slide-group__wrapper {
    border-bottom: 1px solid #E2E2E2;
}
.not-noti {
    padding : 12px 0;
    font-size: 24px;
    font-weight: 700;
}
</style>
