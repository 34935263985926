const plan_navbar = [
    {
        router: 'home',
        class: 'm__right--8',
        text : 'ホーム',
        routes: ['home'],
    },
    {
        router: 'Capital Policies',
        class: 'm__right--8',
        text : '資本政策',
        routes: ['Capital Policies', 'Capital Policy', 'Edit Capital Policy'],
    },
    {
        router: 'Simulation',
        class: 'm__right--24',
        text : 'M&A',
        routes: ['Simulation', 'Simulation List'],
    },
]

const investor_navbar = [
    {
        router: 'home',
        class: 'm__right--8',
        text : 'ホーム',
        routes: ['home'],
    },
    {
        router: 'Investment destination list',
        class: 'm__right--8',
        text : '投資先一覧',
        routes: ['Investment destination list', 'Startup capital policy'],
    },
]

const ROLE_BOTH = 0
const ROLE_STARTUP = 1
const ROLE_INVESTOR = 2

const financing_methods = {
    common_share: '普通',
    preferred_share: '優先株'
}

const financing_status = {
    is_completed : 1,
    is_not_completed : 0
}

const news_type = [
    {
        type : 1,
        text : "資金調達",
        tag : "資金調達",
        color : "#99BFF7"
    },
    {
        type : 2,
        text : "アラート",
        tag : "アラート",
        color : "#F26893"
    },
    {
        type : 3,
        text : "新規参加",
        tag : "新規参加",
        color : "#9F7FED"
    },
    {
        type : 4,
        text : "運営から",
        tag : "運営から",
        color : "#339967"
    },
]

const news_tag = {
    news_startup : 1,
    news_alert : 2,
    news_user : 3,
    news_admin : 4,
}

const news_status = {
    unread : 1,
    read : 2
}

const header_table_shareholders = [
    { text: '株主', value: 'name' },
    { text: 'ラウンド', value: 'round' },
    { text: '出資額', value: 'amount' },
    { text: '出資時株価', value: 'stock_price' },
    { text: '種別', value: 'kinds' },
    { text: '顕在株比率', value: 'ratio_normal' },
    { text: '潜在株含む比率', value: 'ratio' },
]

const corporateColor = [
    '#F2F9F6', '#E6F2EC', '#CCE5D9', '#99CCB3', '#66B28D', '#339967'
]

const httpResponseErrorStatusCode = {
    'Not Found' : 404,
    'Internal Server Error' : 500,
}

const maxCoin = 10000000000000;

const homeStep = {
    stepDefault: 0,
    stepInviteInvestor: 1,
    stepConnectFreee: 2
}


const chartLabels = [
    {
        color: '#2C9664',
        title: '投資額'
    },
    {
        color: '#5567CA',
        title: '資産価値'
    },
    // {
    //     color: '#D95D84',
    //     title: '売却益'
    // },
]

const lineBarChartLabels = [
    {
        color: '#99BFF7',
        title: '売上'
    },
    {
        color: '#FAA4BF',
        title: '営業利益'
    },
    {
        color: '#4A93FF',
        title: '営業利益率'
    },
]

const xAxisLabel = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
const xAxisLabelYear = ['2011年', '2012年', '2013年', '2014年', '2015年', '2016年', '2017年', '2018年', '2019年', '2020年', '2021年', '2022年'];

export {
    plan_navbar,
    investor_navbar,
    ROLE_BOTH,
    ROLE_INVESTOR,
    ROLE_STARTUP,
    financing_methods,
    financing_status,
    news_type,
    news_tag,
    news_status,
    header_table_shareholders,
    corporateColor,
    httpResponseErrorStatusCode,
    maxCoin,
    homeStep,
    chartLabels,
    lineBarChartLabels,
    xAxisLabel,
    xAxisLabelYear
};
