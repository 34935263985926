<template>
    <div>
        <layout-app :isLogin="false"></layout-app>
        <div class="app-container">
            <div class="content login-content">
                <div class="logo">
                    <img :src="logo" alt="">
                </div>

            <div class="description-form fs-16 font-weight-bold m__top--48 color__primary_black">
                ログイン
            </div>
            <div class="w-550-px m__top--48 mx-auto">
                <div class="fs-14 font-weight-bold color__primary_black text-left">どちらでログインするかを選択してください。</div>
                <div class="d-flex justify-center align-center m__top--16">
                    <div class="radio-button d-flex justify-center align-center m__right--12"
                         @click="selectOption(radio.value)" v-for="radio,index in radios" :key="index"
                    >
                        <img :src="option == radio.value ? iconRadioActive : iconRadio" alt="">
                        <span :class="{ active : option == radio.value}"
                              class="radio-button-label m__left--12 m__top--0">{{ radio.text }}</span>
                    </div>
                </div>
            </div>
            <div v-if="option === 1" class="form-group m__top--48">
                <label class="m__bottom--8">Eメールアドレス</label>
                <input
                    v-model="v$.form.email.$model"
                    @input="v$.form.email.$reset"
                    class="form-control large"
                    placeholder="carte@example.com">
                <div v-if="v$.form.email.$errors">
                    <div v-if="v$.form.email.required.$invalid" class="error-text">
                        {{ $t("validation.login.email.required")  }}
                    </div>
                    <div v-else-if="v$.form.email.email.$invalid" class="error-text">
                        {{ $t("validation.login.email.email")  }}
                    </div>
                    <div v-else-if="v$.form.email.maxLength.$invalid" class="error-text">
                        {{ $t("validation.login.email.max-length")  }}
                    </div>
                </div>
                <label class="m__top--24 m__bottom--8">パスワード</label>
                <input
                    v-model="v$.form.password.$model"
                    @input="v$.form.password.$reset"
                    class="form-control large"
                    type="password"
                    placeholder="パスワード">
                <div v-if="v$.form.password.$errors">
                    <div v-if="v$.form.password.required.$invalid" class="error-text">
                        {{ $t("validation.login.password.required") }}
                    </div>
                </div>
            </div>

            <div v-if="option === 2" class="facebook-login w-550-px mx-auto mt-12">
                <div class="d-flex w-100 justify-center align-center py-2" @click="loginWithFacebook">
                    <img :src="icFacebook" alt="">
                    <div class="font-weight-bold fs-14 ml-3">Facebookでログイン</div>
                </div>
            </div>

            <div v-if="option === 1" class="m__top--24">
                <button-component
                    v-if="!$store.getters.isLoading"
                    :text="textButton"
                    :small=false
                    :func="login"
                    :width="widthButton"
                    ref="btnLogin"
                ></button-component>
                <div v-if="$store.getters.isLoading" class="donut"></div>
            </div>
                <div class="mt-12">
                    <div v-if="option === 1" class="text-link mt-0" @click="$router.push({name: 'forgot-password'}).catch(() => {})">パスワードをお忘れの場合はこちら</div>
                    <div class="text-link" @click="$router.push({name: 'register-step-2'})">新規サービス登録</div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import {required, maxLength, email } from "@vuelidate/validators"
import { ACTION_LOGIN_USER, ACTION_USER_LOGOUT } from "@/stores/auth_users/actions"
import ButtonComponent from "@/components/common/ButtonComponent.vue";
import LayoutApp from "@/components/layouts/Navbar.vue";

export default {
    name: "login-component",
    components : {
        ButtonComponent,
        LayoutApp
    },
    data(){
        return {
            logo : require('@/assets/images/svgs/logo_carte.svg'),
            iconRadio : require('@/assets/images/icons/ic_radio.svg'),
            iconRadioActive : require('@/assets/images/icons/ic_radio_active.svg'),
            icFacebook: require('@/assets/images/icons/ic_facebook.svg'),
            textButton : "ログイン",
            widthButton : "160px",
            step : 1,
            option : 1,
            radios : [
                {
                    value : 1,
                    text : "スタートアップとして使用する"
                },
                {
                    value : 2,
                    text : "エンジェル投資家として使用する"
                }
            ],
            form: {
                email: '',
                password: '',
            },
            isInvite: false
        }
    },
    setup: () => ({ v$: useVuelidate({$lazy: true}) }),
    validations() {
        return {
            form: {
                email: {
                    required,
                    email,
                    maxLength: maxLength(255)
                },
                password: {
                    required,
                }
            }
        }
    },
    watch: {
        option () {
            this.v$.form.$reset();
            this.form.email = ''
            this.form.password = ''
        }
    },
    methods: {
        login() {
            this.v$.form.$touch();
            if (!this.v$.form.$error) {
                const data = {
                    password: this.form.password,
                    email: this.form.email,
                    remember_token: false,
                    role: this.option,
                    token: this.$route.query.token || null,
                    invite: this.$route.query.invite || null,
                }
                if (!data.token) delete data.token
                if (!data.invite) delete data.invite
                this.$store.dispatch(ACTION_LOGIN_USER, data)
                .then(() => {
                    this.$router.push({name : 'home'})
                    this.$toast.success(this.$t("messages.success_login"));
                })
                .catch((err) => {
                    if (err.status === 401) {
                        this.$toast.error(this.$t("messages.error_login"))
                    } else {
                        this.$toast.error(err.data.message)
                    }
                })
            }
        },
        loginWithFacebook() {
            let token = ''
            if (this.$route.query.token && this.$route.query.invite === 'investor') {
                token = '?token=' + this.$route.query.token;
            }
            if (this.$route.query.email) {
              localStorage.setItem('emailInvestor', this.$route.query.email)
            }
            window.location.href = `${process.env.VUE_APP_API_URL}/api/auth-investor/redirect` + token
        },
        selectOption(value) {
            if (this.isInvite && value == 1)
              return;

            this.option = value;
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            const isAuthenticated = vm.$store.getters.accessToken || !!localStorage.getItem('accessToken')
            if (isAuthenticated && (!vm.$route.query.token || !vm.$route.query.invite)) {
                vm.$router.push({name: 'home'})
            }
            if (vm.$route.query.token && vm.$route.query.invite) {
                vm.$store.dispatch(ACTION_USER_LOGOUT)
            }
        })
    },
    created() {
        if (this.$route.query.invite === 'investor') {
            this.option = 2
            this.isInvite = true
        }
    },
    mounted() {
        window.addEventListener("keypress", (e) => {
            const key = e.which;
            if (key === 13) {
                this.$refs.btnLogin.$el.click();
            }
        })
    }
}
</script>
<style scoped lang="scss">
.description-form {
    line-height: 32px;
}
.text-link {
    font-weight: 700;
    font-size: 14px;
    line-height: 200%;
    letter-spacing: 0.005em;
    color: #333333;
    margin-top: 24px;
    text-decoration-line: underline;
    cursor: pointer;
}
.radio-button {
    cursor: pointer;
}
.facebook-login {
    cursor: pointer;
}
.facebook-login > div {
    background: #FBFBFB;
    border: 1px solid #EAEDEB;
    border-radius: 8px;
}
.facebook-login > div:hover {
    background: #d4d2d2;
}

</style>
