<template>
    <div>
        <div :class="{'app-container': isManualInput}">
            <div class="content home-content preview-content">
                <div class="main-content">
                    <div class="main-content-header">
                        <span class="content-title">プレビュー</span>
                        <div class="btn-simulation">
                        <button-component
                            :text="'プレビューを終了'"
                            :width="'160px'"
                            :height="'48px'"
                            :func="closePreview"
                        ></button-component>
                        </div>
                    </div>
                    <div class="row1 corporate-value">
                        <form-header
                        class="m__bottom--16"
                        :title="'企業価値'"
                        :isButton="false"     
                        :selected="6"
                        ></form-header>
                        <LineStackedBarChartComponent 
                        :dataColumn="corporateValueColumn"
                        :dataLine="corporateValueLine"
                        :labels="corporateValueLabel"
                        />
                    </div>
                    <div class="row2" style="position: relative">
                        <div class="doughnut-chart">
                        <form-header
                                class="m__bottom--16"
                                :title="'企業価値'"
                                :isButton="false"     
                                :actionButton="() => {}"
                        ></form-header>
                        <DoughnutChartComponent 
                            :labels="ratioShareholderLabel"
                            :data="ratioShareholderData"
                            :backgroundColors="doughnutColors"
                        />
                        </div>
                        <div class="shareholders-summary-table">
                        <form-header
                                class="m__bottom--16"
                                :title="'株主サマリー'"
                                :isButton="false"     
                                :actionButton="() => {}"
                        ></form-header>
                        <v-data-table
                            :headers="headers"
                            :items="shareholdersData"
                            disable-sort
                            disable-pagination
                            hide-default-footer
                            hide-default-header
                            :fixed-header="true"
                            no-data-text="データなし"
                        >
                            <template v-slot:header="{ props: { headers } }">
                                <thead>
                                    <tr>
                                    <th v-for="item in headers" :key="item.value" class="text-center custom-header px-0" :style="{ 'width': item.width }">
                                            <div>{{ item.text }}</div>
                                    </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:[`item.name`]="{ item }">
                                <td class="td-ellipsis w-20">
                                    <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on" class="text-left fs-12 font-weight-bold ellipsis">{{ item.name }}</div>
                                    </template>
                                    <span>{{ item.name }}</span>
                                    </v-tooltip>
                                </td>
                            </template>
                            <template v-slot:[`item.round`]="{ item }">
                                <td class="td-ellipsis w-15">
                                    <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on" class="text-center fs-12 font-weight-bold ellipsis">{{ item.round }}</div>
                                    </template>
                                    <span>{{ item.round }}</span>
                                    </v-tooltip>
                                </td>
                            </template>
                            <template v-slot:[`item.amount`]="{ item }">
                                <td class="td-ellipsis w-20">
                                    <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on" class="text-right fs-12 font-weight-bold ellipsis">¥{{ item.amount }}</div>
                                    </template>
                                    <span>¥{{ item.amount }}</span>
                                    </v-tooltip>
                                </td>
                            </template>
                            <template v-slot:[`item.stock_price`]="{ item }">
                                <td class="td-ellipsis">
                                    <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on" class="text-right fs-12 font-weight-bold ellipsis">¥{{ item.stock_price }}</div>
                                    </template>
                                    <span>¥{{ item.stock_price }}</span>
                                    </v-tooltip>
                                </td>
                            </template>
                            <template v-slot:[`item.kinds`]="{ item }">
                                <td class="td-ellipsis w-25">
                                    <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on" class="text-center fs-12 font-weight-bold ellipsis">{{ item.kinds }}</div>
                                    </template>
                                    <span>{{ item.kinds }}</span>
                                    </v-tooltip>
                                </td>
                            </template>
                            <template v-slot:[`item.ratio`]="{ item }">
                                <td class="td-ellipsis w-10">
                                    <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on" class="text-right fs-12 font-weight-bold ellipsis">{{ item.ratio }}%</div>
                                    </template>
                                    <span>{{ item.ratio }}%</span>
                                    </v-tooltip>
                                </td>
                            </template>
                        </v-data-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ButtonComponent from "@/components/common/ButtonComponent.vue";
import DoughnutChartComponent from "@/components/charts/DoughnutChartComponent.vue";
import LineStackedBarChartComponent from "@/components/charts/LineStackedBarChartComponent.vue";
import FormHeader from "@/components/Home/FormHeader.vue"; 

import { numberWithCommas } from "./../../commons/helpers";
import { header_table_shareholders, corporateColor } from '@/commons/plan_const';
import { lineStackedBarChartDataColumn } from '@/commons/chart';
import moment from 'moment';

export default {
    name : "HomeScreen",
    components : {
        ButtonComponent,
        DoughnutChartComponent,
        LineStackedBarChartComponent,
        FormHeader,
    },
    props: {
        data: {
            type: [Array, Object],
        },
        // isPreviewed: {
        //     type: Boolean,
        //     default: false
        // },
        isManualInput: {
            type: Boolean
        }
    },
    data () {
            return {
                preview: false,
                headers : header_table_shareholders,
                corporateValueLine : [],
                corporateValueColumn : [],
                corporateValueLabel : [],
                ratioShareholderLabel : [],
                ratioShareholderData : [],
                doughnutColors: ['#66b28d', '#7cccd2', '#b49cf8', '#e7e7e7'],
                shareholdersData : [],
                dataRound : null,
            }
    },
    methods: { 
        newData() {
            const dataRound = this.data;
            this.dataRound = dataRound;
            if (dataRound && Object.keys(dataRound).length) {
                this.corporateValueLabel.push(dataRound.execution_date)
                this.corporateValueLine.push(dataRound.share_stock.reduce((current, pre) => current + Number(dataRound.stock_price) * Number(pre.number_of_shares), 0))
                this.corporateValueColumn.push(
                    {
                        type : 'bar',
                        label : dataRound.round_name || '',
                        data : this.corporateValueLine,
                        color : corporateColor[0]
                    }
                )
                dataRound.share_stock.forEach(item => this.ratioShareholderLabel.push(item.investor_name));
                const totalNumberOfShares = dataRound.share_stock.reduce((current, pre) => current + Number(pre.number_of_shares), 0);
                dataRound.share_stock.forEach(item => this.ratioShareholderData.push((item.number_of_shares / totalNumberOfShares * 100).toFixed(2)));
                dataRound.share_stock.forEach(item => {
                    let obj = {
                        name: item.investor_name,
                        round: dataRound.round_name,
                        amount: numberWithCommas(dataRound.stock_price * item.number_of_shares),
                        stock_price: numberWithCommas(dataRound.stock_price),
                        kinds: item.financing_method_id,
                        ratio: (item.number_of_shares / totalNumberOfShares * 100).toFixed(2)
                    };
                    this.shareholdersData.push(obj)
                })
            }
        },
        oldData() {
            const corporateData = this.data[0].data;
            const shareholderData = this.data[1].data;
            const ratioShareholderData = this.data[2].data;

            const corporateArr = [...corporateData.data]
            const newChartData = corporateArr[corporateArr.length - 1];
            const newIndex = corporateArr.findIndex(item => item.execution_date === newChartData.execution_date)
            if (newIndex !== corporateArr.length - 1) {
                corporateArr[newIndex] = {...newChartData}
                corporateArr.pop()
            }
            corporateArr.sort((a, b) => ('' + a.execution_date).localeCompare(b.execution_date))
  
            if (corporateData) {
               this.corporateValues = [...corporateArr];
               this.corporateValueLine = this.corporateValues.map(item => item.value_corporate);
               this.corporateValueColumn = lineStackedBarChartDataColumn(this.corporateValues);
               this.corporateValueLabel = this.corporateValues.map(item => moment(item.execution_date).format('YYYY-MM'));
            }

            if (shareholderData) {
               this.shareholdersData = shareholderData.data.map((item) => {
                  const obj = {
                     name: item.investor_name,
                     round: item.round_name,
                     amount: numberWithCommas(item.money_shares),
                     stock_price: numberWithCommas(item.stock_price),
                     kinds: item.method,
                     ratio: item.ratio_stock,
                  }
                  return obj;
               })
            }

            if (ratioShareholderData) {
               const sortedRatios = ratioShareholderData.data.sort((a, b) => b.ratio_stock - a.ratio_stock)
               let remainRatio = 0
               sortedRatios.forEach((item, index) => {
                  if (index < 3) {
                     this.ratioShareholderLabel.push(item.investor_name)
                     this.ratioShareholderData.push(item.ratio_stock)
                  } else {
                     remainRatio += item.ratio_stock
                  }
               })
               this.ratioShareholderLabel.push('その他')
               this.ratioShareholderData.push(remainRatio)
            }
            this.preview = true
        },
        closePreview() {
            this.$emit('closePreview');
        }
    },
    created() {
        if (!this.isManualInput) {
            this.oldData();
        } else {
            this.newData();
        }
    }
   
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/_home.scss";
.td-ellipsis {
    max-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>
<style lang="scss">
.preview-content {
    .v-data-table__wrapper {
        max-height: 300px;
    }
}
</style>