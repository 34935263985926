<template>
    <div>
        <div class="app-container">
            <div class="content register-content">
                <div class="logo">
                    <img :src="logo" alt="">
                </div>
                <div class="title-form fs-32 font-weight-bold m__top--48 color__primary_black">メールアドレスの変更が完了しました。</div>
                <div>
                    <button-component
                        class="m__top--48"
                        :text="'ホームに戻る'"
                        :small=false
                        :func="home"
                        :width="widthButton"
                    ></button-component>
                </div>
                <div>
                    <button-component
                        class="m__top--48"
                        :text="'ログイン画面に戻る'"
                        :small=false
                        :func="logout"
                        :width="widthButton"
                    ></button-component>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ButtonComponent from "@/components/common/ButtonComponent.vue";
import {
  ACTION_USER_LOGOUT,
  ACTION_SAVE_AUTH_USER
} from '@/stores/auth_users/actions'
export default {
    components : {
        ButtonComponent ,
    },
    data(){
        return {
            logo : require('@/assets/images/svgs/logo_carte.svg'),
            widthButton : "160px"
        }
    },
    created() {
        const user = this.$store.getters['authUser']
        user.email = this.$route.params.email
        this.$store.dispatch(ACTION_SAVE_AUTH_USER, user)
    },
    methods: {
        home() {
            this.$router.push({name: 'home'})
        },
        logout() {
            this.$store.dispatch(ACTION_USER_LOGOUT)
            .then(() => {
            // this.$toast.success('ログアウトしました')
            this.$router.push({name: 'Login'})
            })
            .catch(() => {})
        }
    }
} 
</script>
<style scoped lang="scss">
@import "@/assets/scss/index.scss";
.description-form { 
    line-height: 32px;
}
</style>