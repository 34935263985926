<template>
    <v-dialog
      v-model="show"
      width="600"
    >
        <v-card class="px-16 py-16" style="position: relative">
            <img :src="require('@/assets/images/icons/ic_close.svg')" alt="" @click="show = false" style="position: absolute; top: 8px; right: 8px; cursor: pointer">
            <div class="card-title text-center font-weight-bold fs-18 mb-4">新規ラウンドの追加</div>
            <div class="card-body">
                <div class="px-2 mb-8 fw-500 fs-18">
                    <div>
                        資本政策のファイルをアップロードし直すことによって
                        データを更新することができます。又は手入力によって
                        個別にデータを編集することもできます。
                    </div>
                </div>
                <UploadFile @uploadFile="uploadFile" ref="uploadFile"/>
                <div class="text-center">
                    <span class="text-link active text-decoration-underline font-weight-bold fs-14">
                        <a style="color: #333" href="/download/資本政策のテンプレート.xlsx" download>テンプレートをダウンロードする</a>
                    </span>
                </div>
                <div v-if="files && files.length != 0" class="d-flex justify-center mt-6">
                    <button-component
                        v-if="!isLoading"
                        class="ml-3"
                        :text="'アップロードする'"
                        :small="false"
                        :func="upload"
                        :width="'160px'"
                        :height="'44px'"
                    ></button-component>
                    <div v-else class="donut"></div>
                </div>
                <div class="d-flex justify-center mt-10">
                    <button-component
                        class="mr-3"
                        :text="'キャンセル'"
                        :small="false"
                        :func="() => {show = false}"
                        :width="'160px'"
                        :height="'44px'"
                        :mode="'btn-tertiary'"
                    ></button-component>
                    <button-component
                        class="ml-3"
                        :text="'手入力で追加する'"
                        :small="false"
                        :func="() => {this.$router.push({name: 'Capital Policy'})}"
                        :width="'160px'"
                        :height="'44px'"
                    ></button-component>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import UploadFile from '@/components/common/UploadForm.vue'
import ButtonComponent from '@/components/common/ButtonComponent.vue'
export default {
    name: "UploadPolicyModal",
    components: {
        UploadFile,
        ButtonComponent
    },
    props: {
        value: Boolean,
    },
    data() {
        return {
            files: null,
            isLoading: false
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        upload() {
            this.$emit('upload-policy', this.files)
        },
        uploadFile(files) {
            this.files = files
        },
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.custom-button {
    background: $corporate_color_1;
    border: 1px solid $corporate_color_1;
    border-radius: 4px;
    cursor: pointer;
    min-width: 160px;
}

</style>
