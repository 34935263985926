import { UserService } from '@/services'
import { news_status } from '@/commons/plan_const';

export default {
    state: {
        list_all_news : [],
        list_unread_news : [],
        list_read_news : []
    },
    getters: {
        listAllNews : state => {
            return state.list_all_news;
        },
        listUnreadNews : state => {
            return state.list_unread_news;
        },
        listReadNews : state => {
            return state.list_read_news;
        }
    },
    mutations: {
        setListAllNews(state, data) {
            state.list_all_news = data;
        },
        setListUnreadNews(state, data) {
            state.list_unread_news = data;
        },
        setListReadNews(state, data) {
            state.list_read_news = data;
        }
    },
    actions: {
        actionSetListNews({commit, state}, { isUpdate, params = {}}) {
            const listNews = params && params.status && params.status == news_status.unread ? [...state.list_unread_news]
                            : params && params.status && params.status == news_status.read ? [...state.list_read_news]
                            : [...state.list_all_news];
            
            return new Promise((resolve, reject) => {
                if (!listNews || isUpdate) {
                    UserService.getListNews(params).then((response) => {
                        if (response && response.data) {
                            params && params.status && params.status == news_status.unread ? commit('setListUnreadNews', response.data.data) 
                            : params && params.status && params.status == news_status.read ? commit('setListReadNews', response.data.data) 
                            : commit('setListAllNews', response.data.data) ;
                        } 
                        resolve(response.data.data);
                    }).catch((error)=> {
                        reject(error);
                    })
                } else {
                    resolve(listNews);
                }
            })
        },
    }
}