<template>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

import { lineBarChartOption } from "@/commons/chart";
import { xAxisLabel } from "@/commons/plan_const"
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  name: 'LineChartComponent',
  components: {
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    dataBar1: {
      type: Array,
      // [1, 2, 3, 4]
    },
    dataBar2: {
      type: Array,
      // [1, 2, 3, 4]
    },
    dataLine: {
      type: Array,
      // [1, 2, 3, 4]
    },
    labels: {
      type: Array,
      // {
//   labels: [
//     'January',
//     'February',
//     'March',
//     'April',
//   ],
//     labelData1: 'labelData1',
//   labelData2: 'labelData2',
//   labelLine: 'labelLine',
// }
    }
  },
  mounted() {
     this.chartOptions = lineBarChartOption;
    let isNegative = false;
    this.$props.dataBar2.forEach(element => {
      if (element < 0) {
        isNegative = true;
        return;
      }
    });
    if (!isNegative) {
      this.chartOptions.scales.barPercentage.min = 0;
      this.chartOptions.scales.barPercentage.ticks.stepSize = 25;
    } else {
      this.chartOptions.scales.barPercentage.min = this.minDataLine;
    }
  },
  data() {
    let chartOptions = lineBarChartOption;
    return {
      chartData: {
        labels: xAxisLabel,
        datasets: [
          {
            yAxisID: 'barPercentage',
            type: "line",
            label: this.labels.length ? this.labels[0].labelLine : null,
            data: this.dataLine,
            backgroundColor: '#fff',
            borderColor: '#4A93FF',
            pointBorderColor: "#fff",
            pointBackgroundColor: "#4A93FF",
            lineTension: 0,
            fill: true,
            pointStyle: 'circle',
            pointRadius: 3,
            borderWidth: 2,
            gridLines: {
              color: "rgba(0, 0, 0, 0)",
            },
          },
          {
            type: "bar",
            backgroundColor: "#99BFF7",
            borderWidth: 1,
            label: this.labels.length ? this.labels[0].labelData1 : null,
            data: this.dataBar1,
            borderRadius: 2,
            barThickness : 10
          },
          {
            type: "bar",
            backgroundColor: "#FAA4BF",
            borderWidth: 1,
            label: this.labels.length ? this.labels[0].labelData2 : null,
            data: this.dataBar2,
            borderRadius: 2,
            barThickness : 10
          },
          
        ]
      },
      chartOptions: chartOptions
    }
  },
  computed: {
    minDataLine() {
      return Math.min(...this.$props.dataLine);
    }
  }
}
</script>
