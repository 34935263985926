import Vue from 'vue'
import VueRouter from 'vue-router'

import { api_router } from './api';
import { web_router } from './web';
import store from '@/stores'

Vue.use(VueRouter)
const routes = [
  ...web_router,
  ...api_router,
]


const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta && !x.meta.isPublic)
    const isAuthenticated = store.getters.accessToken || !!localStorage.getItem('accessToken');
    if (requiresAuth && !isAuthenticated) {
      return next({
        name: 'register-step-1'
      })
    }
    if (to.meta.role) {
      const isGranted = store.getters.authUser.role == to.meta.role || localStorage.getItem('userInfo').role == to.meta.role
      if(!isGranted) {
        next({
          name: 'not-found'
        })
      }
    }
    if (from.name && localStorage.getItem('prevRoute') !== from.name && from.name !== to.name) {
      localStorage.setItem('prevRoute', from.name)
    }
    next();
})

router.afterEach((to) => {
  Vue.nextTick(() => {
      document.title = to.meta.title;
  });
})

export default router;
