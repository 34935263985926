<template>
    <div>
        <div class="app-container">
            <div class="content login-content">
                <div class="logo">
                    <img :src="logo" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            logo : require('@/assets/images/svgs/logo_carte.svg')
        }
    },
    created() {
        if (!this.$store.getters.accessToken && !localStorage.getItem('accessToken')) {
            this.$router.push({name: 'unlogged-reset-password'})
        } else {
            this.$router.push({name: 'Logged reset password'})
        }
    }
}
</script>
<style lang="scss" scoped>

</style>