<template>
    <div>
        <layout-app :isLogin="false"></layout-app>
        <div class="app-container">
            <div class="content register-content">
                <div class="logo">
                    <img :src="logo" alt="">
                </div>
                <div class="title-form fs-32 font-weight-bold m__top--48 color__primary_black">カルテにようこそ！</div>
                <div class="description-form fs-16 font-weight-medium m__top--16 color__primary_black">カルテは資本政策や資金調達情報などを簡単にわかりやすく管理・共有できるサービスです。</div>
                <div class="fs-14 font-weight-medium m__top--16 color__primary_secondary">3ステップで簡単に利用できます。</div>
                <button-component
                    class="m__top--48"
                    :text="textButton"
                    :small=false
                    :func="() => $router.push({name: 'register-step-2'})"
                    :width="widthButton"
                ></button-component>
                <div class="fs-14 font-weight-regular m__top--12 color__primary_black">現在無料で使うことができます。</div>
                <div class="link-to-login" @click="$router.push({name: 'Login'})">既に登録済みの方はログイン</div>
            </div>
        </div>
    </div>
</template>
<script>
import ButtonComponent from "@/components/common/ButtonComponent.vue";
import LayoutApp from "@/components/layouts/LayoutApp.vue";
export default {
    components : {
        ButtonComponent ,
        LayoutApp
    },
    data(){
        return {
            logo : require('@/assets/images/svgs/logo_carte.svg'),
            textButton : "使ってみる",
            widthButton : "150px"
        }
    },
    methods: {
        nextPage() {
            this.$router.push({name: "register-step-2"});
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            const isAuthenticated = vm.$store.getters.accessToken || !!localStorage.getItem('accessToken')
            if (isAuthenticated) {
                vm.$router.push({name: 'home'})
            }
        })
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/index.scss";
.description-form {
    line-height: 32px;
}
.link-to-login {
    font-weight: 700;
    font-size: 14px;
    line-height: 200%;
    letter-spacing: 0.005em;
    text-decoration-line: underline;
    color: $primary-color;
    cursor: pointer;
    margin-top: 24px;
}
</style>
