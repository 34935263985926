<template>
    <div>
        <layout-app :isLogin="false"></layout-app>
        <div class="app-container">
            <div class="content register-content">
                <div class="logo">
                    <img :src="logo" alt="">
                </div>

                <div class="description-form fs-16 font-weight-medium m__top--48 color__primary_black">
                    会社名と担当者名を登録してください。
                </div>

            <div class="form-group">
              <div style="display: flex; align-items: center">
                <label class="m__top--32 mb-2">会社名</label>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-img
                      class="m__top--32 mb-2 m__left--8"
                      v-bind="attrs"
                      v-on="on"
                      max-height="16"
                      max-width="16"
                      src="@/assets/images/svgs/information.svg"
                    ></v-img>
                  </template>
                  <p style="font-size: 14px; font-weight: 400; line-height: 175%; ">会社名を入力し、候補が表示されない場合はカタカナ・漢字・アルファベットなど複数パターンで 試してみてください。</p>
                  <label class="m__top--8" style="font-size: 14px; font-weight: 700; line-height: 175%;">入力例 : 「カルテ株式会社」「Carte株式会社」</label>
                </v-tooltip>
              </div>
                  <v-combobox
                    class="form-control large py-0 px-0"
                    placeholder="例：カルテ株式会社"
                    v-model.trim="form.companyName"
                    append-icon=""
                    :items="items"
                    flat
                    solo
                    @update:search-input="getItems($event)"
                    @blur="resetData()"
                  >
                  <template v-slot:append-item>
                    <v-card
                      v-if="lastPage !== currentPage"
                      v-intersect="visibilityChanged"
                      class="text-xs-center"
                    >
                    </v-card>
                  </template>
                </v-combobox>
                <div v-if="v$.form.companyName.$errors">
                    <div v-if="v$.form.companyName.required.$invalid" class="error-text">会社名は必須です。</div>
                    <div v-else-if="v$.form.companyName.maxLength.$invalid" class="error-text">組織名は100文字以内でご入力ください。</div>
                </div>
            </div>

            <div class="form-group">
                <label class="m__top--32">担当者名</label>
                <input
                    v-model="v$.form.representativesName.$model"
                    @input="v$.form.representativesName.$reset"
                    class="form-control large"
                    placeholder="例 : 山田太郎">
                <div v-if="v$.form.representativesName.$errors">
                    <div v-if="v$.form.representativesName.required.$invalid" class="error-text">担当者名は必須です。</div>
                    <div v-else-if="v$.form.representativesName.maxLength.$invalid" class="error-text">担当者名は100文字以内でご入力ください。</div>
                </div>
            </div>

            <div class="form-group">
                <label class="m__top--32">ログイン用パスワードを決めてください</label>
                <input
                    v-model="v$.form.password.$model"
                    @input="v$.form.password.$reset"
                    type="password"
                    class="form-control large">
                <span>8文字以上の英数字でご入力ください。</span>
                <div v-if="v$.form.password.$errors">
                    <div v-if="v$.form.password.required.$invalid" class="error-text">パスワードは必須です。</div>
                    <div v-else-if="v$.form.password.minLength.$invalid" class="error-text">パスワードは:8文字より小さくてはいけません。</div>
                    <div v-else-if="v$.form.password.alphaNum.$invalid" class="error-text">英数字をご使用ください。</div>
                </div>
            </div>

            <div class="form-group">
                <label class="m__top--32">確認のためもう一度パスワードをご入力ください</label>
                <input
                    v-model="v$.form.confirmPassword.$model"
                    @input="v$.form.confirmPassword.$reset"
                    type="password"
                    class="form-control large">
                <span>8文字以上の英数字でご入力ください。</span>
                <div v-if="v$.form.confirmPassword.$errors">
                    <div v-if="v$.form.confirmPassword.required.$invalid" class="error-text">パスワードを認証するは必須です。</div>
                    <div v-else-if="v$.form.confirmPassword.sameAs.$invalid" class="error-text">パスワードが確認用の値と一致しません。</div>
                </div>
            </div>

            <div class="d-flex justify-center m__top--48">
                <button-component
                v-if="!isLoading"
                :text="textButton"
                :small=false
                :func="register"
                :width="widthButton"
            ></button-component>
            <div v-else class="donut"></div>
            </div>
            </div>

            <div class="progress-bar d-flex justify-center m__top--56">
                <progress-component :step="step"></progress-component>
            </div>
        </div>
    </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import {required, minLength, sameAs, alphaNum, maxLength} from "@vuelidate/validators"
import ButtonComponent from "@/components/common/ButtonComponent.vue";
import ProgressComponent from "@/components/common/ProgressComponent.vue";
import { UserService } from '@/services'
import {ACTION_USER_LOGOUT, ACTION_SAVE_AUTH_USER, ACTION_SET_ACCESS_TOKEN} from '@/stores/auth_users/actions'
import {ROLE_STARTUP} from '@/commons/plan_const'
import LayoutApp from "@/components/layouts/LayoutApp.vue";

export default {
    components : {
        ButtonComponent,
        ProgressComponent,
        LayoutApp
    },
    data(){
        return {
            logo : require('@/assets/images/svgs/logo_carte.svg'),
            iconRadio : require('@/assets/images/icons/ic_radio.svg'),
            iconRadioActive : require('@/assets/images/icons/ic_radio_active.svg'),
            textButton : "資本政策へ",
            widthButton : "150px",
            step : 2,
            option : 1,
            radios : [
                {
                    value : 1,
                    text : "スタートアップとし使用する"
                },
                {
                    value : 2,
                    text : "エンジェル投資家とし使用する"
                }
            ],
            form: {
                companyName: '',
                representativesName: '',
                password: '',
                confirmPassword: ''
            },
            isLoading: false,
            lastPage: 0,
            isLastPage: false,
            currentPage: 1,
            items: [],
            searchStartup: '',
          infoModal: false
        }
    },
    setup: () => ({ v$: useVuelidate({$lazy: true}) }),
    validations() {
        return {
            form: {
                companyName: {
                    required,
                    maxLength: maxLength(100),
                },
                representativesName: {
                    required,
                    maxLength: maxLength(100),
                },
                password: {
                    required,
                    minLength: minLength(8),
                    alphaNum
                },
                confirmPassword: {
                    required,
                    sameAs: sameAs(this.form.password),
                }
            }
        }
    },
    methods: {
      visibilityChanged(e) {
            this.currentPage !== this.lastPage && this.lastPage !== 1 && e && this.loadMore();
        },
        async getItems(startup) {
          if (this.searchStartup !== startup) {
            this.searchStartup = startup
            this.form.companyName = startup
            this.currentPage = 1
          }
          try {
            const { data } = await UserService.startupSuggest({page: this.currentPage, startup_name: startup});
            this.lastPage = parseInt(data.data.last_page)
            this.items = [
              ...data.data.startup_names,
            ]
          } catch (e) {
            //
          }
        },
        async loadMore() {
          try {
            const { data } = await UserService.startupSuggest({page: this.currentPage, startup_name: this.searchStartup});
            if (data.data.startup_names && data.data.startup_names.length) {
              ++this.currentPage;
              this.lastPage = parseInt(data.data.last_page)
              this.items = [
                ...this.items,
                ...data.data.startup_names,
              ]
            }
          } catch(e) {
            //
          }
        },
        selectOption(value) {
            this.option = value;
        },
        register() {
            this.v$.form.$touch()
            if (!this.v$.form.$error) {
                this.isLoading = true
                const params = {
                    id: this.$route.query.id,
                    token: this.$route.query.token
                }
                const data = {
                    user_name: this.form.representativesName,
                    startup_name: this.form.companyName,
                    password: this.form.password,
                    password_confirmation: this.form.confirmPassword
                }
                UserService.signup(params, data)
                .then((res) => {
                    this.$store.dispatch(ACTION_SAVE_AUTH_USER, {...res.data.data.user, role: ROLE_STARTUP})
                    this.$store.dispatch(ACTION_SET_ACCESS_TOKEN, res.data.data.access_token)
                })
                .then(() => {
                    this.$router.push({ name: 'register-step-5-6-7'})
                }).catch(err => {
                    this.isLoading = false
                  console.log(err.data)
                    // this.$toast.error(err.data.message)
                })
            }
        }
    },
    created() {
        if (!this.$route.query.id || !this.$route.query.token) {
            this.$router.push({name: 'not-found'})
        }
        if (this.$store.getters.accessToken || !!localStorage.getItem('accessToken')) {
            this.$store.dispatch(ACTION_USER_LOGOUT).then(() => {}).catch(() => {})
        }
    }
}
</script>
<style scoped lang="scss">
.description-form {
    line-height: 32px;
}

.form-group {
  position: relative;
}

.v-tooltip {
  position: absolute;
}

.v-tooltip__content {
  width: 368px;
}
</style>
