import { UserService } from '@/services'

export default {
    state: {
        financing_detail : {}
    },
    getters: {
        lastFinancingInformation : state => {
            return state.financing_detail;
        }
    },
    mutations: {
        setFinancingDetail(state, data) {
            state.financing_detail = data;
        }
    },
    actions: {
        actionSetFinancing(context, isUpdate) {
            return new Promise((resolve, reject) => {
                const financingData = context.lastFinancingInformation;
                if (!financingData || isUpdate) {
                    UserService.getLastFinancingDetail().then((response) => {
                        if (response && response.data) {
                            context.commit('setFinancingDetail', response.data.data);
                        }
                        resolve(response.data.data);
                    }).catch((error)=> {
                        reject(error);
                    })
                } else {
                    resolve(financingData);
                }
            })
        },
    }
}