<template>
  <div class="box-shareholders-management">
    <div v-if="isLoading" class="spinner d-flex justify-center align-center">
      <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    <div v-else class="info-shareholders-management" v-for="item, index in listShareholders" :key="item.id">
      <div class="box-info">
        <label for="">氏名又は会社名</label>
        <div class="content">
          <div class="inner-content">{{ item.investor_name }}</div>
        </div>
      </div>
      <div class="box-info">
        <label for="">メールアドレス</label>
        <div class="content">
          <div class="inner-content">{{ item.email_investor ? item.email_investor : item.email_invite }}</div>
        </div>
      </div>
      <div class="box-info mt-9 d-flex align-center">
        <div v-if="item.status === 1" class="inviting-label d-flex" :style="{background: cancelIndex === index ? '#B93535' : '#339967'}" @mouseover="cancelIndex = index" @mouseout="cancelIndex = -1"
             @click="openUninviteModal(index)">
          <div class="py-2 px-1" v-if="cancelIndex === index">招待取り消し</div>
          <div class="py-2 px-1" v-else>招待中</div>
        </div>
        <div v-if="item.status === 0" class="invite-label d-flex" @click="openInviteModal(index)">
          <div>招待する</div>
        </div>
        <div v-if="item.status === 2" class="invited-label d-flex p__bottom--18 align-end" @click="openUnlinkModal(index)">
          <img class="icon-close" :src=icClose alt="">
        </div>
      </div>
    </div>
    <ButtonComponent text="新しい株主を招待する" mode="btn-secondary" :func="openInvite"/>
    <modal-invite-shareholders ref="modal-invite-shareholders" @rerender="getListShareholders"></modal-invite-shareholders>
    <v-dialog v-model="modal" width="512px">
      <v-card class="pb-16" style="position: relative">
        <img :src="require('@/assets/images/icons/ic_close.svg')" alt="" @click="modal = false" style="position: absolute; top: 8px; right: 8px; cursor: pointer">
          <div class="pt-16 pb-10 text-center">
            <div v-if="modalType === 1">
              <span v-if="listShareholders[unInviteIndex].investor_name">
                <strong>{{ listShareholders[unInviteIndex].investor_name }}</strong>への招待を取り消しますか？
              </span>
              <span v-else>
                招待を取り消しますか？
              </span>
            </div>
            <div v-if="modalType === 2">
              <strong>{{ listShareholders[unInviteIndex].investor_name }}</strong>を連携解除しましか？
            </div>
          </div>
          <div class="d-flex w-100 justify-center">
            <button-component
                class="mr-3"
                :text="'キャンセル'"
                :small="false"
                :func="() => {modal = false}"
                :width="'180px'"
                :height="'48px'"
                :mode="'btn-tertiary'"
            ></button-component>
            <div>
                <div v-if="buttonLoading" class="w-180-px ml-3"><div class="donut"></div></div>
                <button-component
                    v-else
                    class="ml-3"
                    :text=" modalType === 1 ? '取消する' : '解除する'"
                    :small="false"
                    :func="cancelInvite"
                    :width="'180px'"
                    :height="'48px'"
                    :mode="'btn-structure'"
                ></button-component>
            </div>
          </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="inviteModal" width="632px">
      <v-card class="pb-16" style="position: relative">
        <img :src="require('@/assets/images/icons/ic_close.svg')" alt="" @click="inviteModal = false" style="position: absolute; top: 8px; right: 8px; cursor: pointer">
        <div class="pt-16">
          <p class="text-center font-weight-bold fs-18 m__bottom--48"><strong>{{specificShareholder.investor_name}}</strong>を招待します</p>
          <shareholder-form v-if="inviteModal" :shareholder="specificShareholder"></shareholder-form>
          <div class="d-flex justify-center mt-8">
            <div v-if="!buttonLoading">
              <button-component
                  class="m__right--24"
                  :text="'キャンセル'"
                  :func="() => {inviteModal = false}"
                  :width="'160px'"
                  :height="'44px'"
                  :mode="'btn-tertiary'"
              ></button-component>
              <button-component
                  :text="'招待する'"
                  :func="inviteSpecificInvestor"
                  :width="'160px'"
                  :height="'44px'"
              ></button-component>
            </div>
            <div v-else class="donut"></div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ButtonComponent from "@/components/common/ButtonComponent";
import { UserService } from '@/services';
import ModalInviteShareholders from '@/components/Home/ModalInviteShareholders.vue';
import ShareholderForm from '@/components/Home/ShareholderForm.vue'
export default {
  name: "ShareholdersManagement",
  components: {
    ButtonComponent,
    ModalInviteShareholders,
    ShareholderForm,
  },
  data() {
    return {
      listShareholders: [],
      icClose: require('@/assets/images/icons/ic_close.svg'),
      isLoading: false,
      cancelIndex: -1,
      modal: false,
      modalType: -1,
      unInviteIndex: -1,
      inviteIndex: -1,
      inviteModal: false,
      buttonLoading: false,
      specificShareholder: {}
    }
  },
  created() {
    this.getListShareholders()
  },
  watch: {
    modal() {
      if (!this.modal) {
        this.modalType = -1;
        this.unInviteIndex = -1;
        this.unInviteIndex = -1;
      }
    },
    inviteModal() {
      if (!this.inviteModal) {
        this.specificShareholder = {}
        this.inviteIndex = -1
      }
    }
  },
  methods: {
    getListShareholders() {
      this.isLoading = true
      UserService.listShareholders().then((res) => {
        this.listShareholders = res.data.data.map(item => {
          return {...item}
        })
        this.isLoading = false
      }).catch()
    },
    rerenderData() {
      this.listShareholders = []
      this.getListShareholders();
    },
    openInvite() {
      this.$refs['modal-invite-shareholders'].$refs['popup-invite'].open()
      this.$refs['modal-invite-shareholders'].shareHolders = [{inviteEmail: ''}]
    },
    openInviteModal(index) {
      this.inviteModal = true;
      this.inviteIndex = index;
      this.specificShareholder = {...this.listShareholders[this.inviteIndex], inviteEmail: this.listShareholders[this.inviteIndex].email_invite};
    },
    openUninviteModal(index) {
      this.modalType = 1;
      this.modal = true;
      this.unInviteIndex = index;
    },
    openUnlinkModal(index) {
      this.modalType = 2;
      this.modal = true;
      this.unInviteIndex = index;
    },
    inviteSpecificInvestor() {
      if (this.inviteIndex !== -1) {
        this.buttonLoading = true;
        UserService.inviteShareholders({invite: [{
          name: this.specificShareholder.investor_name,
          email: this.specificShareholder.inviteEmail,
        }]}).then(() => {
          this.inviteModal = false
          this.buttonLoading = false
        }).then(() => {
          this.rerenderData();
        }).catch(e => {
          this.buttonLoading = false
          if (e.data.message) {
            this.$toast.error(e.data.message)
          }
        })
      }
    },
    cancelInvite() {
      if (this.unInviteIndex !== -1) {
        this.buttonLoading = true;
        UserService.cancelInviteInvestors({'cancel-invite': [{
            email: this.listShareholders[this.unInviteIndex].email_invite,
            user_id: this.listShareholders[this.unInviteIndex]?.user_id
          }]})
        .then(() => {
          this.modal = false
          this.buttonLoading = false
          this.$toast.success('招待を取り消しました。')
        })
        .then(() => {
          this.rerenderData();
        })
        .catch(e => {
          this.buttonLoading = false
          if (e.data.message && e.status !== 422) {
            this.$toast.error(e.data.message)
          }
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
.info-shareholders-management {
  display: flex;
  .box-info {
    margin-right: 24px;
    margin-bottom: 24px;
    label {
      font-weight: 700;
      font-size: 14px;
      line-height: 200%;
      letter-spacing: 0.005em;
      color: #333333;
    }
    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 12px;
      gap: 10px;
      width: 290px;
      height: 56px;
      background: #F8F8F8;
      border-radius: 4px;
      margin-top: 8px
    }
    .inner-content {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: left;
    }
    .inviting-label {
      color: white;
      font-weight: bold;
      border-radius: 4px;
      line-height: 1;
      cursor: pointer;
      height: 44px;
      background: #99BFF7;
      align-items: center;
      justify-content: center;
      width: 160px;
    }
    .invited-label, .invite-label {
      height: 100%;
      font-weight: 500;
    }
    .invited-label > img, .invite-label {
      cursor: pointer;
    }
    .invite-label {
      height: 44px;
      background: #99BFF7;
      align-items: center;
      border-radius: 4px;
      justify-content: center;
      color: #ffffff;
      width: 160px;
    }
  }
}
.spinner {
  min-height: 200px;
}
.btn-secondary {
  width: 164px !important;
  height: 37px !important;
}

</style>
