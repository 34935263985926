<template>
    <v-dialog
      v-model="show"
      width="452"
    >
        <v-card class="px-16 py-16">
            <div class="card-body">
                <div class="px-2 mb-12">
                    <div class="font-weight-bold fs-18 mb-4 text-center">
                        再設定用メールを送信しました
                    </div>
                    <div class="card-content">
                        <div class="mb-2">ご登録されたメールアドレスにパスワード再設定用のメールを送信しました。</div>
                        <div>メールに記載されたURLからパスワードを設定してください。</div>
                    </div>
                </div>
                <div class="d-flex justify-center">
                    <div class="custom-button px-4 py-3 font-weight-bold color__white fs-14 text-center" @click="show = false">OK</div>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: "ForgotPasswordEmailSent",
    props: {
        value: Boolean
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.custom-button {
    background: $corporate_color_1;
    border-radius: 4px;
    cursor: pointer;
    width: 180px
}
.card-content {
    font-weight: 500;
}
.v-dialog {
    background: $white !important;
}
</style>