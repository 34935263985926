<template>
    <div class="modal-invite-shareholder">
        <popup-confirm ref="popup-invite" :widthDialog="632" @closeDialog="close()" :isBtnClose="false" @onClose="onClose">
            <div slot="body-popup">
                <p class="text-center font-weight-bold fs-18 m__bottom--48 p__top--64">スタートアップを招待しましょう！</p>
                <div class="m__bottom--40" v-for="startup, index in startups" :key="'startup'+ index">
                    <div class="btn-delete-shareholder text-center m__bottom--40">
                        <button-component
                            v-if="index"
                            :text="'削除'"
                            :small=false
                            :func="()=>{startups.splice(index, 1)}"
                            :width="'61px'"
                            :height="'36px'"
                            :mode="'btn-tertiary'"
                        ></button-component>
                    </div>
                    <startup-form ref="startup-form" :startup="startup"></startup-form>
                </div>
                <div class="btn-invite-shareholders text-center">
                <button-component
                    class="text-center m__bottom--24"
                    :text="'招待するスタートアップを追加'"
                    :width="'230px'"
                    :height="'44px'"
                    :mode="'btn-secondary'"
                    :func="() => { startups.push({}) }"
                    :statusDisabled="isLoading"
                ></button-component>
                </div>
            </div>
            <div slot="footer-popup">
                <div v-if="!isLoading">
                    <button-component
                        class="m__right--24"
                        :text="'キャンセル'"
                        :func="close"
                        :width="'160px'"
                        :height="'44px'"
                        :mode="'btn-tertiary'"
                    ></button-component>
                    <button-component
                        :text="'招待する'"
                        :func="inviteStartups"
                        :width="'160px'"
                        :height="'44px'"
                    ></button-component>
                </div>
                <div v-else class="donut"></div>
            </div>
        </popup-confirm>
        <popup-confirm ref="popup-complete" :widthDialog="789" :heightDialog="360" :isBtnClose="false" :persistent="true">
            <div slot="body-popup">
                <p class="text-center fs-18 font-weight-bold p__top--64">スタートアップの招待が完了しました！</p>
                <div class="popup-complete-content">
                    <span v-for="(item, index) in inviteEmail" :key="index">{{ index !== inviteEmail.length - 1 ? item + ', ' : item }}</span>
                    への招待が完了しました。承認されるとスタートアップも使えるようになります。<br>
                    今後のスタートアップを追加する場合はアカウントメニューから追加できます。<br>
                </div>
            </div>
            <div slot="footer-popup">
                <button-component
                    :text="'OK'"
                    :func="confirm"
                    :width="'240px'"
                    :height="'48px'"
                ></button-component>
            </div>
        </popup-confirm>
    </div>
</template>
<script>
import PopupConfirm from "../common/PopupConfirm.vue";
import ButtonComponent from "../common/ButtonComponent.vue";
import StartupForm from "./StartupForm.vue"
import { UserService } from '@/services';

export default {
    components : {
        PopupConfirm,
        ButtonComponent,
        StartupForm
    },
    data() {
        return {
            startups: [{
                inviteEmail: ''
            }],
            results: [],
            idxInput: null,
            isLoading: false,
            inviteEmail: [],
        }
    },
    methods: {
        invite(){
            this.$refs['popup-complete'].open();
            this.$refs['popup-invite'].close();
        },
        close() {
            this.$refs['popup-invite'].close();
        },
        onClose() {
            this.startups = [{
              inviteEmail: ''
            }]
        },
        handleFocusOutInput(){
            this.results = [];
        },
        confirm() {
            this.$refs['popup-complete'].close();
        },
        inviteStartups() {
            let validating = 0
            this.$refs['startup-form'].forEach((item) => {
                if (item.validateInput()) {
                    validating += 1
                } else {
                    validating -= 1
                }
            })
            if (validating === this.startups.length) {
                let startups = []
                let emails = []
                this.startups.forEach(item => {
                    startups.push({
                        email: item.inviteEmail,
                    })
                    emails.push(item.inviteEmail)
                    this.inviteEmail = [...emails]
                })
                this.isLoading = true
                UserService.inviteStartups({invite: startups}).then(() => {
                    this.isLoading = false
                    this.startups = [{
                        inviteEmail: ""
                    }]
                    this.$emit('rerender');
                    this.$refs['popup-invite'].close();
                    this.$refs['popup-complete'].open();
                }).catch((e) => {
                    this.isLoading = false
                    this.$toast.error(e.data.message || 'Server error');
                })
            }
        },
    },
    mounted(){
        document.addEventListener('click', this.handleFocusOutInput);
    },
}
</script>
<style scoped lang="scss">
    @import "@/assets/scss/_variables.scss";
    .content-popup {
        border-left: 4px solid #F3F4F3;
        padding-left: 24px;
        margin: 0 40px;
    }
    .search-result{
        position: absolute;
        top: 55px;
        border: 1px solid #EAEDEB;
        box-shadow: 0px 4px 8px rgba(173, 182, 177, 0.1);
        border-radius: 4px;
        overflow: auto;
        max-height: 344px;
        padding: 12px 28px;
        background: $white;
        z-index: 999;
        .item-result {
            padding: 4px;
            margin : 8px 0;
            text-align: left;
            cursor: pointer;
            &:hover {
                background-color: #F8F8F8;
            }
        }
    }
    .form-control-input {
        position: relative;
    }
    .popup-complete-content{
        font-weight: 500;
        font-size: 16px;
        line-height: 175%;
        text-align: center;
        margin-bottom: 24px;
    }
</style>
