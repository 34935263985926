<template>
  <div class="box-contact">
    <HeaderPageComponent text-btn="戻る" text-title="お問い合わせ"/>
    <div class="box-content-contact">
      <div class="title-content-contact">
        お問い合わせ
      </div>
      <div class="box-select-request">
        <div class="title-select-request">
          お問い合わせ内容を選択してください
        </div>
        <div class="m__top--13">
          <label class="input--checked">
            <input type="checkbox" checked="checked" v-model="type.service">
            <div class="checkmark"></div>
            本サービスについて
          </label>
        </div>
        <div class="m__top--8">
          <label class="input--checked">
            <input type="checkbox" checked="checked" v-model="type.weakness">
            <div class="checkmark"></div>
            不具合について
          </label>
        </div>
        <div class="m__top--8">
          <label class="input--checked">
            <input type="checkbox" checked="checked" v-model="type.other">
            <div class="checkmark"></div>
            その他
          </label>
        </div>
      </div>
      
      <div class="box-select-request">
        <div class="title-select-request">
          詳細
        </div>
        <div class="form-input">
          <textarea class=" w-480-px" placeholder="お問い合わせ内容の詳細をご記入ください" v-model="content"></textarea>
        </div>
      </div>
  
      <div class="box-action">
        <ButtonComponent text="キャンセル" mode="btn-tertiary" :func="clearChange"/>
        <div>
          <ButtonComponent v-if="!isLoading" text="送信" mode="btn-primary" :func="openPopup"/>
          <div v-else class="donut"></div>
        </div>
      </div>
    </div>
    <PopupConfirm ref="confirm" :widthDialog="402" :heightDialog="402" :isBtnClose="false">
        <div slot="body-popup">
          <div class="box-content-popup">
            <div class="popup-title">
              お問い合わせを受付ました。
            </div>
            <div class="popup-content">
              お問い合わせありがとうございます。
              担当者から返信させていただきますので
              今しばらくお待ちください。
            </div>
            <div class="popup-description">
              お問い合わせの状況により返信に数日かかる
              ことがございます。ご了承お願いいたします。
            </div>
          </div>
        </div>
      <div slot="footer-popup">
        <ButtonComponent  text="OK" mode="btn-primary" :func="closePopup" class="btn-close-popup"/>
      </div>
    </PopupConfirm>
  </div>
</template>
<script>
import HeaderPageComponent from "@/components/common/HeaderPageComponent";
import ButtonComponent from "@/components/common/ButtonComponent";
import PopupConfirm from "@/components/common/PopupConfirm";
import { UserService } from '@/services';

export default {
  name: "AccountSetting",
  components: {
    HeaderPageComponent,
    ButtonComponent,
    PopupConfirm,
  },
  data() {
    return {
      arrowLeft: require('@/assets/images/icons/arrow_left.svg'),
      type: {
        service: false,
        weakness: false,
        other: false,
      },
      content: '',
      isLoading: false,
    }
  },
  methods: {
    openPopup() {
      const types = Object.values(this.type).map((item, index) => item ? index : null).filter(item => item != null)
      if (types && types.length !== 0) {
        if (this.content && this.content.trim().length !== 0) {
          this.isLoading = true
          const data = {
            type: types,
            content: this.content
          }
          UserService.contact(data).then(() => {
            this.$refs.confirm.open()
            this.isLoading = false
          }).catch(err => {
            if (err.status < 500) {
              this.$toast.error(err.data.message)
            } else {
              this.$toast.error(this.$store.getters.error)
            }
            this.isLoading = false
          })
        } else {
          this.$toast.error('お問い合わせ内容の詳細をご記入ください。')
        }
      } else {
        this.$toast.error('お問い合わせ内容を選択してください。')
      }
    },
    closePopup() {
      this.$refs.confirm.close()
    },
    clearChange() {
      this.type = {
        service: false,
        weakness: false,
        other: false,
      }
      this.content = ''
    }
  }
}
</script>
<style scoped lang="scss">
textarea {
  resize: none;
}
.box-contact {
  margin-top: 24px;
  textarea {
    resize: none;
  }
  .box-title {
    display: flex;
    align-items: center;
    
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: #333333;
    }
  }
  
  .box-content-contact {
    background: #FFFFFF;
    box-shadow: 0 4px 8px rgba(173, 182, 177, 0.1);
    border-radius: 4px;
    padding: 24px 40px;
    
    .title-content-contact {
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
      letter-spacing: 0.005em;
      color: #333333;
      margin-bottom: 32px;
      border-bottom: 1px solid #EAEDEB;
      padding: 24px 0;
    }
    
    .box-select-request {
      margin-top: 30px;
      
      .title-select-request {
        font-weight: 700;
        font-size: 14px;
        line-height: 200%;
        letter-spacing: 0.005em;
        color: #333333;
      }
    }
  
    .form-input textarea {
      padding: 8px 12px;
      gap: 10px;
      width: 480px;
      height: 168px;
      background: #FFFFFF;
      border: 1px solid #99BFF7;
      border-radius: 4px;
    }
  
    .form-input textarea::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 200%;
      letter-spacing: 0.005em;
      color: #C2C2C2;
    }
    .box-action {
      display: flex;
      justify-content: center;
      padding: 52px 0 68px 0;
      .btn-tertiary {
        width: 113px !important;
        height: 40px !important;
        margin-right: 24px;
      }
      .btn-primary {
        width: 65px !important;
        height: 40px !important;
      }
    }
  }
}
.box-content-popup {
  padding : 64px 64px 0;
  margin-bottom: 8px;
  .popup-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 200%;
    letter-spacing: 0.005em;
    color: #333333;
  }
  .popup-content {
    font-weight: 500;
    font-size: 16px;
    line-height: 175%;
    letter-spacing: 0.005em;
    color: #333333;
    margin: 16px 0;
  }
  .popup-description {
    font-weight: 500;
    font-size: 12px;
    line-height: 175%;
    letter-spacing: 0.005em;
    color: #858585;
  }
}
.btn-close-popup {
  width: 180px !important;
  height: 48px !important;
}
</style>