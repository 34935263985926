import Vue from 'vue'
import App from './App.vue'
import router from '@/routers';
import vuetify from './plugins/vuetify'
import VueToast from 'vue-toast-notification';
import store from '@/stores'
import { ApiService } from '@/services';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import 'vue-toast-notification/dist/theme-default.css';
import Vuelidate from 'vuelidate';
import i18n from '@/lang/i18n';
import VueMoment from 'vue-moment';
import momenttz from 'moment-timezone';


ApiService.init()
ApiService.setHeader()

Vue.config.productionTip = false

Vue.use(VueToast, {
  position: 'top-right'
});

Vue.use(Vuelidate)

const moment = require('moment')
require('moment/locale/ja')
require('moment/locale/en-gb')
momenttz.tz.setDefault(process.env.VUE_APP_TIMEZONE);
momenttz.locale(process.env.VUE_APP_LOCALE == "jp" ? "ja" : "en-gb");
Vue.use(VueMoment, {
    moment
});

new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
