<template>
    <div>
        <layout-app :isLogin="false"></layout-app>
        <div class="app-container">
            <div class="content register-content">
                <div class="logo">
                    <img :src="logo" alt="">
                </div>

                <div class="description-form fs-16 font-weight-bold m__top--48 color__primary_black">
                    メールアドレスを入力し、スタートアップまたはエンジェル投資家として <br>
                    登録するかを選択してください。
                </div>

                <div class="form-group m__top--48 ">
                    <div class="fs-14 font-weight-bold color__primary_black">使用目的を選択してください。</div>
                    <div class="radio-group m__top--16">
                        <div class="radio-button" @click="selectOption(radio.value)" v-for="radio,index in radios" :key="index">
                            <img :src="option == radio.value ? iconRadioActive : iconRadio" alt="">
                            <span :class="{ active : option == radio.value}" class="radio-button-label m__left--12 m__top--0">{{radio.text}}</span>
                        </div>
                    </div>

                    <div v-if="option == 1">
                        <label class="m__top--32 m__bottom--8">Eメールアドレス</label>
                        <input
                            v-model.trim="v$.form.email.$model"
                            @input="v$.form.email.$reset"
                            class="form-control large"
                            placeholder="carte@exampel.com">
                        <div v-if="v$.form.email.$errors">
                            <div v-if="v$.form.email.required.$invalid" class="error-text">メールを入力してください。</div>
                            <div v-else-if="v$.form.email.email.$invalid" class="error-text">有効なメールアドレスをご入力ください。</div>
                            <div v-else-if="v$.form.email.maxLength.$invalid" class="error-text">メールアドレスは255文字以内でご入力ください。</div>
                        </div>
                    </div>

                    <div v-if="option === 2" class="facebook-login w-550-px mx-auto mt-12">
                        <div class="d-flex w-100 justify-center align-center py-2" @click="loginWithFacebook">
                            <img :src="icFacebook" alt="">
                            <div class="font-weight-bold fs-14 ml-3">Facebookで登録</div>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-center m__top--48">
                    <button-component
                        :class="{'mr-6': option == 1}"
                        :text="'戻る'"
                        :small=false
                        :func="nextPage"
                        :mode="'btn-tertiary'"
                        :width="widthButton"></button-component>
                    <div v-if="option == 1">
                        <button-component
                            v-if="!isLoading"
                            :text="textButton"
                            :small=false
                            :func="startupRegister"
                            :width="widthButton"
                        ></button-component>
                    <div v-else class="donut"></div>
                    </div>
                </div>
            </div>

            <div class="progress-bar d-flex justify-center m__top--56">
                <progress-component :step="step"></progress-component>
            </div>
        </div>
    </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import {required, email, maxLength} from "@vuelidate/validators"
import ButtonComponent from "@/components/common/ButtonComponent.vue";
import ProgressComponent from "@/components/common/ProgressComponent.vue";
import { UserService } from '@/services'
import LayoutApp from "@/components/layouts/LayoutApp.vue";

export default {
    components : {
        ButtonComponent,
        ProgressComponent,
        LayoutApp
    },
    data(){
        return {
            logo : require('@/assets/images/svgs/logo_carte.svg'),
            iconRadio : require('@/assets/images/icons/ic_radio.svg'),
            icFacebook: require('@/assets/images/icons/ic_facebook.svg'),
            iconRadioActive : require('@/assets/images/icons/ic_radio_active.svg'),
            textButton : "仮登録",
            widthButton : "150px",
            step : 1,
            option : 1,
            radios : [
                {
                    value : 1,
                    text : "スタートアップとして使用する"
                },
                {
                    value : 2,
                    text : "エンジェル投資家として使用する"
                }
            ],
            form: {
                email: ''
            },
            isLoading: false
        }
    },
    setup: () => ({ v$: useVuelidate({$lazy: true}) }),
    validations() {
        return {
            form: {
                email: {
                    required,
                    email,
                    maxLength: maxLength(255)
                },
            }
        }
    },
    methods: {
        startupRegister() {
            this.v$.form.$touch()
            if (!this.v$.form.$error) {
                this.isLoading = true
                const data = {
                    email: this.form.email,
                    role: 0
                }
                UserService.register(data).then(() => {
                    this.$router.push({name: 'register-step-3'})
                    this.isLoading = false
                }).catch(err => {
                    if (err.status !== 422) {
                        this.$toast.error(err.message !== '' ? err.message : err.data.message)
                    }
                    this.isLoading = false
                })
            }
        },
        loginWithFacebook() {
            window.location.href = `${process.env.VUE_APP_API_URL}/api/auth-investor/redirect`
        },
        selectOption(value) {
            this.option = value;
        },
        nextPage() {
            this.$router.push({name: 'register-step-1'})
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            const isAuthenticated = vm.$store.getters.accessToken || !!localStorage.getItem('accessToken')
            if (isAuthenticated) {
                vm.$router.push({name: 'home'})
            }
        })
    }
}
</script>
<style scoped lang="scss">
.description-form {
    line-height: 32px;
}
.facebook-login {
    cursor: pointer;
}
.facebook-login > div {
    background: #FBFBFB;
    border: 1px solid #EAEDEB;
    border-radius: 8px;
}
.facebook-login > div:hover {
    background: #d4d2d2;
}
</style>
