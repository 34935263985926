<template>
    <v-dialog v-model="show" width="512px">
        <v-card class="delete-round py-16 text-center">
            <div class="fs-18 w-100 d-flex justify-center text-left mb-4 font-weight-bold">
                {{roundName}}を削除します。
            <br>
                よろしいですか？
            </div>
            <div class="mb-8">一度削除すると元に戻すことはできません。</div>
            <div class="mb-8">
                <img v-if="isConfirm" :src="icCheckedlist" alt="" @click="isConfirm = false">
                <img v-else :src="icChecklist" alt="" @click="isConfirm = true">
                <span class="ml-1">最終確認をしました</span>
            </div>
            <div class="d-flex w-100 justify-center">
                <button-component
                    class="mr-3"
                    :text="'キャンセル'"
                    :small="false"
                    :func="() => {show = false}"
                    :width="'180px'"
                    :height="'48px'"
                    :mode="'btn-tertiary'"
                ></button-component>
                <div>
                    <div v-if="isLoading" class="donut"></div>
                    <button-component
                        v-else
                        class="ml-3"
                        :class="{blur: !isConfirm}"
                        :text="'削除する'"
                        :small="false"
                        :func="deleteRound"
                        :width="'180px'"
                        :height="'48px'"
                        :mode="'btn-structure'"
                    ></button-component>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import ButtonComponent from '@/components/common/ButtonComponent.vue'
export default {
    name: "DeleteRoundModal",
    components: {
        ButtonComponent,
    },
    props: {
        value: Boolean,
        roundId: String,
        roundName: String,
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isConfirm: false,
            icChecklist: require('@/assets/images/icons/ic_checklist.svg'),
            icCheckedlist: require('@/assets/images/icons/ic_checkedlist.svg')
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        deleteRound() {
            if (this.isConfirm) {
                this.$emit('delete-round', this.roundId)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.delete-round {
    font-weight: 500;
    img {
        cursor: pointer;
    }
    .blur {
        opacity: .5 !important;
    }
}
</style>
