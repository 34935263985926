<template>
    <div>
        <div class="main-container p__top--28 p__bottom--30">
            <TitleComponent :title="title" :hasBack="true" />
            <div class="main-content">
                <div class="notice-container">
                    <div class="notice-detail">
                        <div class="notice-tag mb-3">
                            <span class="px-1 py-1 fs-10" :style="{ background: findTag(this.$route.query.type).color }">{{ findTag(this.$route.query.type).text }}</span>
                        </div>
                        <div class="notice-title fs-20 mb-2">
                            {{ notice.body }}
                        </div>
                        <div class="notice-date fs-15 mb-7">
                            {{ formatDate(notice.created_at) }}
                        </div>
                        <div class="notice-content">
                            <!-- <div>「ailead」は、商談データを起点として「売れる」営業人材育成を実現するため、営業活動を自動で記録・可視化しデータ活用を促進する、最新AI技術搭載の商談解析クラウドです。 お使いのWeb会議ツール（Zoom、Google Meet、Microsoft Teamsに対応）と連携することで既存の業務フローを全く変えずにご利用可能。さらには文字起こし機能やSalesforce連携機能をご活用いただくことで、面倒な情報共有を効率化することができます。</div>
                            <br>
                            <div>2021年7月にβ版の提供を開始、上場企業を始めとした様々な事業規模の営業組織を中心にβ版をご利用いただく中で、約8ヶ月間にわたりお客様からの声を反映して機能改善を重ね、この度正式にリリースいたしました。</div>
                            <br>
                            <div>資料ダウンロードはこちら：https://www.ailead.app/</div> -->
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TitleComponent from "@/components/common/Title.vue"
import { UserService } from '@/services'
import { news_type } from '@/commons/plan_const'
import { formatDateJp } from '@/commons/helpers'

export default {
    name: "NoticeList",
    components: {
        TitleComponent,
    },
    data() {
        return {
            title: 'お知らせ詳細',
            tags: news_type,
            notice: {}
        }
    },
    methods: {
        findTag(type = null) {
            return this.tags.find(item => item.type == type);
        },
        formatDate(date = null) {
            return formatDateJp(date);
        },
        // api
        getDetail(){
            const params = {
                id : this.$route.query.id,
                type : this.$route.query.type
            }
            UserService.getOneNews(params).then((response) => {
                this.notice = response.data.data;
            })
            .catch(() => {});
        }
    },
    created() {
        if (this.$route.query.id && this.$route.query.type) {
            this.getDetail();
        }
        
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.main-content {
    background: $white;
    position: relative;
    box-shadow: 0px 4px 8px rgba(173, 182, 177, 0.1);
    border-radius: 4px;
    padding: 52px 20%;
}
.notice-date {
    color: #858585;
    font-weight: normal;
}
.notice-tag {
    color: white;
    font-weight: 700;
}
.notice-tag span {
    border-radius: 4px;
}
.notice-title {
    color: #333333;
    font-weight: bold;
}
.active-tab {
    font-weight: bold;
    color: #333333 !important;
}
</style>