<template>
    <div>
        <layout-app :isLogin="false"></layout-app>
        <div class="app-container">
            <div class="content register-content">
                <div class="logo">
                    <img :src="logo" alt="">
                </div>
                <div class="title-form fs-32 font-weight-bold m__top--48 color__primary_black">仮登録が完了しました。</div>
                <div class="description-form fs-16 font-weight-medium m__top--16 color__primary_black">
                    仮登録状態です。カルテ運営があなたの会社を承認すると、<br>
                    本登録となります。しばらくお待ち下さい。<br>
                    本登録された時はご登録されたURLにメールでお知らせいたします。
                </div>
            </div>

            <div class="progress-bar d-flex justify-center m__top--56">
                <progress-component :step="step"></progress-component>   
            </div>
        </div>
    </div>
</template>
<script>
import ProgressComponent from "@/components/common/ProgressComponent.vue";
import LayoutApp from "@/components/layouts/LayoutApp.vue";

export default {
    components : {
        ProgressComponent,
        LayoutApp
    },
    data(){
        return {
            logo : require('@/assets/images/svgs/logo_carte.svg'),
            textButton : "つかっみる",
            widthButton : "150px",
            step : 1
        }
    },
    methods: {
        nextPage() {
             this.$router.push({name: "register-step-2"});   
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            const isAuthenticated = vm.$store.getters.accessToken || !!localStorage.getItem('accessToken')
            if (isAuthenticated) {
                vm.$router.push({name: 'home'})
            }
        })
    }
} 
</script>
<style scoped lang="scss">
@import "@/assets/scss/index.scss";
.description-form { 
    line-height: 32px;
}
</style>