<template>
  <div class="box-contact">
    <TitleComponent :title="'退会'" :hasBack="true" />
    <div class="box-content-contact">
      <div class="title-content-contact">
        退会
      </div>
      <div class="box-select-request">
        <div class="title-select-request">
          退会手続きをいたします。よろしければ退会する理由をご記入ください。
          <br>
          更なるサービス向上のために参考にさせていただきます。
          <div class="m__top--24">
            退会する理由（任意）
          </div>
        </div>
        <div class="m__top--13">
          <label class="input--checked">
            <input type="checkbox" :value="0" v-model="type" checked="checked">
            <div class="checkmark"></div>
            やりたいことと違った
          </label>
        </div>
        <div class="m__top--8">
          <label class="input--checked">
            <input type="checkbox" :value="1" v-model="type" checked="checked">
            <div class="checkmark"></div>
            使いにくかった
          </label>
        </div>
        <div class="m__top--8">
          <label class="input--checked">
            <input type="checkbox" :value="2" v-model="type" checked="checked">
            <div class="checkmark"></div>
            その他
          </label>
        </div>
        <template v-if="submitted" >
            <span class="error error-message m__top--8" v-if="!type.length">{{
                $t("messages.checkbox_reason_delete_account")
            }}</span>
        </template>
      </div>

      <div class="box-select-request">
        <div class="title-select-request">
          その他を選んだ方は詳細をご記入ください（任意）
        </div>
        <div class="form-input">
          <textarea class=" w-480-px" placeholder="退会する理由の詳細をご記入ください" v-model.trim="reason"></textarea>
        </div>
      </div>

      <div class="box-action">
        <ButtonComponent text="キャンセル" mode="btn-tertiary" :func="reset"/>
        <ButtonComponent text="送信" mode="btn-structure" :func="openPopup"/>
      </div>
    </div>
    <PopupConfirm ref="confirm" :widthDialog="512" :isBtnClose="false">
      <div slot="body-popup">
        <div class="box-content-popup">
          <div class="popup-title">
            退会します
          </div>
          <div class="popup-content">
            <div style="text-align: left !important;">
              退会すると、今まで作成した資本政策や
              <br>
              株主のデータは全失われます。
              <br>
              それでも退会しますか？
            </div>
          </div>
          <div class="action-check">
            <label class="input--checked">
              <input type="checkbox" v-model="checkDelete">
              <div class="checkmark"></div>
              <div class="text-action-check">
                最終確認をしました
              </div>
            </label>
          </div>
        </div>
      </div>
      <div slot="footer-popup">
        <ButtonComponent text="キャンセル" mode="btn-tertiary btn-footer-popup" class="m__right--24" :func="closePopup"/>
        <ButtonComponent text="退会する" mode="btn-structure btn-footer-popup" :func="deleteAccount" class="btn-close-popup" :statusDisabled="!checkDelete" :loading="loadingButton"/>
      </div>
    </PopupConfirm>
  </div>
</template>
<script>
import ButtonComponent from "@/components/common/ButtonComponent";
import TitleComponent from "@/components/common/Title.vue"
import PopupConfirm from "@/components/common/PopupConfirm";
import { UserService } from '@/services';
export default {
  name: "DeleteAccount",
  components: {
    ButtonComponent,
    PopupConfirm,
    TitleComponent
  },
  data() {
    return {
      arrowLeft: require('@/assets/images/icons/arrow_left.svg'),
      tab: null,
      checkDelete: false,
      reason: '',
      submitted: false,
      loadingButton: false,
      type: []
    }
  },
  methods: {
    openPopup() {
      if (!this.type.length) {
        this.submitted = true;
        return;
      }
      if (this.type.find(item => item == 2)) {
        this.submitted = true;
      }
      this.$refs.confirm.open();
      this.checkDelete = false;
    },
    closePopup() {
      this.$refs.confirm.close()
      this.checkDelete = false ;
    },
    reset() {
      this.type = [];
      this.checkDelete = false;
      this.reason = '';
      this.submitted = false;
      this.loadingButton = false;
    },
    // api
    deleteAccount() {
      this.loadingButton = true;
      let data = {
        type: this.type,
        body: this.reason
      }
      UserService.deleteAccount(data)
      .then(() => {
        this.closePopup();
        this.submitted = false;
        this.loadingButton = false;
        this.logout();
      })
      .catch(() => {
        this.loadingButton = false;
      })
    },
    logout() {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('accessToken');
      this.$router.push({ name : 'Delete Account Complete'}).catch(()=>{});
    },

  }
}
</script>
<style scoped lang="scss">
.box-contact {
  margin-top: 24px;

  .box-title {
    display: flex;
    align-items: center;

    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: #333333;
    }
  }

  .box-content-contact {
    background: #FFFFFF;
    box-shadow: 0 4px 8px rgba(173, 182, 177, 0.1);
    border-radius: 4px;
    padding: 24px 40px;

    .title-content-contact {
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
      letter-spacing: 0.005em;
      color: #333333;
      margin-bottom: 32px;
      border-bottom: 1px solid #EAEDEB;
      padding: 24px 0;
    }

    .box-select-request {
      margin-top: 30px;

      .title-select-request {
        font-weight: 700;
        font-size: 14px;
        line-height: 200%;
        letter-spacing: 0.005em;
        color: #333333;
      }
    }

    .form-input textarea {
      padding: 8px 12px;
      gap: 10px;
      width: 480px;
      height: 168px;
      background: #FFFFFF;
      border: 1px solid #99BFF7;
      border-radius: 4px;
    }

    .form-input textarea::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 200%;
      letter-spacing: 0.005em;
      color: #C2C2C2;
    }
    .box-action {
      display: flex;
      justify-content: center;
      padding: 52px 0 68px 0;
      .btn-tertiary {
        width: 113px !important;
        height: 40px !important;
        margin-right: 24px;
      }
      .btn-structure {
        width: 97px !important;
        height: 40px !important;
      }
    }
  }
}
.box-content-popup {
  padding : 64px 64px 0;
  margin-bottom: 8px;
  .popup-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 200%;
    letter-spacing: 0.005em;
    color: #333333;
  }
  .popup-content {
    font-weight: 500;
    font-size: 16px;
    line-height: 175%;
    letter-spacing: 0.005em;
    color: #333333;
    justify-content: center;
    display: flex;
    margin: 16px 0 32px 0;
  }
  .popup-description {
    font-weight: 500;
    font-size: 12px;
    line-height: 175%;
    letter-spacing: 0.005em;
    color: #858585;
  }
}
.btn-close-popup {
  width: 180px !important;
  height: 48px !important;
}
.action-check {
  justify-content: center;
  display: flex;
  .text-action-check {
    font-weight: 500;
    font-size: 14px;
    line-height: 200%;
    letter-spacing: 0.005em;
    color: #333333;
    width: 127px;
  }
}
.btn-footer-popup {
  width: 180px !important;
  height: 48px !important;
}
.error.error-message {
  font-size: 14px;
  line-height: 24px;
  color: #ce6060;
  background-color: #fff!important;
  border-color: #fff!important;
}
</style>
