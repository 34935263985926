export const api_router = [
    // auth
    {
        name: "api.login",
        path: "login",
    },
    {
        name: "api.logout",
        path: "logout",
    },
];