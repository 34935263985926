<template>
  <v-btn :class=classButton @click="func" :style="{width: width ?? 87.49 + 'px', height: height ?? 44  + 'px'}" :disabled="statusDisabled" :loading="loading">
    
    <img :src="icon" alt="icon" v-if="icon" class="me-1">
    {{
      text
    }}
    <img class="icon-suffix m__left--4" v-if="suffixIcon" :src="suffixIcon" alt="">
  
  </v-btn>
</template>
<script>
export default {
  name: "ButtonComponent",
  props: {
    text: String,
    mode: String,
    small: Boolean,
    active: Boolean,
    statusDisabled: {
      type: Boolean,
      default: false
    },
    func: Function,
    width: String,
    height: String,
    suffixIcon: String,
    icon: String,
    loading: Boolean
  },
  data() {
    return {
      classButton: ""
    }
  },
  created() {
    this.classButton = this.mode ?? "btn-primary"
    
    if (this.small) {
      this.classButton = this.classButton + " btn-small"
    }
    
    if (this.active) {
      if (this.mode === "btn-secondary") {
        this.classButton = this.classButton + " btn-secondary-active"
      } else if (this.mode === "btn-tertiary") {
        this.classButton = this.classButton + " btn-tertiary-active"
      } else if (this.mode === "btn-structure") {
        this.classButton = this.classButton + " btn-structure-active"
      } else {
        this.classButton = this.classButton + " btn-primary-active"
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/index.scss";

.btn-primary {
  background-color: $corporate_color_1 !important;
  color: $white !important;
  border-radius: 4px !important;
  padding: 12px 16px !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 20.27px;
  box-shadow: none !important;
  
  &.disabled {
    opacity: 0.5 !important;
    cursor: default !important;
    
    &:hover {
      background-color: $corporate_color_1 !important;
    }
  }
}

.btn-primary-outline {
  background-color: $white !important;
  color: $corporate_color_1 !important;
  border-radius: 4px !important;
  padding: 12px 16px !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 20.27px;
  box-shadow: none !important;
  border: solid 1px $corporate_color_1 !important;
}

.btn-primary:hover {
  background-color: $corporate_color_2 !important;
}

.btn-primary-active {
  background-color: $corporate_color_7 !important;
}

.btn-secondary {
  background-color: $white !important;
  color: $corporate_color_1 !important;
  border: 1px solid $corporate_color_1 !important;
  border-radius: 4px !important;
  padding: 12px 16px !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 20.27px;
  box-shadow: none !important;
}


.btn-secondary:hover {
  background-color: $corporate_color_6 !important;
}

.btn-secondary-active {
  background-color: $corporate_color_5 !important;
}

.btn-tertiary {
  background-color: $white !important;
  color: $primary_secondary !important;
  border: 1px solid $primary_secondary !important;
  border-radius: 4px !important;
  padding: 12px 16px !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 20.27px;
  box-shadow: none !important;
}

.btn-tertiary:hover {
  background-color: $key_line_color_2 !important;
}

.btn-tertiary-active {
  background-color: $key_line_color_1 !important;
}

.btn-structure {
  background-color: $structure_color_2 !important;
  color: $white !important;
  border-radius: 4px !important;
  padding: 12px 16px !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 20.27px;
}

.btn-structure:hover {
  background-color: $structure_color_1 !important;
}

.btn-structure-active {
  background-color: $structure_color_3 !important;
}

.btn-small {
  height: 28px !important;
}

.btn-cancel {
  color: $primary_secondary !important;
  border: 1px solid $primary_secondary !important;
  padding: 2px 4px !important;
  font-size: 10px !important;
  line-height: 14.48px !important;
  font-weight: 400 !important;
}

.btn-save {
  color: $corporate_color_1 !important;
  border: 1px solid $corporate_color_1 !important;
  padding: 2px 4px !important;
  font-size: 10px !important;
  line-height: 14.48px !important;
  font-weight: 400 !important;
  box-shadow: none !important;
}

.button-back {
  color: $primary_black !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border: none !important;
  background: none !important;
  box-shadow: unset !important;
}

.button-back:hover {
  background: none !important;
}

.btn-structure.v-btn.v-btn--disabled.v-btn--has-bg:disabled {
  background-color: $structure_color_2 !important;
  color: $white !important;
  opacity: 0.5;
}

.button-back:before {
  background: none !important;
}

button {
  min-width: unset !important;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg.btn-primary {
  background: $corporate_color_1!important  ;
  opacity: 0.3;
  color: $white !important;
}
</style>