import { render, staticRenderFns } from "./FormHeader.vue?vue&type=template&id=545381d7&scoped=true&"
import script from "./FormHeader.vue?vue&type=script&lang=js&"
export * from "./FormHeader.vue?vue&type=script&lang=js&"
import style0 from "./FormHeader.vue?vue&type=style&index=0&id=545381d7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "545381d7",
  null
  
)

export default component.exports