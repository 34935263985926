<template>
  <div class="box-title">
    <ButtonComponent :text="textBtn" mode="button-back" :icon="arrowLeft" :func="() => $router.go(-1)"/>
    <div class="font-weight-bold fs-18">
      {{ textTitle }}
    </div>
  </div>
</template>
<script>
import ButtonComponent from "@/components/common/ButtonComponent";

export default {
  name: "HeaderPageComponent",
  props: {
    textBtn: String,
    textTitle: String,
  },
  components: {
    ButtonComponent,
  },
  data() {
    return {
      arrowLeft: require('@/assets/images/icons/arrow_left.svg'),
    }
  },
  computed: {
    localStore() {
      return localStorage
    }
  }
}
</script>
<style scoped lang="scss">
.box-title {
  display: flex;
  align-items: center;
  
  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #333333;
  }
}
</style>