<template>
  <div>
    <layout-app :isLogin="false"></layout-app>
    <div v-if="!isPreviewed" class="app-container manual-container">
      <div class="content register-content">
        <div class="logo">
          <img :src="logo" alt="">
        </div>
        <div class="fs-16 font-weight-medium m__top--48 color__primary_black">資本政策を作成します。以下の項目をご入力ください。</div>

        <div class="title-form">基本情報</div>
        <div class="divider"></div>
        <div class="form-input m__top--16">
          <label>ラウンド名 (必須)</label>
          <div class="form-control">
            <div class="form-control-input text-left">
              <input :class="{ error : submitted && $v.form.round_name.$error }" class="form-control w-480-px"
                     v-model.trim="form.round_name">
            </div>
          </div>
          <template v-if="submitted && $v.form.round_name.$error">
            <span class="error-message" v-if="!$v.form.round_name.required">
              {{ $t('validation.manual_input.round_name.required') }}
            </span>
          </template>
        </div>
        <div class="form-input  m__top--24">
          <label>実施日 (必須)</label>
          <div class="form-control">
            <div class="form-control-input">
              <select-component
                :element="'year'"
                :datas="yearDropDown"
                :width="124"
                @select="form.execution_year = $event"
                :currentValue="form.execution_year"
                :class="{ error : submitted && (requiredDate || errorDate) }"></select-component>
              <span class="suffix">年</span>
            </div>
            <div class="form-control-input">
              <select-component
                :element="'month'"
                :datas="monthDropDown"
                :width="124"
                @select="form.execution_month = $event"
                :currentValue="form.execution_month"
                :class="{ error : submitted && (requiredDate || errorDate) }"></select-component>
              <span class="suffix">月</span>
            </div>
          </div>
          <template v-if="submitted">
            <span class="error-message" v-if="requiredDate">
              {{ $t('validation.manual_input.execution_date.required') }}
            </span>
            <span class="error-message" v-if="errorDate">
              {{ $t('validation.manual_input.execution_date.invalid_date') }}
            </span>
          </template>
        </div>

        <div class="form-input  m__top--24">
          <label>発行株価 (必須)</label>
          <div class="form-control">
            <div class="form-control-input">
              <input v-model="form.stock_price" :class="{ error : submitted && $v.form.stock_price.$error }"
                     class="form-control w-240-px" style="width : 240px" @keypress="forceNumber($event)">
              <span class="suffix">円</span>
            </div>
          </div>
          <template v-if="submitted && $v.form.stock_price.$error ">
            <span class="error-message" v-if="!$v.form.stock_price.required">
              {{ $t('validation.required', {field: '株価'}) }}
            </span>
            <span class="error-message" v-else-if="!$v.form.stock_price.integer">
              {{ $t('validation.manual_input.stock_price.integer') }}
            </span>
            <span class="error-message" v-if="invalidStock">
              {{ $t('validation.manual_input.stock_price.invalid') }}
            </span>
          </template>
        </div>

        <div class="title-form">新規株主ごとの割り当株数</div>
        <div class="divider"></div>
        <div v-for="stock, index in stocks" :key="'stock'+ index" class="d-flex hold-stock">
          <div class="form-input">
            <label v-if="!index">株主名</label>
            <div class="form-control m__right--30">
              <v-combobox
                :class="{ 'combo-error' : submitted && $v.stocks.$each[index].investor_name.$error }"
                class="form-control w-30"
                v-model.trim="stock.investor_name"
                append-icon=""
                :items="items"
                flat
                solo
                @update:search-input="getItems($event, index)"
                @blur="resetData()"
              >
                <template v-slot:append-item>
                  <v-card
                    v-if="lastPage !== currentPage"
                    v-intersect="visibilityChanged"
                    class="text-xs-center"
                  >
                  </v-card>
                </template>
              </v-combobox>
<!--              <div class="form-control-input">-->
<!--                <select-component-->
<!--                  :currentValue="stock.financing_method_id"-->
<!--                  :element="'financing-method' + index"-->
<!--                  :datas="fiMethods"-->
<!--                  :width="320"-->
<!--                  @select="stock.financing_method_id = $event"-->
<!--                ></select-component>-->
<!--              </div>-->
            </div>
            <div class="error-message" v-if="$v.stocks.$each[index].investor_name.$error">
              <span v-if="!$v.stocks.$each[index].investor_name.required">
                {{ $t('policy_toast.name_required') }}
              </span>
              <span v-else-if="!$v.stocks.$each[index].investor_name.maxLength">
                {{ $t('policy_toast.investor_maxLength') }}
              </span>
            </div>
          </div>
          <div class="form-input">
            <label v-if="!index">割り当株数</label>
            <div class="form-control">
              <div class="form-control-input">
                <input :class="{ error : submitted && $v.stocks.$each[index].number_of_shares.$error }"
                       class="form-control w-140-px" v-model="stock.number_of_shares" @keypress="forceNumber($event)">
                <span class="suffix">株</span>
              </div>
            </div>
            <div class="error-message" v-if="$v.stocks.$each[index].number_of_shares.$error">
              <span v-if="!$v.stocks.$each[index].number_of_shares.required">
                {{ $t('policy_toast.number_of_shares_required') }}
              </span>
              <span v-else-if="!$v.stocks.$each[index].number_of_shares.integer">
                {{ $t('validation.manual_input.stock_price.integer') }}
              </span>
              <span v-else-if="!$v.stocks.$each[index].number_of_shares.minValue">
                {{ $t('policy_toast.number_of_shares_required') }}
              </span>
            </div>
          </div>
          <div class="form-input">
            <label v-if="!index">形式</label>
            <div class="form-control">
              <div class="form-control-input">
                <select-component
                  :currentValue="stock.financing_method_id"
                  :element="'financing-method' + index"
                  :datas="fiMethods"
                  :width="320"
                  @select="stock.financing_method_id = $event"
                ></select-component>
              </div>
            </div>
          </div>
          <div class="form-input relative" v-if="includePreferred">
            <label class="mr-6" v-if="!index">配当分配権倍率</label>
            <div class="form-control" v-if="stock.financing_method_id == 2">
              <div class="form-control-input">
                <input class="form-control w-72-px"
                       :class="{ error : submitted && $v.stocks.$each[index].preferred_stock_magnification.$error }"
                       v-model="stock.preferred_stock_magnification" @keypress="forceNumber($event)">
                <span class="suffix">倍</span>
              </div>
            </div>
            <div class="error-message text-left" style="white-space: nowrap"
                 v-if="$v.stocks.$each[index].preferred_stock_magnification.$error">
              <span v-if="!$v.stocks.$each[index].preferred_stock_magnification.required">
                {{ $t('policy_toast.magnitude_required') }}
              </span>
              <span v-else-if="!$v.stocks.$each[index].preferred_stock_magnification.minValue">
                {{ $t('policy_toast.magnitude_min') }}
              </span>
            </div>
          </div>
          <div class="form-input relative" v-if="includePreferred">
            <label class="mr-8" v-if="!index">優先株 参加 / 非参加</label>
            <div class="form-control" v-if="stock.financing_method_id == 2">
              <div class="form-control-input">
                <select-component
                  :currentValue="stock.is_preferred_stock_participation"
                  :element="'is_preferred_stock_participation_' + index"
                  :datas="participationOptions"
                  :width="124"
                  @select="stock.is_preferred_stock_participation = $event"
                ></select-component>
              </div>
            </div>
          </div>
          <div :class="{'pt-4': index, 'pt-13': !index, 'justify-end': includePreferred}"
               class="d-flex align-center ml-4" style="flex-grow: 1">
            <img :src="iconCross" alt="" style="cursor: pointer" @click="deleteStock(index)">
          </div>
        </div>
        <div class="icon-add-shareholder">
          <img :src="iconPlus" alt="" @click="addStock">
          <span>株主を追加</span>
        </div>
        <div class="wrapper-button m__top--48 d-flex justify-center">
          <button-component
            class="m__right--24"
            :text="textPreviewButton"
            :small=false
            :func="preview"
            :width="widthButton"
            :mode="'btn-secondary'"
          ></button-component>
          <div>
            <button-component
              v-if="!isLoading"
              :text="textCreateButton"
              :small=false
              :func="() => {confirmModal = true}"
              :width="widthButton"
            ></button-component>
            <div class="donut" v-else></div>
          </div>
        </div>
      </div>
      <div class="progress-bar d-flex justify-center m__top--56">
        <progress-component :step="step"></progress-component>
      </div>
    </div>
    <Preview v-if="isPreviewed" :data="previewData" :isManualInput="true" @closePreview="closePreview"/>
    <v-dialog v-model="confirmModal" width="472px">
      <v-card style="position: relative; padding: 64px 68px">
        <div class="font-weight-bold fs-18 text-center mb-4">資本政策を作成します。</div>
        <div class="mb-12">
          <p style="font-size: 16px; font-weight: 500; line-height: 175%; ">このまま保存して作成終了するか、</p>
          <p style="font-size: 16px; font-weight: 500; line-height: 175%; ">続けて別ラウンドを追加することもできます。</p>
        </div>
        <div class="d-flex justify-center">
          <button-component
            class="m__right--24"
            :text="'キャンセル'"
            :func="() => {confirmModal = false}"
            :width="'160px'"
            :height="'44px'"
            :mode="'btn-tertiary'"
          ></button-component>
          <button-component
            class=""
            :text="'資本政策を保存'"
            :func="() => {handleAddOrEdit(false)}"
            :width="'160px'"
            :height="'44px'"
            :mode="'btn-primary'"
          ></button-component>
        </div>
        <div class="mt-12 text-center" @click="handleAddOrEdit(true)">
          <p style="font-size: 14px; font-weight: 700; line-height: 28px; cursor: pointer; text-decoration: underline">作成後別のラウンドを続けて作成</p>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ButtonComponent from "@/components/common/ButtonComponent.vue";
import ProgressComponent from "@/components/common/ProgressComponent.vue";
import Select from "@/components/common/Select.vue";
import LayoutApp from "@/components/layouts/LayoutApp.vue";
import Preview from "@/components/Home/Preview.vue"
import {UserService} from '@/services';
import {financing_methods} from '@/commons/plan_const'
import {required, minValue, integer, maxValue, maxLength, requiredIf} from "vuelidate/lib/validators";
import {selectMonths} from "@/commons/helpers";


export default {
  components: {
    ButtonComponent,
    "select-component": Select,
    LayoutApp,
    ProgressComponent,
    Preview,
  },
  data() {
    return {
      logo: require('@/assets/images/svgs/logo_carte.svg'),
      iconPlus: require('@/assets/images/icons/ic_plus.svg'),
      iconCross: require('@/assets/images/svgs/Cross.svg'),
      textPreviewButton: "プレビュー",
      textCreateButton: "資本政策を作成",
      widthButton: "150px",
      isLoading: false,
      step: 3,
      participationOptions: [
        {
          value: 0,
          text: '非参加'
        },
        {
          value: 1,
          text: '参加'
        }
      ],
      stocks: [],
      listShareholder: [],
      fiMethods: [],
      form: {
        round_name: '',
        execution_year: new Date().getYear() + 1900,
        execution_month: new Date().getMonth() + 1,
        stock_price: '',
      },
      financing_methods,
      currentShareholder: [],
      submitted: false,
      isPreviewed: false,
      previewData: {},
      lastPage: 0,
      isLastPage: false,
      currentPage: 1,
      items: [],
      searchInvestor: '',
      confirmModal: false,
      stay: false
    }
  },
  validations() {
    let validate = {
      form: {
        execution_month: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(12),
        },
        execution_year: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(9999),
        },
        round_name: {
          required
        },
        stock_price: {
          required,
          integer
        }
      },
      stocks: {
        $each: {
          investor_name: {
            required,
            maxLength: maxLength(100)
          },
          number_of_shares: {
            required,
            integer,
            minValue: minValue(1)
          },
          preferred_stock_magnification: {
            required: requiredIf(stock => {
              return stock.financing_method_id === 2;
            }),
            minValue: minValue(1)
          },
        }
      }
    }
    return validate;
  },
  computed: {
    formattedDate() {
      return this.form.execution_year + '-' + this.form.execution_month + '-01'
    },
    errorDate() {
      return this.$v.form.execution_year.$error || this.$v.form.execution_month.$error
    },
    requiredDate() {
      return this.$v.form.execution_year.required.$invalid || this.$v.form.execution_month.required.$invalid
    },
    includeCommon() {
      let count = 0
      this.stocks.forEach(item => {
        if (this.isCommonStock(item.financing_method_id)) {
          count++
        }
      })
      return count;
    },
    includePreferred() {
      let count = 0
      this.stocks.forEach(item => {
        if (this.isPreferredStock(item.financing_method_id)) {
          count++
        }
      })
      return count;
    },
    invalidStock() {
      return !!this.includeCommon && !!this.includePreferred
    },
    yearDropDown() {
      const listYears = [];
      const year = new Date().getFullYear();
      for (let i = -100; i < 99; i++) {
        listYears.push({
          text: year + i,
          value: year + i
        })
      }
      return listYears;
    },
    monthDropDown() {
      return selectMonths();
    }
  },
  created() {
    this.getFinancingMethods();
    const data = this.$route.params.data;
    if (data && Object.keys(data).length) {
      this.form.round_name = data.round_name;
      this.form.execution_year = data.execution_date.split('-')[0];
      this.form.execution_month = data.execution_date.split('-')[1];
      this.form.stock_price = data.stock_price;
      this.stocks = data.share_stock;
    }
  },
  methods: {
    visibilityChanged(e) {
      this.currentPage !== this.lastPage && this.lastPage !== 1 && e && this.loadMore();
    },
    async getItems(investor, index) {
      if (this.searchInvestor !== investor) {
        this.searchInvestor = investor
        this.stocks[index].investor_name = investor
        this.currentPage = 1
      }
      try {
        const {data} = await UserService.investorSuggest({page: this.currentPage, investor_name: investor});
        this.lastPage = parseInt(data.data.last_page)
        this.items = [
          ...data.data.investor_names,
        ]
      } catch (e) {
        //
      }
    },
    async loadMore() {
      try {
        const {data} = await UserService.investorSuggest({page: this.currentPage, investor_name: this.searchInvestor});
        if (data.data.investor_names && data.data.investor_names.length) {
          ++this.currentPage;
          this.lastPage = parseInt(data.data.last_page)
          this.items = [
            ...this.items,
            ...data.data.investor_names,
          ]
        }
      } catch (e) {
        //
      }
    },
    resetData() {
      this.currentPage = 1
      this.lastPage = 0
      this.searchInvestor = ''
    },
    async getFinancingMethods() {
      try {
        const {data} = await UserService.getFinancingMethods()
        data.data.forEach(method => {
          this.fiMethods.push({
            value: method.id,
            text: method.method
          })
        })
      } catch (err) {
        this.$toast.error(err.message)
      }
    },
    addOrEditRound() {
      const data = this.validateInput()
      if (data) {
        this.isLoading = true
        UserService.createOrUpdateCapitalPolicy(data, null).then(() => {
          if (!this.stay)
            this.$router.push({name: 'home'})
          else
            window.location.reload();
        }).catch(err => {
          this.isLoading = false
          this.$toast.error(err.data.message)
        })
      }
    },
    handleAddOrEdit(stay = false) {
      this.submitted = true;
      this.stay = stay
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.addOrEditRound()
    },
    nextPage() {
      this.$router.push({name: "home"});
    },
    preview() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.previewData = this.validateInput()
      if (this.previewData || this.previewData.length !== 0) {
        this.isPreviewed = true
      }
    },
    closePreview() {
      this.isPreviewed = false
    },
    validateInput() {
      let holders = []
      this.stocks.forEach(item => {
        holders.push({
          ...item,
        })
      })
      if (this.invalidStock) {
        this.$toast.error(this.$t('policy_toast.invalid_methods'));
        return;
      }
      return {
        round_name: this.form.round_name,
        execution_date: this.formattedDate,
        stock_price: parseInt(this.form.stock_price),
        share_stock: [
          ...holders,
        ]
      }
    },
    addStock() {
      const stock = {
        investor_name: '',
        number_of_shares: 0,
        financing_method_id: 3,
        preferred_stock_magnification: 1,
        is_preferred_stock_participation: 1
      }
      this.stocks.push(stock);
    },
    deleteStock(index) {
      if (index > -1) {
        this.stocks.splice(index, 1)
      }
    },
    forceNumber($event) {
      let value = String.fromCharCode($event.which);
      let pattern = /[0-9]+/
      if (pattern.test(value)) {
        return true
      } else {
        $event.preventDefault();
      }
    },
    isCommonStock(id) {
      return this.fiMethods.find(item => item.value == id).text.includes(financing_methods.common_share)
    },
    isPreferredStock(id) {
      return this.fiMethods.find(item => item.value == id).text.includes(financing_methods.preferred_share)
    },
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/index.scss";

.description-form {
  line-height: 32px;
}

.icon-add-shareholder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 18px;

  span {
    display: block;
    margin-left: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 200%;
    color: $primary-color;
  }

  img {
    cursor: pointer;
  }
}

.title-form {
  font-weight: 700;
  font-size: 18px;
  line-height: 200%;
  letter-spacing: 0.005em;
  color: $primary-color;
  text-align: left;
  margin-bottom: 4px;
  margin-top: 48px;
}

.divider {
  border-bottom: 1px solid #EAEDEB;
}

.form-input {
  label {
    white-space: nowrap;
  }
}

.hold-stock .form-input input
.sold-stock .form-input input {
  text-align: right;
}

.relative {
  position: relative;
}
</style>
<style lang="scss">
.manual-container {
  .v-input__slot {
    padding: 11px 12px !important;
    border: 1px solid #EAEDEB !important;
  }
}
</style>
