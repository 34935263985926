<template>
  <div>
    <Doughnut :chart-options="chartOptions"
              :chart-data="chartData"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="width"
              :height="height"/>
  </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  // Plugin
} from 'chart.js'
import { doughnutChartOption } from "./../../commons/chart";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)
export default {
  name: "DoughnutChartComponent",
  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    labels: {
      type: Array,
      // [A1, A2, A3, A4]
    },
    data: {
      type: Array,
      // [1, 2, 3, 4]
    },
    backgroundColors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    let chartOptions = doughnutChartOption;
    return {
      chartData: [],
      chartOptions,
      hoverOffset: 5
    }
  },

  created() {
    this.intData()
  },
  methods: {
    intData() {
      let backgroundColors = [];
      for (let i = 0; i < this.labels.length; i++ ) {
        backgroundColors.push('#'+(Math.random()*0xFFFFFF<<0).toString(16))
      }
      if (this.backgroundColors.length !== 0) {
        backgroundColors = [...this.backgroundColors]
      }
      this.chartData = {
        labels: this.labels,
        datasets: [
          {
            backgroundColor: backgroundColors,
            data: this.data,
            hoverOffset : this.hoverOffset,
            // hoverBorderColor: backgroundColors,
            // hoverBorderWidth: 6
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss">

</style>
