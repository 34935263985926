<template>
<div id="dropbox-file-upload">
    <div class="form-upload m__top--48">
        <div v-if="!files || files && !files.length" class="group-icon-upload">
            <label for="file-upload">
                <img class="icon-cloud-upload" :src="iconCloudUpload" alt="">
            </label>
            <input
                type="file"
                id="file-upload"
                @change="uploadFile($event)"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                multiple
            />
            <img v-if="isUploading" :src="iconExcel" alt="">
        </div>
        <div v-else class="group-icon-upload">
            <img :src="iconExcelUploadComplete" alt="">
        </div>
        <div class="fs-14 font-weight-bold color__primary_black m__top--10 text-center">
            <span v-if="!isUploading && ( !files || files && !files.length)" @click="triggerUpload" style="cursor: pointer">資本政策のアップロード</span>
            <span v-if="isUploading && ( !files || files && !files.length)">資本政策の中...</span>
            <span v-if="files && files.length">完了</span>
        </div>
        <div v-if="!files || files && !files.length" class="fs-14 text-left font-weight-regular color__primary_secondary m__top--4 p__right--64 p__left--64">
            ・ドラッグ&ドロップでもアップロードすることができます。<br>
            ・複数のファイルをアップロードすることができます。
        </div>
        <div v-else class="fs-14 text-left font-weight-medium color__primary_black m__top--4">
            アップロードされた資本政策は自動的に本サービスに反映されます。
            反映できましたらメールにお知らせいたします。（目安時間：30分）
            大変お手数ですが今しばらくお待ち下さい。サイトを閉じいただいて
            も反映されますのでご安心ください。
        </div>
    </div>
    <div class="progress-linear-upload" v-if="files">
        <div class="d-flex align-center justify-space-between m__bottom--8" v-for="file, index in files" :key="'file-'+ index">
            <div class="progress-line" :style="progressLineStyle">
                <div class="left" :style="progressLineStyle">
                    <img :src="iconExcelCircle" alt="">
                    <span class="m__left--4 fs-12 font-weight-medium">{{ fileNames[index] }}</span>
                </div>
                <img v-if="files" :src="iconUploadComplete" alt="">
            </div>
            <img class="icon-cross active" :src="iconCross" alt="" @click="removeFile(index)">
        </div>
    </div>
    <div class="progress-linear-upload" v-if="!files && fileNames">
        <div class="d-flex align-center justify-space-between m__bottom--8" v-for="name, index in fileNames" :key="'file-name-'+ index">
            <div class="progress-line">
                <div class="left">
                    <img :src="iconExcelCircle" alt="">
                    <span class="m__left--4 fs-12 font-weight-medium">{{ name }}</span>
                </div>
            </div>
            <img class="icon-cross" :src="iconCross" alt="">
        </div>
    </div>
    <slot name="text-link"></slot>
</div>
</template>
<script>
const jQuery = require('jquery');
export default {
    watch: {
        isUploading: function(currentValue) {
            this.$emit('updateIsUploading', currentValue)
        }
    },
    data() {
        return {
            iconCloudUpload : require('@/assets/images/icons/ic_cloud_upload.svg'),
            iconExcelUploadComplete : require('@/assets/images/icons/ic_excel_upload_complete.svg'),
            iconExcel : require('@/assets/images/icons/ic_excel.svg'),
            iconUploadComplete : require('@/assets/images/icons/ic_upload_complete.svg'),
            iconCross : require('@/assets/images/icons/ic_cross.svg'),
            iconExcelCircle : require('@/assets/images/icons/ic_excel_circle.svg'),
            isUploading : false,
            fileNames : [],
            files : null,
            progressLineStyle : {
                background : "#AECFFF"
            }

        }
    },
    methods: {
        triggerUpload() {
            document.getElementById('file-upload').click();
        },
        uploadFile(event) {
            let vm = this;
            vm.isUploading = true;
            let files = event.target.files || event.dataTransfer.files;
            if (!files.length) {
                return;
            }
            vm.fileNames = Array.from(files).map(item => item.name);
            
            vm.files = Array.from(files);
            vm.isUploading = false;
            vm.$emit('uploadFile', vm.files);
        }, 
        removeFile(index){
            this.fileNames.splice(index, 1);
            this.files.splice(index, 1);
            this.$emit('uploadFile', this.files)
        },
        // drag and drop file upload
        handleDropFile() {
        let vm = this;
        jQuery("#dropbox-file-upload").on("dragover", function (e) {
            e.preventDefault();
            e.stopPropagation();
        });
        jQuery("#dropbox-file-upload").on("dragenter", function (e) {
            e.preventDefault();
            e.stopPropagation();
        });
        jQuery("#dropbox-file-upload").on("drop", function (e) {
            if (e.originalEvent.dataTransfer) {
            if (e.originalEvent.dataTransfer.files.length) {
                e.preventDefault();
                e.stopPropagation();
                let files = [];
                const typeArr = [
                    ".csv",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "application/vnd.ms-excel"
                ];
                Array.from(e.originalEvent.dataTransfer.files).forEach(file => {
                    if (typeArr.includes(file.type)) {
                        files.push(file)
                    }
                })
                if (files.length !== 0) {
                    vm.isUploading = true;
                    vm.fileNames = Array.from(files).map(item => item.name);
                    vm.files = Array.from(files);
                    vm.isUploading = false;
                    vm.$emit('uploadFile', vm.files);
                }
                else {
                    vm.$toast.error("Wrong files format");
                }
            }
            }
        });
        }
    },
    mounted(){
        this.handleDropFile();
    }
}
</script>
<style scope lang="scss">
.group-icon-upload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction:column;
    .icon-cloud-upload {
        cursor: pointer;
    }
}

#file-upload {
    display: none;
}

.progress-linear-upload {
    width: 480px;
    margin: 0 auto;
    margin-top: 24px;
    .progress-line {
        width: 454px;
        height: 100%;
        border-radius: 45px;
        background: #F3F4F3;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding : 0 2px;
        .left {
            width: 218px; ;
            border-top-left-radius: 45px;
            border-end-start-radius: 45px;
            background: linear-gradient(269.95deg, #EAF2FF 14.27%, #AECFFF 45.22%, #EAF2FF 80.22%);
            display: flex;
            align-items: center;
        }
    }
    .icon-cross {
        &.active {
            cursor: pointer;
        }
    }
}
</style>