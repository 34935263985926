<template>
    <v-dialog v-model="show" width="450px">
        <v-card class="w-100 text-center round-modal">
            <img :src="icClose" alt="" @click="show = false">
            <div class="font-weight-bold fs-18 mb-4">
                ラウンドの編集
            </div>
            <div class="fs-19 m__bottom--76">
                編集するラウンドを選択してください。
            </div>
            <div class="round-container mx-auto">
                <router-link 
                    :to="{ name: 'Edit Capital Policy', params: { 'round': round.id } }"
                    v-for="(round, index) in rounds"
                    :key="round.id"
                    class="d-flex justify-center align-center w-100"
                    :class="{'mb-6': index !== rounds.length}">
                        {{ round.round_name }}
                </router-link>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "RoundPickerModal",
    props: {
        value: Boolean,
        rounds: Array
    },
    data() {
        return {
            icClose: require('@/assets/images/icons/ic_close.svg'),
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.round-modal {
    position: relative;
    padding: 64px 0;
    & > img {
        position: absolute;
        top: 8px;
        right: 12px;
        width: 22px;
        cursor: pointer;
    }
}
.round-container {
    width: 250px;
    padding: 0 5px;
    max-height: 320px;
    overflow-y: scroll;
    & > a {
        border: 1px solid #339967;
        border-radius: 4px;
        font-weight: 700;
        height: 52px;
        cursor: pointer;
        color: #333333;
        text-decoration: none;
    }
}
.round-container::-webkit-scrollbar {
  width: 6px;
  padding: 0 2px;
}

.round-container::-webkit-scrollbar-thumb {
  background: #339967;
  border-radius: 9999px;
  border: 1px solid #E2E2E2;
  background-clip: padding-box;
}

.round-container::-webkit-scrollbar-track {
  background: #E2E2E2;
  border-radius: 4px;
  width: 2px;
}
</style>