<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :dataset-id-key="datasetIdKey"
    :width="width"
    :height="height"
  />
</template>

<script>
import {Bar} from 'vue-chartjs/legacy'
import {LineController} from 'chart.js';
import {lineStackedBarChartOption, formatColor} from "@/commons/chart";
import {xAxisLabelYear} from "@/commons/plan_const"

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, BarElement, CategoryScale, LinearScale, PointElement, LineController)
export default {
  name: 'LineStackedBarChartComponent',
  components: {Bar},
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    dataColumn: {
      type: Array,
      default: () => {
        return [];
      }
      // example
      //        dataColumn: [
      //     {
      //        type: "bar",
      //        label: "bar1",
      //        data: [1,2,3,4],
      //        background : [#color1, #color2, #color3, #color4]
      //     },
      //     {
      //        type: "bar",
      //        label: "bar2",
      //        data: [2,3,4,5],
      //     },{
      //        type: "bar",
      //        label: "bar3",
      //        data: [3,4,5,6],
      //     },
      //     {
      //        type: "bar",
      //        label: "bar4",
      //        data: [4,5,6,7],
      //     }
      //  ],
    },
    labels: {
      type: Array,
      // example
      // [A1, A2, A3, A4]
    },
    dataLine: {
      type: Array,
      // example
      // [1, 2, 3, 4]
    },
    labelLine: {
      type: String,
      // example
      // ACB
    },
    data: {
      type: Array
    }

  },
  data() {
    let chartOptions = lineStackedBarChartOption;
    return {
      chartData: {},
      chartOptions
    }
  },
  methods: {
    inData() {
      let arrDatasets = [{
        type: "line",
        label: this.labelLine,
        data: this.dataLine,
        fill: true,
        borderColor: '#339967',
        pointBorderColor: "#fff",
        pointBackgroundColor: "#339967",
        pointStyle: 'circle',
        pointRadius: 5,
        borderWidth: 2,
        yAxisID: 'lineAmountRaised',
      }];
      let opacity = 1 / this.dataColumn.length
      for (let i = 0; i < this.dataColumn.length; i++) {
        let color = `rgba(81, 212, 151, ${opacity})`
        color = formatColor(color)
        arrDatasets.push({
          type: "bar",
          label: this.dataColumn[i].label,
          data: this.dataColumn[i].data,
          backgroundColor: color,
          borderRadius: 5,
          dataColumn: this.data,
          barThickness: 32
        })
        opacity += 1 / this.dataColumn.length
      }
      let listLabel = this.labels.length ? this.labels : xAxisLabelYear;
      const defaultLengthLabels = xAxisLabelYear.length;
      if (listLabel.length < defaultLengthLabels) {
        const leftLength = defaultLengthLabels - this.labels.length;
        for (let i = 0; i < leftLength; i++) {
          listLabel.push("");
        }
      }
      this.chartData = {
        labels: listLabel,
        datasets: arrDatasets,
      }
    }
  },
  created() {
    this.inData()
  }
}
</script>
<style scoped>
::-webkit-scrollbar {
  width: 2px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: yellowgreen;
  outline: 1px solid red;
}
</style>
