<template>
   <div class="content home-content">
      <LoadingComponent v-if="isLoading"/>
      <div v-else class="main-content">
         <div class="main-content-header">
            <span class="content-title">ホーム</span>
            <div class="btn-fundraising" v-if="!isFinancingCompleted">
               <v-btn @click="() => { $refs['modal-financing'].$refs['popup-financing-edit'].open() }">
                  資金調達中
               </v-btn>
            </div>
         </div>
         <div class="row1 corporate-value">
           <div class="m__bottom--16">
             <div class="sub-title-component--right">
               <button-component
                 class="m__right--8"
                 :text="'顕在株のみ'"
                 :width="'130px'"
                 :height="'37px'"
                 :mode="modeButtonChangeChartStrainsOnly"
                 :key="keyChangeChart"
                 :func="() => { changeChart = false }"
               ></button-component>
               <button-component
                 :text="'潜在株込み'"
                 :width="'130px'"
                 :height="'37px'"
                 :mode="modeButtonChangeChartIncludeShares"
                 :key="keyChangeChart"
                 :func="() => { changeChart = true }"
               ></button-component>
             </div>
           </div>
           <div class="sub-title-component m__bottom--16">
             <div class="sub-title-component--left">
               <span>調達額総額</span>
             </div>
             <div class="sub-title-component--right">
               <span>企業価値</span>
             </div>
           </div>
           <LineStackedBarChartComponent
             :key="componentKey"
             :dataColumn="corporateValueColumn"
             :dataLine="corporateValueLine"
             :labels="corporateValueLabel"
             :data="corporateValues"
           />
         </div>
         <div class="row2" style="position: relative">
            <div class="doughnut-chart">
               <DoughnutChartComponent
                  v-if="ratioShareholderData.length"
                  :labels="ratioShareholderLabel"
                  :data="ratioShareholderData"
                  :backgroundColors="doughnutColors"
               />
               <img v-else :src="iconBlankCircle" alt="">
            </div>
            <div class="shareholders-summary-table">
               <v-data-table
                  :headers="headers"
                  :items="shareholdersData"
                  disable-sort
                  hide-default-footer
                  hide-default-header
                  :fixed-header="true"
                  :height="'100%'"
                  :no-data-text="'データなし'"
                  :items-per-page="-1"
               >
                  <template v-slot:header="{ props: { headers } }">
                     <thead>
                        <tr>
                           <th v-for="item in headers" :key="item.value" class="text-center custom-header px-0" :style="{ 'width': item.width }">
                                 <div>{{ item.text }}</div>
                           </th>
                        </tr>
                     </thead>
                  </template>
                  <template v-slot:[`item.name`]="{ item }">
                     <td class="td-ellipsis" style="max-width: 100px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap">
                        <v-tooltip bottom>
                           <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="text-left fs-12 font-weight-bold ellipsis" style="max-width: 100px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap">{{ item.name }}</div>
                           </template>
                           <span>{{ item.name }}</span>
                        </v-tooltip>
                     </td>
                  </template>
                  <template v-slot:[`item.round`]="{ item }">
                     <td class="td-ellipsis" style="max-width: 100px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap">
                        <v-tooltip bottom>
                           <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="text-left fs-12 font-weight-bold ellipsis" style="max-width: 100px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap">{{ item.round }}</div>
                           </template>
                           <span>{{ item.round }}</span>
                        </v-tooltip>
                     </td>
                  </template>
                  <template v-slot:[`item.amount`]="{ item }">
                     <td class="td-ellipsis">
                        <div class="text-right fs-12 font-weight-bold ellipsis">¥{{ item.amount }}</div>
                     </td>
                  </template>
                  <template v-slot:[`item.stock_price`]="{ item }">
                     <td class="td-ellipsis">
                        <div class="text-right fs-12 font-weight-bold ellipsis">¥{{ item.stock_price }}</div>
                     </td>
                  </template>
                  <template v-slot:[`item.kinds`]="{ item }">
                     <td class="td-ellipsis">
                        <div class="text-center fs-12 font-weight-bold ellipsis">{{ item.kinds }}</div>
                     </td>
                  </template>
                 <template v-slot:[`item.ratio_normal`]="{ item }">
                   <td class="td-ellipsis">
                     <div class="text-right fs-12 font-weight-bold ellipsis">{{ item.ratio_normal }}%</div>
                   </td>
                 </template>
                  <template v-slot:[`item.ratio`]="{ item }">
                     <td class="td-ellipsis">
                        <div class="text-right fs-12 font-weight-bold ellipsis">{{ item.ratio }}%</div>
                     </td>
                  </template>
               </v-data-table>
            </div>
         </div>
         <div class="row3">
            <div class="stacked-bar-chart">
               <form-header
                  class="m__bottom--16"
                  :title="'収支'"
                  :modeButton="'btn-secondary'"
                  :textButton="'売上利益率'"
                  :widthButton="'106.49px'"
                  :heightButton="'37px'"
                  :actionButton="() => {}"
                  :isButton="false"
                  :charLabels="chartLabels"
               ></form-header>

               <LineBarChartComponent
                  class="line-stacked-bar-char"
                  :dataBar1="dealChartData1"
                  :dataBar2="dealChartData2"
                  :dataLine="dealChartDataLine"
                  :labels="dealLabels"
               />
            </div>
            <div class="line-chart">
               <form-header
                  class="m__bottom--16"
                  :title="'残高推移'"
                  :modeButton="'btn-secondary'"
                  :textButton="'詳細を見る'"
                  :widthButton="'106.49px'"
                  :heightButton="'37px'"
                  :isButton="false"
               ></form-header>
               <LineChartComponent :labels="balanceLabels" :data="balanceChartData" />
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import ButtonComponent from "@/components/common/ButtonComponent.vue";
import DoughnutChartComponent from "@/components/charts/DoughnutChartComponent.vue";
import LineStackedBarChartComponent from "@/components/charts/LineStackedBarChartComponent.vue";
import FormHeader from "@/components/Home/FormHeader.vue";
import LineBarChartComponent from "@/components/charts/LineBarChartComponent.vue";
import LineChartComponent from "@/components/charts/LineChartComponent.vue";
import LoadingComponent from "@/components/common/Loading.vue";

import {numberWithCommas, timeOptions} from "./../../commons/helpers";
import {UserService} from '@/services';
import {lineStackedBarChartDataColumn, lineStackedBarChartData} from '@/commons/chart';
import {
  header_table_shareholders,
  httpResponseErrorStatusCode,
  lineBarChartLabels,
  homeStep
} from '@/commons/plan_const';
import moment from 'moment';

export default {
  name: "StartupInfo",
  components: {
    ButtonComponent,
    DoughnutChartComponent,
    LineStackedBarChartComponent,
    FormHeader,
    LineBarChartComponent,
    LineChartComponent,
    LoadingComponent,
  },
  data() {
    return {
      iconArrowRight: require("@/assets/images/icons/ic_arrow_right_black.svg"),
      iconModalClose: require("@/assets/images/icons/ic_modal_close.svg"),
      iconBlankCircle: require("@/assets/images/icons/ic_blank_ellipse.svg"),
      step: null,
      headers: header_table_shareholders,
      timeOptions: timeOptions,
      lastFinancingInformation: {},
      isFinancingCompleted: true,
      corporateValues: [],
      corporateValueLine: [],
      corporateValueColumn: [],
      corporateValueLabel: [],
      ratioShareholderLabel: [],
      ratioShareholderData: [],
      doughnutColors: ['#66b28d', '#7cccd2', '#b49cf8', '#e7e7e7'],
      shareholdersData: [],
      isLoading: true,
      balanceLabels: [],
      balanceChartData: [],
      chartLabels: lineBarChartLabels,
      dealChartData1: [],
      dealChartData2: [],
      dealChartDataLine: [],
      dealLabels: [],
      homeStep: homeStep,
      specificShareholder: {},
      buttonLoading: false,
      changeChart: false,
      componentKey: 0,
      modeButtonChangeChartStrainsOnly: 'btn-primary',
      modeButtonChangeChartIncludeShares: 'btn-tertiary',
      keyChangeChart: 0,
    }
  },
  watch: {
    changeChart() {
      this.changeChartFunc();
      if (this.changeChart) {
        this.modeButtonChangeChartStrainsOnly = 'btn-tertiary';
        this.modeButtonChangeChartIncludeShares = 'btn-primary';
      } else {
        this.modeButtonChangeChartStrainsOnly = 'btn-primary';
        this.modeButtonChangeChartIncludeShares = 'btn-tertiary';
      }
      this.keyChangeChart++;
    }
  },
  methods: {
    goHomeFinancingPage() {
      const authUser = JSON.parse(localStorage.getItem('userInfo'));
      if (this.step == this.homeStep.stepDefault) {
        this.step = this.homeStep.stepInviteInvestor;
      } else if (this.step == this.homeStep.stepInviteInvestor) {
        this.step = this.homeStep.stepConnectFreee;
      }
      authUser.flg_step = this.step;
      localStorage.setItem('userInfo', JSON.stringify(authUser));
      this.updateHomeStep();
    },
    completeFinancing() {
      this.getLastFinancingInformation(true);
    },
    createFinancingSuccess() {
      this.getLastFinancingInformation(true);
    },
    handleFormatYearMonth(data) {
      return data.slice(0, 4) + '-' + data.slice(4, data.length)
    },
    handlerInviteOrConnect() {
      if (this.step == homeStep.stepDefault) {
        this.$refs['modal-invite-shareholders'].$refs['popup-invite'].open();
      } else if (this.step == homeStep.stepInviteInvestor) {
        window.location.href = process.env.VUE_APP_FREEE;
      }
    },
    redirectToCapitalPolicy() {
      this.$router.push({name: 'Capital Policies'})
    },
    updateStep() {
      this.step = this.homeStep.stepInviteInvestor;
      const authUser = JSON.parse(localStorage.getItem('userInfo'));
      authUser.flg_step = this.step;
      localStorage.setItem('userInfo', JSON.stringify(authUser));
    },
    // api
    initData() {
      const startup_id = this.$route.params.startup_id
      Promise.all([
        UserService.getValueCorporateStartup(startup_id),
        UserService.getShareholdersStartup(startup_id),
        UserService.getRatioShareholderStartup(startup_id),
        UserService.getHomeListDealsStartup(startup_id),
        UserService.getHomeListBalancesStartup(startup_id),
      ]).then((data) => {
        const corporateData = data[0].data;
        const shareholderData = data[1].data;
        const ratioShareholderData = data[2].data;
        const dealsData = data[3].data;
        const balancesData = data[4].data;
        if (corporateData) {
          const corporateValues = corporateData.data;
          this.corporateValues = lineStackedBarChartData(corporateValues);
          this.corporateValueLine = corporateValues.map(item => item.value_corporate);
          this.corporateValueColumn = lineStackedBarChartDataColumn(corporateValues);
          this.corporateValueLabel = corporateValues.map(item => moment(item.execution_date).format('YYYY-MM'));
        }

        if (shareholderData) {
          this.shareholdersData = shareholderData?.data.filter(item => item.money_shares >= 0).map((item) => {
            const obj = {
              name: item.investor_name,
              round: item.round_name,
              amount: numberWithCommas(item.money_shares),
              stock_price: numberWithCommas(item.stock_price),
              kinds: item.method,
              ratio: item.ratio_stock,
              ratio_normal: item.ratio_stock_normal,
            }
            return obj;
          })
        }

        if (ratioShareholderData) {
          if (ratioShareholderData.data.length) {
            const sortedRatios = ratioShareholderData.data.sort((a, b) => b.ratio_stock - a.ratio_stock)
            let remainRatio = 0
            sortedRatios.forEach((item, index) => {
              if (index < 3) {
                this.ratioShareholderLabel.push(item.investor_name)
                this.ratioShareholderData.push(item.ratio_stock)
              } else {
                remainRatio += item.ratio_stock
              }
            })
            this.ratioShareholderLabel.push('その他')
            this.ratioShareholderData.push(remainRatio)
          }
        }

        if (dealsData) {
          this.dealChartData1 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          this.dealChartData2 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          this.dealChartDataLine = [null, null, null, null, null, null, null, null, null, null, null, null];
          this.dealLabels = dealsData.data.map(item => this.handleFormatYearMonth(item.year_month));
          dealsData.data.forEach(item => {
            let month = item.year_month.slice(4, item.year_month.length);
            this.dealChartData1.splice(month - 1, 1, item.sales)
            this.dealChartData2.splice(month - 1, 1, item.operating_income)
            this.dealChartDataLine.splice(month - 1, 1, item.operating_income_margin)
          })
        }

        if (balancesData) {
          this.balanceLabels = balancesData.data.map(item => this.handleFormatYearMonth(item.year_month))
          this.balanceChartData = [null, null, null, null, null, null, null, null, null, null, null, null];
          balancesData.data.forEach(item => {
            let month = item.year_month.slice(4, item.year_month.length);
            this.balanceChartData.splice(month - 1, 1, item.cash);
          })
        }

        this.isLoading = false
      })
        .catch((err) => {
          if (err.status == httpResponseErrorStatusCode['Internal Server Error']) {
            this.$toast.error(err.statusText)
          }
          this.isLoading = false
        })
    },
    getHomeListDeals() {
      UserService.getHomeListDeals()
        .then((res) => {
          console.log(res)
        })
        .catch(() => {
        });
    },
    getHomeListBalances() {
      UserService.getHomeListBalances()
        .then((res) => {
          console.log(res)
        })
        .catch(() => {
        });
    },
    updateHomeStep() {
      const data = {
        step: this.step
      }
      UserService.updateHomeStep(data)
        .then(() => {
        })
        .catch(() => {
        })
    },
    async changeChartFunc() {
      const startup_id = this.$route.params.startup_id
      const response = await UserService.getValueCorporateStartup(startup_id)
      const corporateData = response.data
      if (corporateData) {
        const corporateValues = corporateData.data.map(item => {
          item.value_corporate = this.changeChart ? item.value_all_corporate : item.value_corporate
          item.sum_stock = this.changeChart ? item.sum_all_stock : item.sum_stock
          item.sum_shares = this.changeChart ? item.sum_all_shares : item.sum_shares
          return item;
        });
        this.corporateValues = lineStackedBarChartData(corporateValues);
        this.corporateValueLine = corporateValues.map(item => item.value_corporate);
        this.corporateValueColumn = lineStackedBarChartDataColumn(corporateValues);
        this.corporateValueLabel = corporateValues.map(item => moment(item.execution_date).format('YYYY-MM'));
        this.componentKey += 1;
      }
    }
  },
  created() {
    const authUser = JSON.parse(localStorage.getItem('userInfo'));
    this.step = authUser.flg_step;
    this.initData();
  }

}
</script>
<style scoped lang="scss">
   @import "@/assets/scss/_home.scss";
   .v-data-table {
      height: calc(100% - 53px);
   }

   .sub-title-component {
     width: 100%;
     display: flex;
     align-items: flex-start ;
     justify-content: space-between;
     .sub-title-component--left {
       font-weight: 700;
       font-size: 12px;
       line-height: 17.38px;
       color: rgba(133, 133, 133, 1);
     }
     .sub-title-component--right {
       font-weight: 700;
       font-size: 12px;
       line-height: 17.38px;
       color: rgba(133, 133, 133, 1);
     }
   }

   .row5 {
     display: flex;
     align-items: center;
     p {
       margin-bottom: 0;
     }
   }
</style>
