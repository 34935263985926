import Layout from "@/components/layouts/LayoutApp";
import Login from "@/pages/Login/Login";
import StepOne from "@/pages/Register/StepOne";
import StepTwo from "@/pages/Register/StepTwo";
import StepThree from "@/pages/Register/StepThree";
import StepFour from "@/pages/Register/StepFour";
import StepFiveSixSeven from "@/pages/Register/StepFiveSixSeven";
import ManualInput from  "@/pages/Register/ManualInput";
import InvestorManualInput from "@/pages/Register/InvestorManualInput";
import Contact from "@/pages/ContactPage/Contact";
import CapitalPolicy from '@/pages/CapitalPolicy/CapitalPolicy';
import EditPolicy from '@/pages/CapitalPolicy/PolicyEdit';
import InvestmentList from '@/pages/InvestorPage/InvestmentList';
import StartupCapitalPolicy from '@/pages/InvestorPage/InvestorPolicy';
import NoticeList from '@/pages/Notice/NoticeList';
import NoticeDetail from '@/pages/Notice/NoticeDetail';
import SimulationScreen from '@/pages/SimulationPage/SimulationScreen';
import AccountSetting from '@/pages/AccountSetting/AccountSetting';
import ForgotPassword from '@/pages/ForgotPassword/ForgotPassword';
import ResetPassword from '@/pages/ForgotPassword/ResetPassword';
import UnloggedResetPassword from '@/pages/ForgotPassword/UnloggedResetPassword';
import LoggedResetPassword from '@/pages/ForgotPassword/LoggedResetPassword';
import PostPasswordReset from '@/pages/ForgotPassword/PostPasswordReset';
import PostEmailReset from '@/pages/AccountSetting/PostEmailReset';
import InvestorRegister from '@/pages/Register/StepFourInvestor';
import FacebookCallback from '@/pages/InvestorPage/FacebookCallback';
import DeleteAccount from '@/pages/DeleteAccount/DeleteAccount';
import Complete from '@/pages/DeleteAccount/Complete';
import NotFound from '@/pages/NotFound';
import FreeeBlank from '@/components/Home/FreeeBlank';
import store from '@/stores'
import { ROLE_STARTUP, ROLE_INVESTOR, ROLE_BOTH } from "@/commons/plan_const";
import StartupInfo from "@/pages/InvestorPage/StartupInfo.vue";

export const web_router = [{
        path:'/login',
        name: 'Login',
        component: Login,
        meta: {
            title: 'ログイン',
            isPublic: true,
            role: ROLE_BOTH,
        },
    },
    {
        path:'/register-step-1',
        name: 'register-step-1',
        component: StepOne,
        meta: {
          title: '登録',
          isPublic: true,
          role: ROLE_BOTH,
        },
    },
    {
        path:'/register-step-2',
        name: 'register-step-2',
        component: StepTwo,
        meta: {
          title: '登録',
          isPublic: true,
          role: ROLE_BOTH,
        },
    },
    {
        path:'/register-step-3',
        name: 'register-step-3',
        component: StepThree,
        meta: {
          title: '登録',
          isPublic: true,
          role: ROLE_BOTH,
        },
    },
    {
        path:'/register-step-4',
        name: 'register-step-4',
        component: StepFour,
        meta: {
          title: '登録',
          isPublic: true,
          role: ROLE_BOTH,
        },
    },
    {
        path:'/register-step-5-6-7',
        name: 'register-step-5-6-7',
        component: StepFiveSixSeven,
        meta: {
          title: '登録',
          isPublic: true,
          role: ROLE_BOTH,
        },
    },
    {
        path:'/manual-input',
        name: 'manual-input',
        component: ManualInput,
        meta: {
          title: '登録',
          isPublic: true,
          role: ROLE_STARTUP,
        },
    },
    {
        path:'/investor-manual-input',
        name: 'investor-manual-input',
        component: InvestorManualInput,
        meta: {
          title: '登録',
          isPublic: true,
          role: ROLE_INVESTOR,
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {
            title: 'パスワード再設定',
            isPublic: true,
            role: ROLE_BOTH
        },
    },
    {
        path: '/investor-register',
        name: 'Investor register',
        component: InvestorRegister,
        meta: {
            title: '登録',
            isPublic: true,
            role: ROLE_BOTH
        }
    },
    {
        path: '/reset-password',
        name: 'Reset password',
        component: ResetPassword,
        meta: {
            title: 'パスワード再設定',
            isPublic: true,
            role: ROLE_BOTH
        }
    },
    {
        path: '/facebook-callback',
        name: 'Facebook callback',
        component: FacebookCallback,
        meta: {
            title: 'Facebook連携画面',
            isPublic: true,
            role: ROLE_BOTH
        }
    },
    {
        path: '/change-password',
        name: 'Change Password',
        component: UnloggedResetPassword,
        meta: {
            title: 'パスワード変更',
            isPublic: true,
            role: ROLE_BOTH
        },
    },
    {
        path: '/',
        redirect: '/',
        name: 'Layout',
        component: Layout,
        children: [{
                path: '/',
                name: 'home',
                component: () => {
                    if (store.getters['authUser']) {
                      switch (store.getters['authUser'].role) {
                        case ROLE_STARTUP:
                          return import("@/pages/Home/List");
                        case ROLE_INVESTOR:
                          return import("@/pages/InvestorPage/HomeInvestor");
                      }
                    }
                },
                meta: {
                    title: 'ホーム',
                    isPublic: false,
                    role: ROLE_BOTH,
                },
            },
            {
                path: '/capital-policies',
                name: 'Capital Policies',
                component: CapitalPolicy,
                meta: {
                    title: '資本政策・ ラウンド追加',
                    isPublic: false,
                    role: ROLE_BOTH,
                }
            },
            {
                path: '/capital-policy',
                name: 'Capital Policy',
                component: EditPolicy,
                meta: {
                    title: 'ラウンドの追加・編集',
                    isPublic: false,
                    role: ROLE_STARTUP,
                }
            },
            {
                path: '/capital-policy/:round',
                name: 'Edit Capital Policy',
                component: EditPolicy,
                meta: {
                    title: '資本政策の 編集・削除',
                    isPublic: false,
                    role: ROLE_STARTUP,
                }
            },
            {
                path: '/investment-list',
                name: 'Investment destination list',
                component: InvestmentList,
                meta: {
                    title: '投資先一覧',
                    isPublic: false,
                    role: ROLE_INVESTOR,
                }
            },
            {
                path: '/startup-capital-policy/:id',
                name: 'Startup capital policy',
                component: StartupCapitalPolicy,
                meta: {
                    title: '資本政策',
                    isPublic: false,
                    role: ROLE_INVESTOR,
                }
            },
            {
                path: '/notice-list',
                name: 'Notice list',
                component: NoticeList,
                meta: {
                    title: 'お知らせ',
                    isPublic: false,
                    role: ROLE_BOTH,
                }
            },
            {
                path: '/notice-detail',
                name: 'Notice detail',
                component: NoticeDetail,
                meta: {
                    title: 'Notice detail',
                    isPublic: false,
                    role: ROLE_BOTH,
                }
            },
            {
                path:'/contact',
                name: 'contact',
                component: Contact,
                meta: {
                  title: 'お問い合わせ',
                  isPublic: false,
                  role: ROLE_BOTH,
                },
            },
            {
                path: '/simulation',
                name: 'Simulation',
                component: SimulationScreen,
                meta: {
                    title: 'シュミレーション',
                    isPublic: false,
                    role: ROLE_STARTUP,
                },
            },
            // {
            //     path: '/simulation-list',
            //     name: 'Simulation List',
            //     component: SimulationList,
            //     meta: {
            //         title: 'シュミレーション',
            //         isPublic: false,
            //         role: ROLE_STARTUP,
            //     }
            // },
            {
                path: '/account-setting',
                name: 'Account Setting',
                component: AccountSetting,
                meta: {
                    title: 'アカウント設定',
                    isPublic: false,
                    role: ROLE_BOTH,
                }
            },
            {
                path: '/delete-account',
                name: 'Delete Account',
                component: DeleteAccount,
                meta: {
                    title: '退会',
                    isPublic: false,
                    role: ROLE_BOTH,
                }
            },
            {
                path: '/delete-account-complete',
                name: 'Delete Account Complete',
                component: Complete,
                meta: {
                    title: '退会',
                    isPublic: false,
                    role: ROLE_BOTH,
                }
            },
            {
                path: '/change-logged-password',
                name: 'Logged reset password',
                component: LoggedResetPassword,
                meta: {
                    title: 'パスワード再設定',
                    role: ROLE_BOTH
                }
            },
            {
                path: 'post-reset',
                name: 'Post Reset',
                component: PostPasswordReset,
                meta: {
                    title: 'パスワード再設定',
                    role: ROLE_BOTH
                }
            },
            {
              path: '/startup/:startup_id',
              name: 'Startup Info',
              component: StartupInfo,
              meta: {
                title: '',
                isPublic: true,
                role: ROLE_INVESTOR
              }
            },
        ]
    },
    {
        path: '/verify/complete-verify-email',
        name: 'Email Changed',
        component: PostEmailReset,
        meta: {
            title: 'Email Changed',
            role: ROLE_INVESTOR
        }
    },
    {
        path: "*",
        name: 'not-found',
        component: NotFound,
        meta: {
            title: 'Not found',
            isPublic: true,
            role: ROLE_BOTH
        }
    },
    {
        path: "/freee-blank",
        name: 'Freee Blank',
        component: FreeeBlank,
        meta: {
            title: 'Freee Blank',
            isPublic: true,
            role: ROLE_BOTH
        }
    }
]
