<template>
  <div class="box-account-setting">
    <HeaderPageComponent text-btn="戻る" text-title="アカウント設定"/>
    <div class="box-content-setting">
      <div class="title-content-setting">
        アカウント設定
      </div>
      <v-tabs
        v-if="userRole == role_startup"
        v-model="tab"
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1" @click="$router.push({name: 'Account Setting', query: {tab: 1}})">
          プロフィールの設定
        </v-tab>

        <v-tab href="#tab-2" @click="$router.push({name: 'Account Setting', query: {tab: 2}})">
          パスワードの設定
        </v-tab>

        <v-tab href="#tab-3" @click="$router.push({name: 'Account Setting', query: {tab: 3}})">
          株主の管理
        </v-tab>
      </v-tabs>

      <v-tabs
        v-if="userRole == role_investor"
        v-model="tab"
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1" @click="$router.push({name: 'Account Setting', query: {tab: 1}})">
          プロフィールの設定
        </v-tab>

        <v-tab href="#tab-3" @click="$router.push({name: 'Account Setting', query: {tab: 3}})">
          スタートアップの管理
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="i in 3"
          :key="i"
          :value="'tab-' + i"
        >
          <v-card flat>
            <v-card-text v-if="userRole == role_startup">
              <ProfileSetting v-if="tab === 'tab-1'"/>
              <PasswordSetting v-if="tab === 'tab-2'"/>
              <ShareholdersManagement v-if="tab === 'tab-3'"/>
            </v-card-text>
            <v-card-text v-if="userRole == role_investor">
              <InvestorProfileSetting v-if="tab === 'tab-1'"/>
              <StartupsManagement v-if="tab === 'tab-3'"/>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>
import ProfileSetting from "@/pages/AccountSetting/ProfileSetting";
import InvestorProfileSetting from "@/pages/AccountSetting/InvestorProfileSetting";
import PasswordSetting from "@/pages/AccountSetting/PasswordSetting";
import ShareholdersManagement from "@/pages/AccountSetting/ShareholdersManagement";
import StartupsManagement from "@/pages/AccountSetting/StartupManagement";
import HeaderPageComponent from "@/components/common/HeaderPageComponent";
import { ROLE_INVESTOR, ROLE_STARTUP } from "@/commons/plan_const"

export default {
  name: "AccountSetting",
  components: {
    ProfileSetting,
    InvestorProfileSetting,
    PasswordSetting,
    ShareholdersManagement,
    StartupsManagement,
    HeaderPageComponent,
  },
  data() {
    return {
      arrowLeft: require('@/assets/images/icons/arrow_left.svg'),
      tab: null,
      role_startup: ROLE_STARTUP,
      role_investor: ROLE_INVESTOR
    }
  },
  computed: {
    userRole() {
      return this.$store.getters['authUser'].role;
    }
  },
  created() {
    this.tab = 'tab-' + this.$route.query.tab
  }
}
</script>
<style scoped lang="scss">
.box-account-setting {
  margin-top: 24px;
  .box-content-setting {
    background: #FFFFFF;
    box-shadow: 0 4px 8px rgba(173, 182, 177, 0.1);
    border-radius: 4px;
    padding: 24px 40px;

    .title-content-setting {
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
      letter-spacing: 0.005em;
      color: #333333;
      margin-bottom: 32px;
    }
    .v-tab {
      font-weight: 400;
      font-size: 16px;
      line-height: 200%;
      letter-spacing: 0.005em;
      color: #858585;
      padding: 0;
      margin-right: 32px;
    }

    .v-tab--active {
      font-weight: 700;
      font-size: 16px;
      line-height: 200%;
      letter-spacing: 0.005em;
      color: #333333;
      border-bottom: 3px solid #339967;
    }
    .v-tab:before, .v-tabs-slider {
      background: none;
    }
  }
}
</style>
