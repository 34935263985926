<template>
  <div class="box-progress">
    <div class="box-step-progress" :class="stepProgress == 1 ? 'progress-active' : null ">
      <div class="step-progress">
        Step1
      </div>
      <div class="text-progress">
        仮登録
      </div>
    </div>
    <img :src="icArrowLeft" alt="">
    <div class="box-step-progress" :class="stepProgress == 2 ? 'progress-active' : null ">
      <div class="step-progress">
        Step2
      </div>
      <div class="text-progress">
        会社情報
      </div>
    </div>
    <img :src="icArrowLeft" alt="">
    <div class="box-step-progress" :class="stepProgress == 3 ? 'progress-active' : null ">
      <div class="step-progress">
        Step3
      </div>
      <div class="text-progress">
        資本政策
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProgressComponent",
  props: {
    step: Number
  },
  data() {
    return {
      icArrowLeft: require('@/assets/images/icons/ic_arrow_left.svg'),
      stepProgress: this.step ?? 1,
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/index.scss";
.box-progress {
  display: flex;
  align-items: flex-start;
  img {
    margin: 20px 32px;
  }
  .box-step-progress {
    color: #C2C2C2;
    font-size: 14px;
    line-height: 28px;
    .step-progress {
      font-weight: 700;
      display: flex;
      align-items: center;
      height: 56px;
      width: 56px;
      justify-content: center;
      margin-bottom: 5px;
      background-color: $white;
      border-radius: 50%;
    }
  }
}

.progress-active {
  font-weight: 700;
  .step-progress {
    border: solid 3px $corporate_color_1;
    border-radius: 50%;
    color: $corporate_color_1 !important;
  }
  .text-progress {
    color: $primary_black !important;
  }
}
</style>