<template>
    <div>
        <div v-if="!isPreviewed" class="main-container edit-policy">
            <TitleComponent :title="title" :hasBack="true" />
            <div>
                <LoadingComponent v-if="loadScreen"/>
                <div v-else class="register-form">
                    <div class="title-form">基本情報</div>
                    <div class="divider"></div>
                    <div class="form-input m__top--16">
                        <label>ラウンド名 (必須)</label>
                        <div class="form-control">
                            <div class="form-control-input text-left">
                                <input :class="{ error : submitted && $v.form.round_name.$error }" class="form-control w-480-px" v-model.trim="form.round_name">
                            </div>
                        </div>
                        <template v-if="submitted && $v.form.round_name.$error">
                            <span class="error-message" v-if="!$v.form.round_name.required">
                                {{ $t('validation.manual_input.round_name.required') }}
                            </span>
                            <span class="error-message" v-else-if="!$v.form.round_name.maxLength">
                                {{ $t('validation.manual_input.round_name.maxLength') }}
                            </span>
                        </template>
                    </div>
                    <div class="form-input  m__top--24">
                        <label>実施日 (必須)</label>
                        <div class="form-control">
                            <div class="form-control-input">
                                <select-component
                                    :element="'year'"
                                    :datas="yearDropDown"
                                    :width="124"
                                    @select="form.execution_year = $event"
                                    :currentValue="form.execution_year"
                                    :class="{ error : submitted && (requiredDate || errorDate) }"></select-component>
                                <span class="suffix">年</span>
                            </div>
                            <div class="form-control-input">
                                <select-component
                                    :element="'month'"
                                    :datas="monthDropDown"
                                    :width="124"
                                    @select="form.execution_month = $event"
                                    :currentValue="form.execution_month"
                                    :class="{ error : submitted && (requiredDate || errorDate) }"></select-component>
                                <span class="suffix">月</span>
                            </div>
                        </div>
                        <template v-if="submitted">
                            <span class="error-message" v-if="requiredDate">
                                {{ $t('validation.manual_input.execution_date.required') }}
                            </span>
                            <span class="error-message" v-if="errorDate">
                                {{ $t('validation.manual_input.execution_date.invalid_date') }}
                            </span>
                        </template>
                    </div>

                    <div class="form-input  m__top--24">
                        <label>発行株価 (必須)</label>
                        <div class="form-control">
                            <div class="form-control-input">
                                <input v-model="form.stock_price" :class="{ error : submitted && $v.form.stock_price.$error }" class="form-control w-240-px" style="width : 240px" @keypress="forceNumber($event)">
                                <span class="suffix">円</span>
                            </div>
                        </div>
                        <template v-if="submitted && $v.form.stock_price.$error ">
                            <span class="error-message" v-if="!$v.form.stock_price.required">
                                {{ $t('validation.required', {field: '株価'}) }}
                            </span>
                            <span class="error-message" v-else-if="!$v.form.stock_price.integer">
                                {{ $t('validation.manual_input.stock_price.integer') }}
                            </span>
                            <span class="error-message" v-if="invalidStock">
                                {{ $t('validation.manual_input.stock_price.invalid') }}
                            </span>
                        </template>
                    </div>

                    <div class="title-form">新規株主ごとの割り当株数</div>
                    <div class="divider"></div>
                    <div v-for="stock, index in stocks" :key="'stock'+ index" class="d-flex hold-stock">
                        <div class="form-input">
                            <label v-if="!index">株主名</label>
                            <div class="form-control m__right--30">
                                <v-combobox
                                  :class="{ 'combo-error' : submitted && $v.stocks.$each[index].investor_name.$error }"
                                  class="form-control w-30"
                                  v-model.trim="stock.investor_name"
                                  append-icon=""
                                  :items="items"
                                  flat
                                  solo
                                  @update:search-input="getItems($event, index)"
                                  @blur="resetData()"
                                >
                                  <template v-slot:append-item>
                                    <v-card
                                      v-if="lastPage !== currentPage"
                                      v-intersect="visibilityChanged"
                                      class="text-xs-center"
                                    >
                                    </v-card>
                                  </template>
                                </v-combobox>
<!--                              <div class="form-control-input">-->
<!--                                <select-component-->
<!--                                  :currentValue="stock.financing_method_id"-->
<!--                                  :element="'financing-method' + index"-->
<!--                                  :datas="fiMethods"-->
<!--                                  :width="320"-->
<!--                                  @select="stock.financing_method_id = $event"-->
<!--                                ></select-component>-->
<!--                              </div>-->
                            </div>
                            <div class="error-message combo-message" v-if="$v.stocks.$each[index].investor_name.$error">
                                <span v-if="!$v.stocks.$each[index].investor_name.required">
                                    {{ $t('policy_toast.name_required') }}
                                </span>
                                <span v-else-if="!$v.stocks.$each[index].investor_name.maxLength">
                                    {{ $t('policy_toast.investor_maxLength') }}
                                </span>
                            </div>
                        </div>
                        <div class="form-input">
                            <label v-if="!index">割り当株数</label>
                            <div class="form-control">
                                <div class="form-control-input">
                                    <input :class="{ error : submitted && $v.stocks.$each[index].number_of_shares.$error }" class="form-control w-140-px" v-model="stock.number_of_shares" @keypress="forceNumber($event)">
                                    <span class="suffix">株</span>
                                </div>
                            </div>
                            <div class="error-message" v-if="$v.stocks.$each[index].number_of_shares.$error">
                                <span v-if="!$v.stocks.$each[index].number_of_shares.required">
                                    {{ $t('policy_toast.number_of_shares_required') }}
                                </span>
                                <span v-else-if="!$v.stocks.$each[index].number_of_shares.integer">
                                    {{ $t('validation.manual_input.stock_price.integer') }}
                                </span>
                                <span v-else-if="!$v.stocks.$each[index].number_of_shares.minValue">
                                    {{ $t('policy_toast.number_of_shares_required') }}
                                </span>
                            </div>
                        </div>
                        <div class="form-input">
                            <label v-if="!index">形式</label>
                            <div class="form-control">
                                <div class="form-control-input">
                                    <select-component
                                        :currentValue="stock.financing_method_id"
                                        :element="'financing-method' + index"
                                        :datas="fiMethods"
                                        :width="320"
                                        @select="stock.financing_method_id = $event"
                                    ></select-component>
                                </div>
                            </div>
                        </div>
                        <div class="form-input relative" v-if="includePreferred">
                            <label class="mr-6" v-if="!index">配当分配権倍率</label>
                            <div class="form-control" v-if="stock.financing_method_id == 2">
                                <div class="form-control-input">
                                    <input class="form-control w-72-px" :class="{ error : submitted && $v.stocks.$each[index].preferred_stock_magnification.$error }" v-model="stock.preferred_stock_magnification" @keypress="forceNumber($event)">
                                    <span class="suffix">倍</span>
                                </div>
                            </div>
                            <div class="error-message text-left" style="white-space: nowrap" v-if="$v.stocks.$each[index].preferred_stock_magnification.$error">
                                <span v-if="!$v.stocks.$each[index].preferred_stock_magnification.required">
                                    {{ $t('policy_toast.magnitude_required') }}
                                </span>
                                <span v-else-if="!$v.stocks.$each[index].preferred_stock_magnification.minValue">
                                    {{ $t('policy_toast.magnitude_min') }}
                                </span>
                            </div>
                        </div>
                        <div class="form-input relative" v-if="includePreferred">
                            <label class="mr-8" v-if="!index">優先株 参加 / 非参加</label>
                            <div class="form-control" v-if="stock.financing_method_id == 2">
                                <div class="form-control-input">
                                    <select-component
                                        :currentValue="stock.is_preferred_stock_participation"
                                        :element="'is_preferred_stock_participation_' + index"
                                        :datas="participationOptions"
                                        :width="124"
                                        @select="stock.is_preferred_stock_participation = $event"
                                    ></select-component>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-center ml-4" :class="{'pt-5': !index, 'pb-4': index, 'justify-end': includePreferred}" style="flex-grow: 1">
                            <img :src="iconCross" alt="" @click="deleteStock(index)" style="cursor: pointer">
                        </div>
                    </div>
                    <div class="icon-add-shareholder">
                        <img :src="iconPlus" alt="" @click="addStock">
                        <span>株主を追加</span>
                    </div>

                    <div v-if="!isFirstRound">
                        <div class="title-form">売却株主を登録</div>
                        <div class="divider"></div>
                        <div v-for="sellingShareholder, index in sellingShareholders" :key="'sellingShareholder'+ index" class="d-flex sold-stock">
                            <div class="form-input m__right--30">
                                <label v-if="!index">株主名</label>
                                <div class="form-control" v-if="!isFirstRound">
                                    <v-autocomplete
                                        :class="{ 'combo-error' : submitted && $v.sellingShareholders.$each[index].investor_name.$error }"
                                        class="form-control w-30"
                                        v-model.trim="sellingShareholder.investor_name"
                                        :items="currentShareholder"
                                        flat
                                        hide-no-data
                                        solo></v-autocomplete>
                                </div>
                                <div class="form-control" v-else>
                                    <div class="form-control-input">
                                        <input type="text" :class="{ error : submitted && $v.sellingShareholders.$each[index].investor_name.$error }" class="form-control w-284-px" v-model.trim="sellingShareholder.investor_name">
                                    </div>
                                </div>
                                <div class="error-message combo-message" v-if="$v.sellingShareholders.$each[index].investor_name.$error">
                                    <span v-if="!$v.sellingShareholders.$each[index].investor_name.required">
                                        {{ $t('policy_toast.name_required') }}
                                    </span>
                                </div>
                            </div>
                            <div class="form-input">
                                <label v-if="!index">割り当株数</label>
                                <div class="form-control">
                                    <div class="form-control-input">
                                        <input
                                            v-model="sellingShareholder.number_of_shares"
                                            :class="{ error : submitted && $v.sellingShareholders.$each[index].number_of_shares.$error }"
                                            class="form-control w-140-px"
                                            @input="sellingPrices[index] = (Number(form.stock_price) && Number(sellingShareholder.number_of_shares)) ? sellingShareholder.number_of_shares * form.stock_price : 0"
                                            @keypress="forceNumber($event)"
                                        >
                                        <span class="suffix">株</span>
                                    </div>
                                </div>
                                <div class="error-message" v-if="$v.sellingShareholders.$each[index].number_of_shares.$error">
                                    <span v-if="!$v.sellingShareholders.$each[index].number_of_shares.required">
                                        {{ $t('policy_toast.number_of_shares_required') }}
                                    </span>
                                    <span v-else-if="!$v.sellingShareholders.$each[index].number_of_shares.integer">
                                        {{ $t('validation.manual_input.stock_price.integer') }}
                                    </span>
                                    <span v-else-if="!$v.sellingShareholders.$each[index].number_of_shares.minValue">
                                        {{ $t('policy_toast.number_of_shares_required') }}
                                    </span>
                                </div>
                            </div>
                            <div class="form-input">
                                <label v-if="!index">株価</label>
                                <div class="form-control">
                                    <div class="form-control-input">
                                        <input
                                            disabled
                                            class="form-control w-140-px"
                                            v-model="sellingPrices[index]"
                                        >
                                        <span class="suffix">円</span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-center pt-6 ml-4">
                                <img :src="iconCross" alt="" @click="deleteSallingShareholder(index)" style="cursor: pointer">
                            </div>
                        </div>
                        <div class="icon-add-shareholder">
                            <img :src="iconPlus" alt="" @click="addSallingShareholder">
                            <span>株主を追加</span>
                        </div>
                    </div>

                    <div class="wrapper-button m__top--48 d-flex justify-center">
                        <div class="w-150-px mr-6 d-flex justify-center align-center">
                            <button-component
                                v-if="!isLoadingPreview"
                                class="m__right--24"
                                :text="textPreviewButton"
                                :small=false
                                :func="preview"
                                :width="widthButton"
                                :mode="'btn-secondary'"
                            ></button-component>
                            <div v-else class="donut"></div>
                        </div>
                        <div class="w-150-px mr-6 d-flex justify-center align-center">
                            <button-component
                              v-if="!isLoading"
                                :text="textCreateButton"
                                :small=false
                                :func="handleAddOrEdit"
                                :width="widthButton"
                            ></button-component>
                          <div class="donut" v-else></div>
                        </div>
                    </div>

                    <div v-if="$route.params.round" class="delete-round text-center font-weight-bold text-decoration-underline mt-12" style="cursor: pointer" @click="deleteModal = true">このラウンドを削除</div>
                </div>
            </div>

        </div>
    <DeleteRoundModal :isLoading="deleteLoading" v-model="deleteModal" :roundId="$route.params.round" :roundName="currentRoundName" @delete-round="deleteRound"/>
    <v-dialog v-model="modalDeleted" width="400px">
        <v-card class="py-16">
            <div class="font-weight-bold fs-18 text-center mb-8">
                {{curRound}}を削除いたしました。
            </div>
            <div class="justify-center d-flex">
                <button-component
                    class="mx-auto"
                    :text="'OK'"
                    :small="false"
                    :func="() => {modalDeleted = false; $router.push({name: 'Capital Policies'})}"
                    :width="'180px'"
                    :height="'48px'"
                    :mode="'btn-structure'"
                ></button-component>
            </div>
        </v-card>
    </v-dialog>
    <Preview v-if="isPreviewed" :data="previewData" :isManualInput="false" @closePreview="closePreview"/>
    </div>
</template>
<script>
import ButtonComponent from "@/components/common/ButtonComponent.vue";
import Select from "@/components/common/Select.vue";
import TitleComponent from "@/components/common/Title.vue";
import DeleteRoundModal from "@/components/modals/DeleteRoundModal.vue"
import Preview from "@/components/Home/Preview.vue"
import { UserService } from '@/services';
import { financing_methods } from '@/commons/plan_const'
import { required, minValue, maxValue, integer, maxLength, requiredIf } from "vuelidate/lib/validators"
import { selectMonths } from "@/commons/helpers";
import LoadingComponent from "@/components/common/Loading.vue";

export default {
    components : {
        ButtonComponent,
        "select-component" : Select,
        TitleComponent,
        DeleteRoundModal,
        Preview,
        LoadingComponent
    },
    data(){
        return {
            title: 'ラウンドの編集',
            logo : require('@/assets/images/svgs/logo_carte.svg'),
            iconPlus : require('@/assets/images/icons/ic_plus.svg'),
            iconCross: require('@/assets/images/svgs/Cross.svg'),
            textPreviewButton : "プレビュー",
            textCreateButton : "完了",
            deleteModal: false,
            modalDeleted: false,
            newRound: false,
            widthButton : "150px",
            isLoading: false,
            isLoadingPreview: false,
            deleteLoading: false,
            participationOptions: [
                {
                    value: false,
                    text: '非参加'
                },
                {
                    value: true,
                    text: '参加'
                }
            ],
            stocks : [],
            sellingShareholders : [],
            sellingPrices: [],
            listShareholder: [],
            fiMethods: [],
            form: {
                round_name: '',
                execution_year: new Date().getYear() + 1900,
                execution_month: new Date().getMonth() + 1,
                stock_price: '',
            },
            currentRoundName: '',
            financing_methods,
            loadScreen: false,
            currentShareholder: [],
            curRound: '',
            isFirstRound: false,
            submitted: false,
            previewData: {},
            isPreviewed: false,
            lastPage: 0,
            isLastPage: false,
            currentPage: 1,
            items: [],
            searchInvestor: '',
          confirmModal: false,
          stay: false
        }
    },
    validations() {
        return  {
            form: {
                execution_month: {
                    required,
                    minValue: minValue(1),
                    maxValue: maxValue(12),
                },
                execution_year: {
                    required,
                    minValue: minValue(1),
                    maxValue: maxValue(9999),
                },
                round_name: {
                    required,
                    maxLength: maxLength(100)
                },
                stock_price: {
                    required,
                    integer
                }
            },
            stocks: {
                $each: {
                    investor_name: {
                        required,
                        maxLength: maxLength(100)
                    },
                    number_of_shares: {
                        required,
                        integer,
                        minValue: minValue(1)
                    },
                    preferred_stock_magnification: {
                        required: requiredIf(stock => {
                            return stock.financing_method_id === 2;
                        }),
                        minValue: minValue(1)
                    },
                }
            },
            sellingShareholders: {
                $each: {
                    investor_name: {
                        required,
                    },
                    number_of_shares: {
                        required,
                        integer,
                        minValue: minValue(1)
                    },
                }
            }
        }
    },
    computed: {
        formattedDate() {
            return this.form.execution_year + '-' + (this.form.execution_month < 10 ? '0' + this.form.execution_month : this.form.execution_month) + '-01'
        },
        errorDate() {
            return this.$v.form.execution_year.$error || this.$v.form.execution_month.$error
        },
        requiredDate() {
            return this.$v.form.execution_year.required.$invalid || this.$v.form.execution_month.required.$invalid
        },
        includeCommon() {
            let count = 0
            this.stocks.forEach(item => {
                if (this.isCommonStock(item.financing_method_id)) {
                    count++
                }
            })
            return count;
        },
        includePreferred() {
            let count = 0
            this.stocks.forEach(item => {
                if (this.isPreferredStock(item.financing_method_id)) {
                    count++
                }
            })
            return count;
        },
        invalidStock() {
            return !!this.includeCommon && !!this.includePreferred
        },
        yearDropDown() {
            const listYears = [];
            const year = new Date().getFullYear();
            for( let i = -100; i < 99; i++ ) {
                listYears.push({
                    text: year + i,
                    value: year + i
                })
            }
            return listYears;
        },
        monthDropDown() {
            return selectMonths();
        }
    },
    created() {
        this.getAllData()
        this.newRound = this.$route.query.newRound
        this.title = !this.$route.params.round ? '新規ラウンドの追加' : 'ラウンドの編集'
    },
    methods: {
        visibilityChanged(e) {
            this.currentPage !== this.lastPage && this.lastPage !== 1 && e && this.loadMore();
        },
        async getItems(investor, index) {
          if (this.searchInvestor !== investor) {
            this.searchInvestor = investor
            this.stocks[index].investor_name = investor
            this.currentPage = 1
          }
          try {
            const { data } = await UserService.investorSuggest({page: this.currentPage, investor_name: investor});
            this.lastPage = parseInt(data.data.last_page)
            this.items = [
              ...data.data.investor_names,
            ]
          } catch (e) {
            //
          }
        },
        async loadMore() {
          try {
            const { data } = await UserService.investorSuggest({page: this.currentPage, investor_name: this.searchInvestor});
            if (data.data.investor_names && data.data.investor_names.length) {
              ++this.currentPage;
              this.lastPage = parseInt(data.data.last_page)
              this.items = [
                ...this.items,
                ...data.data.investor_names,
              ]
            }
          } catch(e) {
            //
          }
        },
        resetData() {
          this.currentPage = 1
          this.lastPage = 0
          this.searchInvestor = ''
        },
        //getters
        async getAllData() {
            this.loadScreen = true
            await Promise.all([this.getFinancingMethods(), this.getListShareholders(), this.getRound()])
            this.loadScreen = false
            if (this.$route.params.round) {
                this.getRoundData();
            }
        },
        async getRound() {
            try {
                const { data } = await UserService.getCapitalPolicies()
                if (!data.data.round_info.length || !data.data.round_info) {
                    this.isFirstRound = true
                }
            } catch (err) {
                this.isFirstRound = true
            }
        },
        async getListShareholders() {
            try {
                const { data } = await UserService.getShareholderStocks()
                data.data.forEach(shareholder => {
                    this.listShareholder.push({
                        name: shareholder.investor_name,
                        stock: shareholder.sum_stock
                    })
                    this.items.push(shareholder.investor_name)
                    this.currentShareholder.push(shareholder.investor_name)
                })
            } catch (err) {
                this.$toast.error(err.message)
            }
        },
        async getFinancingMethods() {
            try {
                const { data } = await UserService.getFinancingMethods()
                data.data.forEach(method => {
                    this.fiMethods.push({
                        value: method.id,
                        text: method.method
                    })
                })
            } catch (err) {
                this.$toast.error(err.message)
            }
        },
        async getRoundData() {
            try {
                this.loadScreen = true
                const { data } = await UserService.getCapitalPolicy(this.$route.params.round)
                const date = data.data.execution_date.split('-')
                this.title = data.data.round_name + 'の編集'
                this.currentRoundName = data.data.round_name
                this.form = {
                    execution_year: parseInt(date[0]),
                    execution_month: parseInt(date[1]),
                    round_name: data.data.round_name,
                    stock_price: data.data.stock_price
                }
                this.curRound = data.data.round_name
                data.data.share_stock.forEach(item => {
                    const absShare = Math.abs(item.number_of_shares)
                    if (item.financing_method_id === 2 && !item.preferred_stock_magnification) item.preferred_stock_magnification = 1
                    const stock = {
                        ...item,
                        is_preferred_stock_participation: item.is_preferred_stock_participation ? item.is_preferred_stock_participation : false,
                        number_of_shares: absShare,
                    }
                    if (item.number_of_shares < 0) {
                        this.sellingShareholders.push(stock)
                        this.sellingPrices.push(absShare * this.form.stock_price)
                    } else {
                        this.stocks.push(stock)
                    }
                })
                this.loadScreen = false
            } catch (err) {
                this.$toast.error(err.data.message)
                this.$router.push({name: 'Capital Policy'})
                this.loadScreen = false
            }
        },
        getInvestorTotalStock(name) {
            if (name) {
                return this.listShareholder.find(item => item.name == name).stock
            } else {
                return ''
            }
        },
        addStock(){
            const stock = {
                investor_name : '',
                number_of_shares : 0,
                financing_method_id : this.fiMethods[0].value,
                preferred_stock_magnification : 1,
                is_preferred_stock_participation : true
            }
            this.stocks.push(stock);
        },
        deleteStock(index) {
            if (index > -1) {
                this.stocks.splice(index, 1)
            }
        },
        addSallingShareholder(){
            const sallingShareholder = {
                investor_name : '',
                number_of_shares : 0,
                stockPrice : null,
            }
            this.sellingShareholders.push(sallingShareholder)
            this.sellingPrices.push(0)
        },
        deleteSallingShareholder(index) {
            if (index > -1) {
                this.sellingShareholders.splice(index, 1)
                this.sellingPrices.splice(index, 1)
            }
        },
        deleteRound(roundId) {
            if (roundId) {
                // delete round by roundId
                this.deleteLoading = true
                UserService.deleteCapitalPolicy(this.$route.params.round).then(() => {
                    this.deleteLoading = false
                    this.deleteModal = false
                    this.modalDeleted = true
                }).catch(err => {
                    this.deleteLoading = false
                    this.deleteModal = false
                    this.$toast.error(err.data.message)
                })
            }
        },
        addOrEditRound() {
                const data = this.validateInput()
                if (data) {
                    let roundId = this.$route.params.round
                    this.isLoading = true
                    UserService.createOrUpdateCapitalPolicy(data, roundId).then(() => {
                        const changeType = roundId ? 'edit' : 'new'
                        this.$router.push({name: 'Capital Policies', params: {'newRound': true, 'changeType': changeType}})
                    }).catch(err => {
                        this.isLoading = false
                        this.$toast.error(err.data.message)
                    })
                }
        },
        preview() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.oldData();
        },
        closePreview() {
            this.isPreviewed = false
        },
        handleAddOrEdit() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.addOrEditRound()
        },
        validateInput() {
            let soldShareholder = []
            let message = ''
            this.sellingShareholders.forEach(item => {
                if (item.number_of_shares > this.getInvestorTotalStock(item.investor_name)) {
                    message = item.investor_name + ' は ' + this.getInvestorTotalStock(item.investor_name) + '株しかもっていません。'
                    return
                }
                else {
                    soldShareholder.push({
                        investor_name: item.investor_name,
                        number_of_shares: -item.number_of_shares,
                        financing_method_id: 1,
                        preferred_stock_magnification: 1,
                        is_preferred_stock_participation: true
                    })
                }
            })
            if (message) {
                this.$toast.error(message);
                return
            }
            let holders = []
            this.stocks.forEach(item => {
                holders.push({
                    ...item,
                })
            })
            if (this.invalidStock) {
                this.$toast.error(this.$t('policy_toast.invalid_methods'));
                return;
            }
            return {
                round_name: this.form.round_name,
                execution_date: this.formattedDate,
                stock_price: parseInt(this.form.stock_price),
                share_stock: [
                    ...holders,
                    ...soldShareholder
                ]
            }
        },
        oldData() {
            this.previewData = false
            this.isLoadingPreview = true
            const res = this.validateInput()
            Promise.all([
                UserService.previewValueCorporate(res),
                UserService.previewShareholders(res),
                UserService.previewRatioShareholder(res)
            ]).then((data) => {
                this.previewData = data
                this.isPreviewed = true
                this.isLoadingPreview = false
            })
            .catch((err) => {
                this.isLoadingPreview = false
                this.toast.error(err.data.message)
            })
        },
        forceNumber($event) {
            let value = String.fromCharCode($event.which);
            let pattern = /[0-9]+/
            if (pattern.test(value)) {
                return true
            } else {
                $event.preventDefault();
            }
        },
        isCommonStock(id) {
            return this.fiMethods.find(item => item.value == id).text.includes(financing_methods.common_share)
        },
        isPreferredStock(id) {
            return this.fiMethods.find(item => item.value == id).text.includes(financing_methods.preferred_share)
        },
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/index.scss";
.main-container {
    text-align: center;
    background: $white;
    padding : 32px 0;
    background: #FBFBFB;
}
.register-form {
    background: white;
    padding: 16px 36.5px 64px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(173, 182, 177, 0.1);
}
.description-form {
    line-height: 32px;
}
.icon-add-shareholder{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 18px;
    span {
        display: block;
        margin-left: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 200%;
        color: $primary-color;
    }

    img {
        cursor: pointer;
        width: 16px;
    }
}
.title-form {
    font-weight: 700;
    font-size: 18px;
    line-height: 200%;
    letter-spacing: 0.005em;
    color: $primary-color;
    text-align: left;
    margin-bottom: 4px;
    margin-top: 48px;
}
.form-input {
    label {
        white-space: nowrap;
    }
}
.hold-stock .form-input input
.sold-stock .form-input input {
    text-align: right;
}
.relative {
    position: relative;
}
.combo-message {
    margin-top: -20px !important;
}
</style>
<style lang="scss">
.edit-policy {
    .v-input__slot {
        padding: 11px 12px !important;
        border: 1px solid #EAEDEB !important;
    }
    .combo-error {
        .v-input__slot {
            border: 1px solid #EB5757 !important;
            background-color: #fef4f4!important;
        }
    }
}
</style>
