<template>
    <div>
        <layout-app :isLogin="false"></layout-app>
        <div class="app-container">
            <div class="content register-content">
                <div class="logo">
                    <img :src="logo" alt="">
                </div>
                <div class="title-form fs-18 font-weight-bold m__top--48 color__primary_black">資本政策を作成しましょう！</div>
<!--                <div class="description-form fs-15 font-weight-medium m__top&#45;&#45;16 color__primary_black">-->
<!--                    資本政策のエクセルをアップロードすることにより自動で作成できます。<br>-->
<!--                    又はスプレッドシートに直接入力し作成することもできます。<br>-->
<!--                    ファイル名は登記簿に登録されている正式な会社名にしてください。-->
<!--                </div>-->

<!--                <upload-file @uploadFile="files = $event" @updateIsUploading="isUploading = $event">-->
<!--                    <div slot="text-link" class="fs-12 font-weight-bold" :class="[ isUploading || ( files && files.length ) ? 'm__top&#45;&#45;26' : 'm__top&#45;&#45;18']">-->
<!--                        <span-->
<!--                            class="text-link text-decoration-underline"-->
<!--                            :class="{ active : ( files && files.length ) || !isUploading }"-->
<!--                            @click="enterCapitalPolicyManually">資本政策を手入力する-->
<!--                        </span>-->
<!--                        <div class="mb-2"></div>-->
<!--                        <span class="text-link active text-decoration-underline">-->
<!--                            <a style="color: #333" href="/download/資本政策のテンプレート.xlsx" download>テンプレートをダウンロードする</a>-->
<!--                        </span>-->
<!--                    </div>-->
<!--                </upload-file>-->

                <div class="wrapper-but m__top--48 d-flex justify-center">
                    <button-component
                        class="m__right--24"
                        :text="textReturnButton"
                        :small=false
                        :func="() => { $router.go(-1) }"
                        :width="widthButton"
                        :mode="'btn-tertiary'"
                    ></button-component>
                    <div>
                        <div v-if="isLoading" class="donut"></div>
                        <button-component
                            v-else
                            :text="textStartButton"
                            :small=false
                            :func="() => { $router.push({ name: 'manual-input'}) }"
                            :width="widthButton"
                        ></button-component>
                    </div>
                </div>
                <div class="fs-12 font-weight-bold m__top--24 color__primary_black text-decoration-underline">
                    <span class="link-skip" @click="$router.push({ name: 'home', query : {is_hint : 1}})">スキップ</span>
                </div>
            </div>
            <div class="progress-bar d-flex justify-center m__top--56">
                <progress-component :step="step"></progress-component>
            </div>
        </div>
    </div>
</template>
<script>
import ButtonComponent from "@/components/common/ButtonComponent.vue";
import ProgressComponent from "@/components/common/ProgressComponent.vue";
// import UploadFile from "@/components/common/UploadForm.vue";
import LayoutApp from "@/components/layouts/LayoutApp.vue";
// import { UserService } from '@/services';
import { ROLE_INVESTOR, ROLE_STARTUP } from "@/commons/plan_const"

export default {
    components : {
        ButtonComponent,
        ProgressComponent,
        // UploadFile,
        LayoutApp
    },
    data(){
        return {
            logo : require('@/assets/images/svgs/logo_carte.svg'),
            textReturnButton : "戻る",
            textStartButton : "資本政策を作成",
            widthButton : "160px",
            step : 3,
            progressLineWidth : 454,
            files : null,
            isUploading : null,
            isLoading: false,
        }
    },
    methods: {
        nextPage() {
            // if(this.files && this.files.length) {
            //     this.isLoading = true
            //     const data = new FormData();
            //     this.files.forEach(item => data.append('data[]', item))
            //     UserService.importCapitalPolicy(data).then(() => {
            //         this.$toast.success('Policy uploaded')
            //         this.$router.push({ name: "home", query: {is_hint: 1} });
            //     }).catch(err => {
            //         this.isLoading = false
            //         if (err.status !== 422) {
            //             this.$toast.error(err.data.message)
            //         }
            //     })
            // }
        },
        uploadFile(files) {
            this.files = files;
        },
        enterCapitalPolicyManually() {
            if(!this.isUploading) {
                if (this.$store.getters['authUser'].role == ROLE_STARTUP) this.$router.push({ name: 'manual-input'});
                if (this.$store.getters['authUser'].role == ROLE_INVESTOR) this.$router.push({ name: 'investor-manual-input'});
            }
        }
    },
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/index.scss";
.description-form {
    line-height: 32px;
}
.text-link {
    color : $tertiary;
    cursor: default;
    &.active {
        color: $primary_black;
        cursor: pointer;
    }
}
.link-skip {
    cursor: pointer;
}


</style>
