<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :max-width=width
      :max-height=height
      :persistent="persistent"
    >
      <v-card :class="classDialog">
        <v-card-title class="d-flex justify-end title-cart" v-if="btnClose">
          <img class="icon-close" :src=icClose alt="" @click="dialog = false">
        </v-card-title>
        
        <v-card-text class="body-popup">
          <slot name="body-popup"></slot>
        </v-card-text>
        <div class="d-flex justify-center footer-popup">
          <slot name="footer-popup"></slot>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "PopupConfirm",
  props: {
    widthDialog: Number,
    heightDialog: Number,
    isBtnClose: Boolean,
    persistent: Boolean,
    classDialog: String,
    isOpen: Boolean,
  },
  watch: {
    widthDialog: function(currentValue) {
      this.$data.width = currentValue;
    },
    dialog() {
      if (!this.dialog) {
        this.$emit('onClose');
      }
    }
  },
  data() {
    return {
      dialog: this.isOpen ? true : false,
      icClose: require('@/assets/images/icons/ic_close.svg'),
      width: this.widthDialog ? this.widthDialog : "600",
      height: this.heightDialog ? this.heightDialog : "600",
      btnClose: !this.isBtnClose ? this.isBtnClose : true
    }
  },
  methods: {
    open () {
      this.dialog = true;
    },
    close () {
      this.dialog = false;
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/index.scss";
.title-cart {
  padding: 12px !important;
}
.footer-popup {
 padding: 24px 0 64px;
}
.body-popup {
  color: $primary_black !important;
  text-align: center;
  padding: 0 !important;
}
.icon-close {
  cursor: pointer;
}
</style>