<template>
  <div class="form-password-setting">
    <div class="d-flex">
      <div class="w-50">
        <div class="form-input">
          <label>現在のパスワード</label>
          <div class="form-control">
            <div class="form-control-input">
              <input v-model="v$.form.current_password.$model" @input="v$.form.current_password.$reset" type="password" class="form-control w-480-px">
            </div>
          </div>
          <div v-if="v$.form.current_password.$errors">
                <div v-if="v$.form.current_password.required.$invalid" class="error-text">現在のパスワードをご入力ください。</div>
              </div>
        </div>
        <div class="form-input m__top--40">
          <label>新しいパスワード</label>
          <div class="form-control">
            <div class="form-control-input">
              <input v-model="v$.form.password.$model" @input="checkPassword" type="password" class="form-control w-480-px">
            </div>
          </div>
          <div class="validate-password mt-2">
            <div>
              8文字以上の英数字をご使用ください。
            </div>
            <div class="d-flex mt-2">
              <img v-if="!isChecking" :src="iconError" alt="iconCheck" class="mr-1">
              <img v-else :src="v$.form.password.minLength.$invalid || v$.form.password.required.$invalid ? iconError : iconCheck" alt="iconCheck" class="mr-1">
              <div>
                8文字以上
              </div>
            </div>
            <div class="d-flex mt-2">
              <img v-if="!isChecking" :src="iconError" alt="iconCheck" class="mr-1">
              <img v-else :src="v$.form.password.alphaNum.$invalid || v$.form.password.required.$invalid ? iconError : iconCheck" alt="iconError" class="mr-1">
              <div>
                英数字をご使用ください。
              </div>
            </div>
          </div>
          <div class="box-forgot-pass-email">
            <div>
              現在のパスワードが思い出せない場合はメールで再設定してください。
            </div>
            <div class="send-mail-pass" @click="sendPasswordEmail">
              メールでパスワードを再設定
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-action-password">
      <ButtonComponent text="キャンセル" mode="btn-tertiary m__right--24" :func="clearChange"/>
      <div class="w-113-px">
        <ButtonComponent v-if="!$store.getters.isLoading" text="変更を保存" mode="btn-primary" :func="updatePassword"/>
        <div v-else class="donut"></div>
      </div>
    </div>
    <ForgotPasswordEmailSent v-model="emailSent" />
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import {required, minLength, alphaNum, maxLength} from "@vuelidate/validators"
import ButtonComponent from "@/components/common/ButtonComponent";
import ForgotPasswordEmailSent from "@/components/modals/ForgotPasswordEmailSent.vue";
import { UserService } from '@/services';
export default {
  name: "PasswordSetting",
  components: {
    ButtonComponent,
    ForgotPasswordEmailSent,
  },
  data() {
    return {
      iconError: require("@/assets/images/icons/error.svg"),
      iconCheck: require("@/assets/images/icons/check.svg"),
      form: {
        current_password: '',
        password: ''
      },
      isChecking: false,
      emailSent: false
    }
  },
  setup: () => ({ v$: useVuelidate({$lazy: true}) }),
  validations() {
    return {
      form: {
        current_password: {
          required
        },
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(255),
          alphaNum
        }
      }
    }
  },
  methods: {
    updatePassword() {
      this.v$.form.$touch()
      if (!this.v$.form.$error) {
        UserService.changePassword(this.form).then(() => {
          this.$toast.success('Password updated')
        }).catch(e => {
          if (e.status !== 422) {
            this.$toast.error(e.data.message);
          }
        })
      }
    },
    checkPassword() {
      this.isChecking = true
      this.v$.form.password.$touch()
    },
    sendPasswordEmail() {
      this.emailSent = true
      UserService.mailResetPassword({email: this.$store.getters['authUser'].email})
    },
    clearChange() {
      this.form.current_password = ''
      this.form.password = ''
    }
  }
}
</script>
<style scoped lang="scss">
.form-password-setting {
  .form-control-input input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 200%;
    letter-spacing: 0.005em;
    color: #C2C2C2;
  }
  .box-action-password {
    display: flex;
    justify-content: center;
    margin: 48px 0 64px 0;
    button{
      width: 113px !important;
      height: 40px !important;
    }
  }
  .validate-password {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #333333;
  }
  .box-forgot-pass-email {
    font-weight: 400;
    font-size: 14px;
    line-height: 200%;
    letter-spacing: 0.005em;
    color: #333333;
    margin-top: 27px;
    .send-mail-pass {
      font-weight: 700;
      font-size: 14px;
      line-height: 200%;
      letter-spacing: 0.005em;
      text-decoration-line: underline;
      color: #333333;
      cursor: pointer;
    }
  }
}
</style>
