<template>
  <div class="form-profile-setting">
    <div class="d-flex">
    <div class="w-50">
      <div class="form-input">
        <label>組織名</label>
        <div class="form-control">
          <div class="form-control-input">
            <input v-model="v$.form.companyName.$model" @input="v$.form.companyName.$reset" class="form-control w-480-px" placeholder="本名をご記入ください">
          </div>
        </div>
        <div v-if="v$.form.companyName.$errors">
                <div v-if="v$.form.companyName.required.$invalid" class="error-text">組織名は必須です。</div>
                <div v-else-if="v$.form.companyName.maxLength.$invalid" class="error-text">組織名は100文字以内でご入力ください。</div>
              </div>
      </div>
      <div class="form-input m__top--40">
        <label>名前</label>
        <div class="form-control">
          <div class="form-control-input">
            <input v-model="v$.form.representativesName.$model" @input="v$.form.representativesName.$reset" class="form-control w-480-px" placeholder="本名をご記入ください">
          </div>
        </div>
        <div v-if="v$.form.representativesName.$errors">
                <div v-if="v$.form.representativesName.required.$invalid" class="error-text">担当者名は必須です。</div>
                <div v-else-if="v$.form.representativesName.maxLength.$invalid" class="error-text">名前は100文字以内でご入力ください。</div>
              </div>
      </div>
      <div class="form-input m__top--40">
        <label>メールアドレス</label>
        <div class="form-control">
          <div class="form-control-input">
            <input v-model="v$.form.email.$model" @input="v$.form.email.$reset" class="form-control w-480-px" placeholder="company@carte.jp">
          </div>
        </div>
        <div v-if="v$.form.email.$errors">
          <div v-if="v$.form.email.required.$invalid" class="error-text">メールアドレスは必須です。</div>
          <div v-else-if="v$.form.email.email.$invalid" class="error-text">有効なメールアドレスをご入力ください。</div>
          <div v-else-if="v$.form.email.maxLength.$invalid" class="error-text">メールアドレスは255文字以内でご入力ください。</div>
        </div>
      </div>
    </div>
    <!-- <div class="w-50 m__left--70 w-160-px">
      <div class="title-avatar">
        プロフィール写真
      </div>
      <div class="box-avtar">
        <img :src="avatar" alt="avatar">
      </div>
      <div class="box-action-avatar mt-2">
        <input type="file" name="avatar" id="avatar" hidden @change="onFileChange" accept="image/*">
        <label for="avatar">
          <div class="div-btn">
            写真を
          </div>
        </label>
        <div class="btn-remove-avatar" @click="removeFileChange">
          写真を削除
        </div>
      </div>
    </div> -->
    </div>
    <div class="box-action-profile">
      <ButtonComponent text="キャンセル" mode="btn-tertiary m__right--24" :func="cancel"/>
      <div class="w-113-px">
        <ButtonComponent v-if="!$store.getters.isLoading" text="変更を保存" mode="btn-primary" :func="handleSubmit"/>
        <div v-else class="donut"></div>
      </div>
    </div>
    <popup-confirm :classDialog="'confirm-email'" ref="confirm-email" :widthDialog="472" @closeDialog="$refs['confirm-email'].close()" :isBtnClose="true">
      <div slot="body-popup">
        <div class="text-center fw-500 fs-18 mb-4 mt-5">メールアドレスを本当に変更しますか？</div>
      </div>
      <div slot="footer-popup">
        <div class="d-flex">
          <ButtonComponent :width="'138px'" text="キャンセル" mode="btn-tertiary m__right--24" :func="() => {$refs['confirm-email'].close()}"/>
          <div>
              <ButtonComponent :width="'138px'" v-if="!$store.getters.isLoading" text="はい" mode="btn-primary" :func="updateUser"/>
              <div v-else class="donut"></div>
          </div>
        </div>
      </div>
    </popup-confirm>
    <popup-confirm :classDialog="'email-sent'" ref="email-sent" :width="472" @closeDialog="$refs['email-sent'].close()" :isBtnClose="true">
      <div slot="body-popup">
        <div class="text-center fs-18 font-weight-bold mb-5">メールアドレスの変更</div>
        <div class="fw-500">{{this.form.email}}に確認メールを送信しました。<br>メールに記載されたURLをクリックしてからメールアドレスの変更が完了されます。</div>
      </div>
      <div slot="footer-popup">
        <div class="d-flex">
          <ButtonComponent text="OK" mode="btn-primary" :func="() => {$refs['email-sent'].close()}"/>
        </div>
      </div>
    </popup-confirm>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import {required, maxLength, email} from "@vuelidate/validators"
import ButtonComponent from "@/components/common/ButtonComponent";
import { UserService } from '@/services';
import { ACTION_SAVE_AUTH_USER } from "@/stores/auth_users/actions";
import PopupConfirm from '@/components/common/PopupConfirm.vue';

export default {
  name: "ProfileSetting",
  components: {
    ButtonComponent,
    PopupConfirm,
  },
  data() {
    return {
      avatar: require("@/assets/images/avatar_default.png"),
      avatarDefault: require("@/assets/images/avatar_default.png"),
      form: {
        email: '',
        companyName: '',
        representativesName: ''
      },
      currentForm: {},
      currentEmail: ''
    }
  },
  setup: () => ({ v$: useVuelidate({$lazy: true}) }),
  validations() {
    return {
      form: {
        email: {
          required,
          email,
          maxLength: maxLength(255)
        },
        companyName: {
          required,
          maxLength: maxLength(100)
        },
        representativesName: {
          required,
          maxLength: maxLength(100)
        }
      }
    }
  },
  created() {
    this.getUser()
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0]
      this.avatar = URL.createObjectURL(file)
    },
    removeFileChange() {
      this.avatar = this.avatarDefault
    },
    getUser() {
      UserService.getUserProfile().then((res) => {
        this.form.email = this.currentEmail = res.data.data.email
        this.form.companyName = res.data.data.organization_name
        this.form.representativesName = res.data.data.name
        this.currentForm = {...this.form}
      }).catch()
    },
    handleSubmit() {
      this.v$.form.$touch()
      if (!this.v$.form.$error) {
        if (this.form.email.trim() !== this.currentEmail) {
          this.$refs['confirm-email'].open()
        } else {
          this.updateUser()
        }
      }
    },
    updateUser() {
      const data = {
        email: this.form.email,
        name: this.form.representativesName,
        organization_name: this.form.companyName
      }
      UserService.updateUserProfile(data)
      .then(() => {
        const user = this.$store.getters['authUser']
        user.name = data.name
        user.organization_name = data.organization_name
        this.$store.dispatch(ACTION_SAVE_AUTH_USER, user)
        this.$toast.success('プロファイルが更新されました')
      })
      .then(() => {
        if (this.form.email.trim() !== this.currentEmail) {
          this.$refs['confirm-email'].close()
          this.$refs['email-sent'].open()
        }
      })
      .catch()
    },
    cancel() {
      this.form = {...this.currentForm}
    }
  }
}
</script>
<style scoped lang="scss">
.form-profile-setting {
  .form-control-input input::placeholder {
    font-weight: 400;
    font-size: 14px !important;
    line-height: 200%;
    letter-spacing: 0.005em;
    color: #C2C2C2;
  }
  .title-avatar {
    font-weight: 700;
    font-size: 14px;
    line-height: 200%;
    letter-spacing: 0.005em;
    color: #333333;
  }
  .box-avtar img {
    width: 160px !important;
    height: 160px !important;
  }
  .div-btn {
    width: 160px !important;
    height: 36px !important;
    border: 1px solid #339967;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.005em;
    color: #339967;
  }
  .btn-remove-avatar {
    width: 160px !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 200%;
    letter-spacing: 0.005em;
    text-decoration-line: underline;
    color: #333333;
    cursor: pointer;
    margin-top: 12px;
    text-align: center;
  }
  .box-action-profile {
    display: flex;
    justify-content: center;
    margin: 48px 0 64px 0;
    button{
      width: 113px !important;
      height: 40px !important;
    }
  }
}
</style>
