<template>
    <LoadingComponent v-if="isLoading"/>
</template>
<script>
import { UserService } from "@/services";
import LoadingComponent from "../common/Loading.vue";
export default {
    components: {
        LoadingComponent
    },
    data() {
        return {
            isLoading : false
        }
    },
    methods: {
        freeeCallback() {
            this.isLoading = true;
            const userId = JSON.parse(localStorage.getItem('userInfo')).id
            const params = this.$route.query;
            const query = `?user_id=${userId}&email=${params.email}&token=${params.token}&refresh_token=${params.refresh_token}&id=${params.id}`
            UserService.freeeCallback(query).then(()=>{
                this.$router.push({name : 'home', query : { 'freee_token' : params.token}}).catch(() => {});
                this.isLoading = false;
            }).catch(()=>{
                this.isLoading = false;
            })
        }
    },
    created() {
        this.freeeCallback();
    },
}
</script>