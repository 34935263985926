import {corporateColor} from '@/commons/plan_const';
import {numberWithCommas} from '@/commons/helpers';

const doughnutChartOption = {
  responsive: true,
  maintainAspectRatio: false,
  borderWidth: 0,
  layout: {
    padding: 5,
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      mode: 'index',
      displayColors: false,
      backgroundColor: '#FFFFFF',
      titleColor: '#4B4B4B',
      titleFont: {
        size: 12,
        weight: 700,
        lineHeight: '17px'
      },
      titleAlign: 'center',
      bodyColor: "#4B4B4B",
      bodyFont: {
        size: 18,
        weight: 700,
        lineHeight: '21px'
      },
      bodyAlign: 'center',
      borderColor: '#F3F4F3',
      borderWidth: 1,
      callbacks: {
        title: tooltipItem => {
          if (tooltipItem[0].label.length > 30) {
            return tooltipItem[0].label.slice(0, 30) + '...'
          }
          return `${tooltipItem[0].label}`
        },
        label: function (context) {
          let value = context.parsed;
          let sum = 0;
          let dataArr = context.chart.data.datasets[0].data;
          dataArr.map(data => {
            sum += Number(data);
          });
          let percentage = (value * 100 / sum).toFixed(2) + '%';
          return percentage;
        }
      },

    },

  }
}

const lineChartOption = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      offset: true,
      grid: {
        display: false,
        color: "#FBFBFB"
      },
      ticks: {
        color: '#4B4B4B',
        font: {
          size: 14,
        }
      }
    },
    y: {
      ticks: {
        maxTicksLimit: 5,
        color: '#4B4B4B',
        font: {
          size: 12,
          weight: "bold"
        },
        callback: function (value) {
          let displayValue = value
          let suffix = ''
          if (value) {
            if (value >= 100000000 || value <= -100000000) {
              displayValue = Math.floor(value / 100000000)
              suffix = '億'
            } else if (value >= 10000 || value <= -10000) {
              displayValue = Math.floor(value / 10000)
              suffix = '万'
            }
            return numberWithCommas(displayValue) + suffix + '円';
          } else {
            return value
          }
        }
      }
    },
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      mode: 'index',
      displayColors: false,
      backgroundColor: '#FFFFFF',
      titleColor: '#4B4B4B',
      titleFont: {
        size: 14,
        weight: 700,
      },
      titleAlign: 'center',
      borderColor: '#F3F4F3',
      borderWidth: 1,
      padding: {
        left: 15,
        right: 15,
        top: 12,
        bottom: 4
      },
      callbacks: {
        title: (tooltipItem) => {
          return '¥' + numberWithCommas(tooltipItem[0].raw)
        },
        label: () => {
          return
        }
      }
    },
  }
}

const lineBarChartOption = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
        color: "#FBFBFB"
      }
    },
    y: {
      ticks: {
        maxTicksLimit: 5,
        color: '#4B4B4B',
        font: {
          size: 12,
          weight: "bold"
        },
        callback: function (value) {
          let displayValue = value
          let suffix = ''
          if (value) {
            if (value >= 100000000 || value <= -100000000) {
              displayValue = value / 100000000
              suffix = '億'
            } else if (value >= 10000 || value <= -10000) {
              displayValue = value / 10000
              suffix = '万'
            }
            return value % 2 == 0 ? (numberWithCommas(displayValue) + suffix + '円') : null;
          } else {
            return value
          }
        }
      }
    },
    barPercentage: {
      id: 'barPercentage',
      beginAtZero: true,
      type: "linear",
      position: "right",
      gird: {
        drawOnChartArea: false
      },
      min: -100,
      max: 100,
      ticks: {
        stepSize: 50,
        color: '#4B4B4B',
        font: {
          size: 12,
          weight: "bold"
        },
        callback: function (value) {
          return value.toFixed(2) + '%';
        }
      }
    },
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: true,
      usePointStyle: false,
      displayColors: false,
      backgroundColor: '#FFFFFF',
      bodyColor: "#4B4B4B",
      bodyFont: {
        size: 12,
        weight: 700,
        lineHeight: '16px'
      },
      bodyAlign: 'left',
      borderColor: '#F3F4F3',
      borderWidth: 1,
      padding: {
        left: 20,
        right: 20,
        top: 13,
        bottom: 8
      },
      callbacks: {
        title: function () {
          return
        },
        label: function (tooltipItem) {
          if (tooltipItem.dataset.type == "line") {
            return tooltipItem.raw + '%';
          }
          if (tooltipItem.dataset.type == "bar") {
            return '¥' + numberWithCommas(tooltipItem.raw);
          }
        }
      }
    }
  }
}

const lineStackedBarChartOption = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        color: "#FBFBFB"
      },
      ticks: {
        color: '#4B4B4B',
        font: {
          size: 14,
        }
      }
    },
    y: {
      stacked: true,
      ticks: {
        maxTicksLimit: 6,
        color: '#4B4B4B',
        font: {
          size: 12,
          weight: "bold"
        },
        callback: function (value) {
          let displayValue = value
          let suffix = ''
          if (value) {
            if (value >= 100000000 || value <= -100000000) {
              displayValue = value / 100000000
              suffix = '億'
            } else if (value >= 10000 || value <= -10000) {
              displayValue = value / 10000
              suffix = '万'
            }
            return value % 2 == 0 ? (numberWithCommas(displayValue) + suffix + '円') : null;
          } else {
            return value
          }
        }
      }
    },
    lineAmountRaised: {
      id: 'lineAmountRaised',
      stacked: true,
      beginAtZero: true,
      type: "linear",
      position: "right",
      gird: {
        drawOnChartArea: false
      },
      ticks: {
        maxTicksLimit: 6,
        color: '#4B4B4B',
        font: {
          size: 12,
          weight: "bold"
        },
        callback: function (value) {
          let displayValue = value
          let suffix = ''
          if (value) {
            if (value >= 100000000 || value <= -100000000) {
              displayValue = value / 100000000
              suffix = '億'
            } else if (value >= 10000 || value <= -10000) {
              displayValue = value / 10000
              suffix = '万'
            }
            return value % 2 == 0 ? (numberWithCommas(displayValue) + suffix + '円') : null;
          } else {
            return value
          }
        }
      }
    },
  },
  plugins: {
    legend: {
      display: false
    },

    tooltip: {
      enabled: true,
      usePointStyle: false,
      displayColors: false,
      backgroundColor: '#FFFFFF',
      titleColor: '#4B4B4B',
      titleFont: {
        size: 14,
        weight: 700,
        lineHeight: '19px'
      },
      titleAlign: 'left',
      bodyColor: "#4B4B4B",
      bodyFont: {
        size: 12,
        weight: 700,
        lineHeight: '16px'
      },
      bodyAlign: 'left',
      borderColor: '#F3F4F3',
      borderWidth: 1,
      padding: {
        left: 20,
        right: 20,
        top: 13,
        bottom: 8
      },
      callbacks: {
        title: (tooltipItem) => {
          if (tooltipItem[0].dataset.type == "line") {
            return '時価総額'
          }
        },
        label: function (tooltipItem) {
          if (tooltipItem.dataset.type == "line") {
            return '¥' + numberWithCommas(tooltipItem.raw);
          }
          if (tooltipItem.dataset.type == "bar") {
            let dataColumn = tooltipItem.dataset.dataColumn;
            let dataIndex = tooltipItem.datasetIndex;
            let tooltip = dataColumn[dataIndex - 1].label + ' : ¥' + numberWithCommas(dataColumn[dataIndex - 1].data);
            // let total = dataColumn.slice(0, dataIndex + 1).map(item => item.data).reduce((a, b) => a + b , 0);
            // tooltip.push('合計調達 : ' + '¥' + numberWithCommas(total))
            return tooltip;
          }
        }
      }
    }
  }
}

const stackedBarChartOption = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        color: "#FBFBFB"
      },
      ticks: {
        color: '#4B4B4B',
        font: {
          size: 14,
        }
      }
    },
    y: {
      stacked: true,
      ticks: {
        color: '#4B4B4B',
        font: {
          size: 12,
          weight: "bold"
        },
        callback: function (value) {
          let displayValue = value
          let suffix = ''
          if (value) {
            if (value >= 100000000 || value <= -100000000) {
              displayValue = value / 100000000
              suffix = '億'
            } else if (value >= 10000 || value <= -10000) {
              displayValue = value / 10000
              suffix = '万'
            }
            return value % 2 == 0 ? (numberWithCommas(displayValue) + suffix + '円') : null;
          } else {
            return value
          }
        }
      }
    }
  },
  plugins: {
    legend: {
      display: false
    }
  }
}

const lineStackedBarChartDataColumn = (corporateValue = []) => {
  if (corporateValue.length) {
    const sumShares = corporateValue.map(item => item.sum_shares);
    const maxLength = Math.max(...sumShares.map(item => item.length));
    const newSumShares = formatDataCorporate(corporateValue, maxLength)
    return corporateValue.map((item, index) => {
      const obj = {
        type: 'bar',
        label: item.round_name,
        data: newSumShares[index],
        color: corporateColor[index]
      }
      return obj;
    })
  }
}

const formatDataCorporate = (data, maxLength) => {
  let arrTemp = []
  for (let i = 0; i < maxLength; i++) {
    data.map(item => {
      arrTemp.push(item.sum_shares[i] ?? 0)
    })
  }
  const chunkArray = (myArray, chunk_size) => {
    var results = [];

    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }

    return results;
  }
  return chunkArray(arrTemp, maxLength)
}

const lineStackedBarChartData = (data = []) => {
  if (data.length) {
    return data.map((item) => {
      const obj = {
        label: item.round_name,
        data: Number(item.sum_shares[item.sum_shares.length - 1]),
      }
      return obj;
    })
  }

}

const formatColor = (color) => {
  let channelInter = (v, p) => 255 - (255 - v) * p | 0
  const [r, g, b, per] = color.match(/[0-9.]+/g)
  const diff = Number(per)
  return `rgb(${channelInter(Number(r), diff)},${channelInter(Number(g), diff)},${channelInter(Number(b), diff)})`
}

export {
  doughnutChartOption,
  lineChartOption,
  lineBarChartOption,
  lineStackedBarChartOption,
  stackedBarChartOption,
  lineStackedBarChartDataColumn,
  lineStackedBarChartData,
  formatColor
}
