<template>
  <div class="box-account-setting">
    <div class="box-content-setting">
      <div class="title-content-setting">
        パスワードの再設定
      </div>
      <div class="form-password-setting">
            <div class="d-flex">
            <div class="w-50">
                <div class="form-input">
                <label>新しいパスワード</label>
                <div class="form-control">
                    <div class="form-control-input">
                      <input v-model="v$.form.password.$model" @input="checkPassword" type="password" class="form-control w-480-px">
                    </div>
                    <div v-if="v$.form.password.maxLength.$invalid" class="error-text">255文字以内でご入力ください。</div>
                </div>
                </div>
                <div class="form-input m__top--24">
                <label>新しいパスワード（確認用）</label>
                <div class="form-control">
                    <div class="form-control-input">
                    <input v-model="v$.form.confirm_password.$model" @input="v$.form.confirm_password.$reset" type="password" class="form-control w-480-px">
                    </div>
                    <div v-if="v$.form.confirm_password.$errors">
                        <div v-if="v$.form.confirm_password.required.$invalid" class="error-text">新しいパスワード（確認用）は必須です。</div>
                        <div v-else-if="v$.form.confirm_password.sameAs.$invalid" class="error-text">パスワードと同じで入力してください。</div>
                    </div>
                </div>
                <div class="validate-password mt-2">
                    <div>
                    8文字以上の英数字をご使用ください。
                    </div>
                    <div class="d-flex mt-2">
                    <img v-if="!isChecking" :src="iconError" alt="iconCheck" class="mr-1">
                    <img v-else :src="v$.form.password.minLength.$invalid || v$.form.password.required.$invalid ? iconError : iconCheck" alt="iconCheck" class="mr-1">
                    <div>
                        8文字以上
                    </div>
                    </div>
                    <div class="d-flex mt-2">
                    <img v-if="!isChecking" :src="iconError" alt="iconCheck" class="mr-1">
                    <img v-else :src="v$.form.password.alphaNum.$invalid || v$.form.password.required.$invalid ? iconError : iconCheck" alt="iconError" class="mr-1">
                    <div>
                        英数字をご使用ください。
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="box-action-password">
            <ButtonComponent text="キャンセル" mode="btn-tertiary m__right--24" :func="clearData"/>
            <div class="w-113-px">
                <ButtonComponent v-if="!$store.getters.isLoading" text="変更を保存" mode="btn-primary" :func="changePassword"/>
                <div v-else class="donut"></div>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import ButtonComponent from "@/components/common/ButtonComponent";
import { useVuelidate } from '@vuelidate/core';
import { UserService } from "@/services";
import {required, alphaNum, minLength, sameAs, maxLength} from "@vuelidate/validators";

export default {
  name: "LoggedResetPassword",
  components: {
    ButtonComponent
  },
  data() {
    return {
      iconError: require("@/assets/images/icons/error.svg"),
      iconCheck: require("@/assets/images/icons/check.svg"),
      form: {
            password: '',
            confirm_password: ''
        },
      isChecking: false,
    }
  },
  setup: () => ({ v$: useVuelidate({$lazy: true}) }),
  validations() {
    return {
      form: {
        password: {
          required,
          alphaNum,
          minLength: minLength(8),
          maxLength: maxLength(255),
        },
        confirm_password: {
          required,
          sameAs: sameAs(this.password)
        }
      }
    }
  },
  methods: {
    clearData() {
      this.form = {
        password: '',
        confirm_password: ''
      }
    },
    checkPassword() {
      this.isChecking = true
      this.v$.form.password.$touch()
    },
    changePassword() {
      if (!this.v$.form.$invalid) {
        const data = {
          password: this.form.password,
          password_confirmation: this.form.confirm_password,
          token: this.$route.query.token
        }
        UserService.resetPassword(data)
        .then(() => {
            this.$toast.success(this.$t("messages.reset_password_success"))
            this.$router.push({name: 'home'})
        })
        .catch((e) => {
            if (e.status !== 422) {
              this.$toast.error(e.data.message)
            }
        })
      }
    }
  },
  beforeRouteEnter(to, from, next) {
      next((vm) => {
        if (!vm.$route.query.token) {
          vm.$router.push({name: 'home'})
        }
      })
  }
}
</script>
<style scoped lang="scss">
.box-account-setting {
  margin-top: 24px;
  .box-content-setting {
    background: #FFFFFF;
    box-shadow: 0 4px 8px rgba(173, 182, 177, 0.1);
    border-radius: 4px;
    padding: 24px 40px;

    .title-content-setting {
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
      letter-spacing: 0.005em;
      color: #333333;
      margin-bottom: 24px;
    }
    .form-password-setting {
  .form-control-input input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 200%;
    letter-spacing: 0.005em;
    color: #C2C2C2;
  }
  .box-action-password {
    display: flex;
    justify-content: center;
    margin: 48px 0 64px 0;
    button{
      width: 113px !important;
      height: 40px !important;
    }
  }
  .validate-password {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #333333;
  }
  .box-forgot-pass-email {
    font-weight: 400;
    font-size: 14px;
    line-height: 200%;
    letter-spacing: 0.005em;
    color: #333333;
    margin-top: 27px;
    .send-mail-pass {
      font-weight: 700;
      font-size: 14px;
      line-height: 200%;
      letter-spacing: 0.005em;
      text-decoration-line: underline;
      color: #333333;
      cursor: pointer;
    }
  }
}
  }
}
</style>
