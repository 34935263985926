<template>
    <div class="title-component">
        <div class="title-component--left">
            <span>{{ title }}</span>
        </div>
        <div class="title-component--right align-center">
            <div v-if="charLabels" class="list-chart-labels d-flex mr-10">
                <div v-for="(item, index) in charLabels" :key="index" class="label-item d-flex align-center justify-center ml-8 fs-12">
                    <div class="bar-label mr-2" :style="{background: item.color}"></div>
                    <div class="bar-title">{{ item.title }}</div>
                </div>
            </div>
            <div class="list-time-option" v-if="timeOptions && timeOptions.length">
                <div class="time-option"
                    v-for="option, index in timeOptions"
                    :key="'option'+ index"
                    :class="{ active : option.value == optionSelected}"
                    @click="selectOption(option.value)"
                >
                    {{ option.text }}
                </div>
            </div>
            <div class="btn-detai m__right--8" v-if="isSubButton">
              <button-component
                :text="textSubButton"
                :width="widthSubButton"
                :height="heightSubButton"
                :mode="modeSubButton"
                :func="actionSubButton"
              ></button-component>
            </div>
            <div class="btn-detai" v-if="isButton">
                <button-component
                :text="textButton"
                :width="widthButton"
                :height="heightButton"
                :mode="modeButton"
                :func="actionButton"
                :suffixIcon="iconArrowRight"
                ></button-component>
            </div>
        </div>
    </div>
</template>
<script>
import ButtonComponent from "@/components/common/ButtonComponent.vue";
export default {
    name : "FormHeader",
    components :{
        ButtonComponent
    },
    props : {
        title : String,
        modeButton : String,
        textButton : String,
        widthButton : String,
        heightButton : String,
        textSubButton: String,
        widthSubButton: String,
        heightSubButton: String,
        modeSubButton: String,
        actionSubButton: Function,
        timeOptions : {
            type: Array,
            default: () => []
        },
        actionButton : Function,
        isButton: {
            type: Boolean,
            default: true
        },
        isSubButton: {
          type: Boolean,
          default: false
        },
        selected: Number,
        charLabels: Array,
        // [
        //     {
        //         color: '#abcxyz',
        //         title: 'something'
        //     },
        //     {
        //         color: '#abcxyz',
        //         title: 'something'
        //     },
        // ]
    },
    watch: {
        selected: function(value){
            this.optionSelected = value;
        }
    },
    data(){
        return {
            iconArrowRight : require("@/assets/images/icons/ic_arrow_right_green.svg"),
            optionSelected : this.$props.selected,
        }
    },
    methods : {
        selectOption(value){
            this.optionSelected = value;
            this.$emit('selectTimeOption', value);
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
.title-component {
    width: 100%;
    display: flex;
    align-items: flex-start ;
    justify-content: space-between;
    .title-component--left {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: $primary_color;
    }
    .title-component--right {
        display: flex;
        align-items: flex-start;
        .list-time-option {
            background-color: $key_line_color_2;
            display: flex;
            margin-right: 16px;
            padding: 4px;
            border-radius: 4px;
            .time-option {
                padding: 6px 8px;
                border-radius: 4px;
                font-weight: 700;
                font-size: 13px;
                line-height: 15px;
                color: $tertiary;
                cursor: pointer;
                margin-left: 8px;
                &:nth-child(1) {
                    margin-left: 0;
                }
                &.active {
                    color: $corporate_color_1;
                    background: $white;
                    box-shadow: 0px 4px 4px rgba(204, 229, 217, 0.6);
                    line-height: 16px;
                }
            }
        }
        .list-chart-labels {
            .label-item {
                .bar-label {
                    width: 32px;
                    height: 4px;
                }
                .bar-title {
                    font-weight: 500;
                }
            }
        }
    }
}
</style>
