<template>
    <div>
        <layout-app :isLogin="false"></layout-app>
        <div class="app-container">
            <div class="content register-content">
                <div class="logo">
                    <img :src="logo" alt="">
                </div>

                <div class="description-form fs-16 font-weight-medium m__top--48 color__primary_black">
                    所属組織と担当者名を登録してください。
                </div>

            <div class="form-group">
                <label class="m__top--32 mb-2">氏名</label>
                <v-combobox
                    class="form-control large px-0 py-0"
                    placeholder="氏名"
                    v-model.trim="form.name"
                    append-icon=""
                    :items="items"
                    flat
                    solo
                    @update:search-input="getItems($event)"
                  >
                  <template v-slot:append-item>
                    <v-card
                      v-if="lastPage !== currentPage"
                      v-intersect="visibilityChanged"
                      class="text-xs-center"
                    >
                    </v-card>
                  </template>
                </v-combobox>
                <div v-if="v$.form.name.$errors">
                    <div v-if="v$.form.name.required.$invalid" class="error-text">氏名又は会社名は必須です。</div>
                    <div v-else-if="v$.form.name.maxLength.$invalid" class="error-text">氏名又は会社名は100文字以内でご入力ください。</div>
                </div>
            </div>

            <div class="form-group">
                <label class="m__top--32">メールアドレス</label>
                <input
                    v-model="v$.form.email.$model"
                    @input="v$.form.email.$reset"
                    class="form-control large"
                    placeholder="carte@mail.com">
                <div v-if="v$.form.email.$errors">
                    <div v-if="v$.form.email.required.$invalid" class="error-text">メールアドレスは必須です。</div>
                    <div v-else-if="v$.form.email.email.$invalid" class="error-text">有効なメールアドレスをご入力ください。</div>
                    <div v-else-if="v$.form.email.maxLength.$invalid" class="error-text">メールアドレスは255文字以内でご入力ください。</div>
                </div>
            </div>

            <div class="d-flex justify-center m__top--48">
                <div v-if="isLoading" class="donut"></div>
                <button-component
                    v-else
                    :text="textButton"
                    :small=false
                    :func="register"
                    :width="widthButton"
                ></button-component>
            </div>
            </div>

            <div class="progress-bar d-flex justify-center m__top--56">
                <progress-component :step="step"></progress-component>
            </div>
        </div>
    </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import {required, email, maxLength} from "@vuelidate/validators"
import ButtonComponent from "@/components/common/ButtonComponent.vue";
import ProgressComponent from "@/components/common/ProgressComponent.vue";
import { UserService } from '@/services'
import { ACTION_SAVE_AUTH_USER, ACTION_SET_ACCESS_TOKEN } from '@/stores/auth_users/actions'
import { ROLE_INVESTOR } from '@/commons/plan_const'
import LayoutApp from "@/components/layouts/LayoutApp.vue";

export default {
    components : {
        ButtonComponent,
        ProgressComponent,
        LayoutApp
    },
    data(){
        return {
            logo : require('@/assets/images/svgs/logo_carte.svg'),
            iconRadio : require('@/assets/images/icons/ic_radio.svg'),
            iconRadioActive : require('@/assets/images/icons/ic_radio_active.svg'),
            textButton : "資本政策へ",
            widthButton : "150px",
            step : 2,
            form: {
                name: '',
                email: localStorage.getItem('emailInvestor'),
            },
            isLoading: false,
            lastPage: 0,
            isLastPage: false,
            currentPage: 1,
            items: [],
            searchInvestor: '',
        }
    },
    setup: () => ({ v$: useVuelidate({$lazy: true}) }),
    validations() {
        return {
            form: {
                name: {
                    required,
                    maxLength: maxLength(100),
                },
                email: {
                    email,
                    required,
                    maxLength: maxLength(255),
                },
            }
        }
    },
    methods: {
        visibilityChanged(e) {
            this.currentPage !== this.lastPage && this.lastPage !== 1 && e && this.loadMore();
        },
        async getItems(investor) {
          if (this.searchInvestor !== investor) {
            this.searchInvestor = investor
            this.form.name = investor
            this.currentPage = 1
          }
          try {
            const { data } = await UserService.investorSuggest({page: this.currentPage, investor_name: investor});
            this.lastPage = parseInt(data.data.last_page)
            this.items = [
              ...data.data.investor_names,
            ]
          } catch (e) {
            //
          }
        },
        async loadMore() {
          try {
            const { data } = await UserService.investorSuggest({page: this.currentPage, investor_name: this.searchInvestor});
            if (data.data.investor_names && data.data.investor_names.length) {
              ++this.currentPage;
              this.lastPage = parseInt(data.data.last_page)
              this.items = [
                ...this.items,
                ...data.data.investor_names,
              ]
            }
          } catch(e) {
            //
          }
        },
        register() {
            this.v$.form.$touch()
            if (!this.v$.form.$error) {
                this.isLoading = true
                const data = {
                    investor_name: this.form.name,
                    email: this.form.email,
                    user_investor_id: this.$route.query.user_investor_id,
                    investor_token: this.$route.query.investor_token,
                    token_invite: this.$route.query.token_invite || null,
                }
                if (!data.token_invite) delete data.token_invite
                UserService.investorRegisterOrLogin(data)
                .then(res => {
                    this.$store.dispatch(ACTION_SAVE_AUTH_USER, {...res.data.data.user_investor, role: ROLE_INVESTOR})
                    this.$store.dispatch(ACTION_SET_ACCESS_TOKEN, res.data.data.investor_access_token)
                    localStorage.removeItem('emailInvestor');
                    if (res.data.data.startup_id) {
                      let startupId = res.data.data.startup_id
                      this.$router.push({name: 'Startup capital policy', params: { id: startupId }})
                    } else {
                      this.$router.push({name: 'home'})
                    }
                }).catch(err => {
                    this.isLoading = false
                    this.$toast.error(err.data.message)
                })
            }
        }
    },
    // beforeRouteEnter(to, from, next) {
    //     next((vm) => {
    //         const isAuthenticated = vm.$store.getters.accessToken || !!localStorage.getItem('accessToken')
    //         if (isAuthenticated) {
    //             vm.$router.push({name: 'home'})
    //         }
    //     })
    // }
}
</script>
<style scoped lang="scss">
.description-form {
    line-height: 32px;
}
</style>
<style lang="scss">
.register-content ::placeholder {
  color: #757575 !important;
}
</style>
