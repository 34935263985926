<template>
    <div>
        <layout-app :isLogin="false"></layout-app>
        <div class="app-container">
            <div class="content register-content">
                <div class="title-form fs-32 font-weight-bold m__top--48 color__primary_black">Facebook連携画面<br>（facebook内）</div>
            </div>
        </div>
    </div>
</template>

<script>
import LayoutApp from "@/components/layouts/LayoutApp.vue";
import { UserService } from '@/services';
import { ACTION_SAVE_AUTH_USER, ACTION_SET_ACCESS_TOKEN } from '@/stores/auth_users/actions'
import { ROLE_INVESTOR } from '@/commons/plan_const';
export default {
    components : {
        LayoutApp
    },
    mounted() {
        if (!this.$route.query.token) {
            this.$router.push({name: 'home'})
        }
        const token = this.$route.query.token
        this.$store.dispatch(ACTION_SET_ACCESS_TOKEN, token)
        UserService.getUserProfile()
        .then((res) => {
            this.$store.dispatch(ACTION_SAVE_AUTH_USER, {...res.data.data, role: ROLE_INVESTOR})
        })
        .then(() => {
            this.$router.push({name: 'home'})
        })
        .catch(err => {
            this.$toast.error(err.data.message)
        })
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/index.scss";
.description-form { 
    line-height: 32px;
}
.link-to-login {
    font-weight: 700;
    font-size: 14px;
    line-height: 200%;
    letter-spacing: 0.005em;
    text-decoration-line: underline;
    color: $primary-color;
    cursor: pointer;
    margin-top: 24px;
}
</style>