<template>
  <div class="simulation-screen">
    <div class="main-container p__top--28 p__bottom--30">
        <TitleComponent :title="title" :hasBack="true" />
        <div class="main-content px-6 py-6">
            <div v-if="isLoading" class="d-flex justify-center">
                <div class="donut"></div>
            </div>
            <div v-else>
                <div class="section-title pb-3">
                    <div class="inner-title fs-24 font-weight-bold pb-6 text-center">
                        {{ title }}
                    </div>
                    <div class="company-info d-flex">
                        <div class="company-name">
                            <div class="color__primary_secondary fs-12">会社名</div>
                            <div class="fs-18 font-weight-bold">{{ company_name }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="items && items.length !== 0" class="simulation-content m__top--42 p__right--73 p__left--73 m__bottom--40">
                    <table class="ma-value m__bottom--52">
                        <tr>
                            <td>
                                <div class="font-weight-bold m__right--56">M&A企業価値</div>
                            </td>
                            <td class="text-center" style="min-width: 200px">
                                <div v-if="!CValueEdit" class="value font-weight-bold mr-6">
                                    <span class="fs-28 mr-1">{{ company_total_value }}</span>
                                    <span class="fs-14">{{ currency }}</span>
                                </div>
                                <div v-else class="value font-weight-bold mr-6">
                                    <span class="mr-1 CV-input px-1 py-1">
                                        <input class="text-center fs-20" type="text" v-model.trim="editingCValue">
                                    </span>
                                    <span class="fs-14" style="vertical-align: bottom">{{ currency }}</span>
                                </div>
                            </td>
                            <td>
                                <div v-if="!CValueEdit" class="d-flex px-3 py-2 custom-button color__primary_secondary border__primary_secondary" @click="editCompanyValue">
                                    <img :src="require('@/assets/images/icons/ic_edit.svg')" alt="">
                                    <div class="m__right--4 font-weight-bold fs-14">企業価値を変更</div>
                                </div>
                                <div v-else class="d-flex justify-center px-3 py-2 custom-button" @click="submitCValue">
                                    <div class="m__right--4 font-weight-bold fs-14">企業価値を更新</div>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <div class="simulation-data">
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            no-data-text="データがありません。"
                            disable-sort
                            disable-pagination
                            hide-default-footer
                            hide-default-header>
                            <template v-slot:header="{ props: { headers } }">
                                <thead>
                                    <tr>
                                        <th v-for="item in headers" :key="item.value" class="text-center custom-header px-0" :style="{ 'width': item.width }">
                                            <div :class="{ 'text-right': item.value === 'profitOnSale' }">{{ item.text }}</div>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:[`item.shareholder`]="{ item }">
                                <td class="td-ellipsis">
                                    <div></div>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div v-bind="attrs" v-on="on" class="fs-12 ellipsis">{{ item.shareholder }}</div>
                                        </template>
                                            <span>{{ item.shareholder }}</span>
                                    </v-tooltip>
                                </td>
                            </template>
                            <template v-slot:[`item.holding`]="{ item }">
                                <div class="fs-13">{{ item.holding !== 'ー' ? Math.round(item.holding * 100) / 100 + '%' : item.holding }}</div>
                            </template>
                            <template v-slot:[`item.saleAmount`]="{ item }">
                                <div :class="{ 'alert': getSaleAmount(item) < 0 }" v-if="items.indexOf(item) !== items.length - 1" class="fs-10 color__primary_black text-right">{{ jpCurrencyFormat(Math.round(getSaleAmount(item) * 100) / 100) }}</div>
                                <div :class="{ 'alert': totalSaleAmount < 0 }" v-else class="fs-10 color__primary_black text-right">{{ jpCurrencyFormat(Math.round(totalSaleAmount * 100) / 100) }}</div>
                            </template>
                            <template v-slot:[`item.totalInvestment`]="{ item }">
                                <div class="fs-10">{{ jpCurrencyFormat(item.totalInvestment) }}</div>
                            </template>
                            <template v-slot:[`item.preferredShare`]="{ item }">
                                <div class="fs-10">{{ jpCurrencyFormat(item.preferredShare) }}</div>
                            </template>
                            <template v-slot:[`item.profitOnSale`]="{ item }">
                                <div :class="{ 'alert': getProfit(item) < 0 }">{{ item.profitOnSale !== 'ー' ? jpCurrencyFormat(Math.round(getProfit(item) * 100) / 100) : item.profitOnSale }}</div>
                            </template>
                        </v-data-table>
                    </div>
                </div>
                <div v-if="!items || items.length === 0" class="no-data m__top--42 p__right--73 p__left--73 m__bottom--40 text-center">
                    データがありません。
                </div>
                <div v-if="items && items.length !== 0" class="d-flex justify-center fs-14">
                    <div class="custom-button px-4 py-3 font-weight-bold" @click="exportSimulation">エクセルでダウンロード</div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/common/Title.vue";
import { UserService } from '@/services';
export default {
  name: "SimulationScreen",
  components: {
    TitleComponent,
  },
  data () {
    return {
        title: 'M&Aシュミレーション',
        company_name: '株式会社Carte',
        company_type: 'テクノロジー',
        company_value: 500000000,
        currency: '円',
        editingCValue: 0,
        CValueEdit: false,
        totalEdit: false,
        isLoading: false,
        headers: [
            {
                text: '株主',
                value: 'shareholder',
                align: 'left',
                width: '20%'
            },
            {
                text: '保有株%',
                value: 'holding',
                align: 'right',
                width: '7%'
            },
            {
                text: '売却額（普通株）',
                value: 'saleAmount',
                align: 'right',
                width: '14%'
            },
            {
                text: '売却額（優先株）',
                value: 'preferredShare',
                align: 'right',
                width: '14%'
            },
            {
                text: '出資総額',
                value: 'totalInvestment',
                align: 'right',
                width: '14%'
            },
            {
                text: '売却益（売却額-出資額）',
                value: 'profitOnSale',
                align: 'right',
                width: '14%'
            }
        ],
        items: [],
        totalPreferredShare: 0,
    };
  },
  computed: {
    company_total_value() {
        return this.company_value.toString(10).split('').reverse().join('')
                                 .match(/(\d{1,3})/g).join(',').split('')
                                 .reverse().join('');
    },
    totalSaleAmount() {
        let total = 0;
        for (let i = 0; i < this.items.length - 1; ++i) {
            total += (this.company_value - this.totalPreferredShare) * this.items[i].holding / 100;
        }
        return total;
    }
  },
  created() {
    this.getData();
  },
  mounted() {
  },
  methods: {
    getData() {
        this.isLoading = true
        UserService.getSimulation().then(res => {
            this.items = []
            if (res.data.data && res.data.data.length !== 0) {
                const data = res.data.data
                this.company_value = data.corporate_value
                this.company_name = data.startup_name
                data.list_investors.forEach(item => {
                    this.totalPreferredShare += item.preferred_share_receive
                })
                data.list_investors.forEach((item) => {
                    this.items.push({
                        shareholder: item.investor_name,
                        holding: item.number_of_share_holds,
                        saleAmount: item.number_of_share_holds * (data.corporate_value - this.totalPreferredShare) / 100,
                        preferredShare: item.preferred_share_receive,
                        totalInvestment: item.total_investment,
                        profitOnSale: item.number_of_share_holds * data.corporate_value / 100 + item.preferred_share_receive - item.total_investment,
                    })
                })
                this.items.push({
                    shareholder: '合計',
                    holding: 'ー',
                    saleAmount: data.corporate_value,
                    preferredShare: 'ー',
                    totalInvestment: 'ー',
                    profitOnSale: 'ー',
                })
            }
            this.isLoading = false

        }).then(() => {
            // const self = this
            const element = document.querySelector('.simulation-data > .v-data-table')
            const wrapper_height = element.querySelector('table').offsetHeight
            const footer = element.querySelector('table > tbody > tr:last-of-type')
            const header = element.querySelector('thead > tr')
            footer.style.bottom = (wrapper_height - element.offsetHeight - element.scrollTop) + 'px'
            const fixedRow = function() {
                header.style.top = element.scrollTop + 'px'
                footer.style.bottom = (wrapper_height - element.offsetHeight - element.scrollTop) + 'px'
            }
            element.addEventListener('scroll', fixedRow);
        })
        .catch(() => {
            this.isLoading = false
        })
    },
    getSaleAmount(item) {
        const index = this.items.indexOf(item)
        return (this.company_value - this.totalPreferredShare) * this.items[index].holding / 100;
    },
    getProfit(item) {
        const index = this.items.indexOf(item)
        return this.getSaleAmount(item) + this.items[index].preferredShare - this.items[index].totalInvestment;
    },
    jpCurrencyFormat(number) {
        if (number !== 'ー') {
            if (number < 0) {
                return '￥' + number.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
            }
            return '￥' + number.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
            }
        return number;
    },
    calculateProfit(index) {
        this.items[index].saleAmount = this.company_value * this.items[index].holding / 100
        this.items[index].profitOnSale = this.items[index].saleAmount - this.items[index].totalInvestment
    },
    editCompanyValue() {
        this.CValueEdit = true;
        this.editingCValue = this.company_value;
    },
    submitCValue() {
        if (!this.editCompanyValue) {
            this.$toast.error('企業価値をご入力ください。')
        }
        else if (!Number(this.editingCValue) && Number(this.editingCValue) !== 0) {
            this.$toast.error('数字でご入力ください。')
        }
        else if (Number(this.editingCValue) > 1000000000000) {
            this.$toast.error('企業価値は最大10兆円でご入力ください。')
        }
        else {
            this.company_value = this.editingCValue;
        }
        this.CValueEdit = false;
    },
    redirectList() {
        this.$router.push({name: 'Simulation List'})
    },
    async exportSimulation() {
        try {
            const response = await UserService.exportSimulation({corporate_value: this.company_value})
            var fileURL = window.URL.createObjectURL(new Blob([response.data]))
            var fileLink = document.createElement('a')
            fileLink.href = fileURL;
            fileLink.download = 'M&Aシミュレーション.xlsx'
            document.body.appendChild(fileLink)
            fileLink.click()
            this.$toast.success('エクスポートが完了しました')
        } catch (e) {
            this.$toast.error(e.data.message)
        }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.main-content {
    background: $white;
    position: relative;
    box-shadow: 0px 4px 8px rgba(173, 182, 177, 0.1);
    border-radius: 4px;
}
.custom-button {
    color: $corporate_color_1;
    border: 1px solid $corporate_color_1;
    border-radius: 4px;
    cursor: pointer;
}
.saved-simulation {
    position: absolute;
    top: 24px;
    right: 24px;
}
.section-title {
    box-shadow: 0px 1px 0px #F3F4F3;
}
.custom-header {
    background: $light;
}
.total-input {
    border: 1px solid $corporate_color_1;
    box-shadow: -1px -1px 1px rgba(51, 153, 103, 0.1), 1px 1px 1px rgba(51, 153, 103, 0.1);
    border-radius: 4px;
    width: 114px;
    display: inline-block;
}
.total-input input {
    background: #E2E2E2;
    outline: none;
    width: 42px;
}
.CV-input {
    border: 1px solid $corporate_color_1;
    box-shadow: -1px -1px 1px rgba(51, 153, 103, 0.1), 1px 1px 1px rgba(51, 153, 103, 0.1);
    border-radius: 4px;
    display: inline-block;
    width: 228px;
}
.CV-input input {
    outline: none;
    max-width: 100%;
}
.v-data-table {
    border: 1px solid #E2E2E2;
}
.v-data-table .custom-header {
    position: relative;
}
.v-data-table .share-input {
    outline: none;
    border: 1px solid $corporate_color_1;
    filter: drop-shadow(-1px -1px 1px rgba(51, 153, 103, 0.1)) drop-shadow(1px 1px 1px rgba(51, 153, 103, 0.1));
    border-radius: 4px;
    width: 38px;
}
.v-data-table .share-button {
    cursor: pointer;
    color: $corporate_color_1;
}
.button-bar>div:first-of-type {
    background: $corporate_color_1;
}
.td-ellipsis {
    max-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.spacer {
    height: 26px;
}
.no-data {
    color: #888;
    font-size: 32px;
}
</style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
.simulation-screen {
    .v-data-table {
        max-height: 495px !important;
        overflow-y: auto;
        position: relative;
    }
    .v-data-table table {
        width: 100%;
    }
    .v-data-table thead tr {
        position: relative;
    }
    .v-data-table>.v-data-table__wrapper>table>tbody>tr {
        font-weight: bold;
    }
    .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
        font-size: 10px !important;
        height: 56px !important;
        padding: 8px !important;
        .alert {
            color: $structure_color_2 !important;
        }
    }
    .v-data-table>.v-data-table__wrapper>table>tbody>tr:nth-of-type(even) {
        background: #F8F8F8 !important;
    }
    .v-data-table>.v-data-table__wrapper>table>tbody>tr:last-of-type {
        background: #E8EBF8 !important;
        color: #334AC3;
        position: relative;
    }
    .v-data-table>.v-data-table__wrapper>table>tbody>tr:last-of-type>td {
        text-align: center !important;
    }
}
</style>
