<template>
    <div>
        <layout-app :isLogin="false"></layout-app>
        <div class="app-container">
            <div class="content login-content">
                <div class="logo">
                    <img :src="logo" alt="">
                </div>

            <div class="description-form fs-16 font-weight-bold m__top--48 color__primary_black">
                メールを入力してください。
            </div>
            <div class="form-group m__top--48">
                <label class="m__top--32 m__bottom--8">Eメールアドレス</label>
                <input
                    v-model="form.email"
                    class="form-control large"
                    :class="{ 'error' : submitted && $v.form.email.$error }"
                    placeholder="carte@example.com"
                >
                <div v-if="submitted && $v.form.email.$error">
                    <div v-if="!$v.form.email.required" class="error-text">メールは必須です。</div>
                    <div v-else-if="!$v.form.email.email" class="error-text">有効なメールアドレスをご入力ください。</div>
                    <div v-else-if="!$v.form.email.maxLength" class="error-text">255文字以内でご入力ください。</div>
                </div>
            </div>

            <div class="m__top--48">
                <button-component
                    v-if="!$store.getters.isLoading"
                    :text="textButton"
                    :small=false
                    :func="forgotPassword"
                    :width="widthButton"
                ></button-component>
                <div v-if="$store.getters.isLoading" class="donut"></div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import ButtonComponent from "@/components/common/ButtonComponent.vue";
import LayoutApp from "@/components/layouts/Navbar.vue";
import { required, maxLength, email } from "vuelidate/lib/validators";
import { UserService } from '@/services'

export default {
    name: "login-component",
    components : {
        ButtonComponent,
        LayoutApp
    },
    data(){
        return {
            logo : require('@/assets/images/svgs/logo_carte.svg'),
            textButton : "再設定",
            widthButton : "160px",
            form: {
                email: '',
            },
            submitted: false
        }
    },
    validations() {
        return {
            form: {
                email: {
                    required,
                    email,
                    maxLength: maxLength(255)
                },
            }
        }
    },
    methods: {
        forgotPassword() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            const data = {
                email: this.form.email
            }
            UserService.forgotPassword(data)
            .then(() => {
                this.submitted = false;
                this.$toast.success(this.$t("messages.send_mail_reset_password_success"))
                this.$router.push({name: "Login"})
            })
            .catch(() => {
                this.submitted = false;
            })
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            const isAuthenticated = vm.$store.getters.accessToken || !!localStorage.getItem('accessToken')
            if (isAuthenticated) {
                vm.$router.push({name: 'home'})
            }
        })
    }
}
</script>
<style scoped lang="scss">
.description-form {
    line-height: 32px;
}
</style>
