<template>
    <div class="form-select">
        <span class="form-arrow"><img :src="iconArrowDown" alt=""></span>
        <select :name="element" :id="element" :style="{width: `${width}px`}" v-model="selectedValue" :disabled="isDisabled" :class="{'bg-disabled' : isDisabled}">
            <option v-for="data, index in datas" :key="'option'+ index" :value="data.value" :disabled="data.value < getCurrentMonth && isDateTime ? true : false">
                {{ data.text }}
            </option>
        </select>
    </div>
</template>
<script>

export default {
    name : "SelectComponent",
    props : {
        datas : Array,
        element : String,
        width : Number,
        currentValue : Number,
        isDisabled: Boolean,
        isDateTime: Boolean
    },
    watch: {
        selectedValue: function(currentValue) {
            this.$emit('select', currentValue);
        },
        currentValue: function(currentValue) {
            this.selectedValue = currentValue;
        }
    },
    data(){
        return {
            iconArrowDown : require('@/assets/images/icons/ic_arrow_down_active.svg'),
            selectedValue : this.$props.currentValue || null
        }
    },
    computed: {
        getCurrentMonth() {
            return new Date().getMonth() + 1;
        }
    }
}
</script>
<style scope lang="scss">
.form-select select {
    text-align: right;
    padding-right: 35px!important;
    cursor: pointer;
}

.form {
    &-select {
        position: relative;
    }
    &-arrow {
        position: absolute;
        right: 13px;
        top: 50%;
        transform: translateY(-50%);
        line-height: 1;
        cursor: pointer;
    }
}

select > option {
    border: none;
}
.bg-disabled { 
    background-color : #EAEDEB
}
</style>