import ApiService from '@/services/ApiService'

const UserService = {
  login(data) {
    return ApiService.post('/api/login', data)
  },
  investorRegisterOrLogin(data) {
    return ApiService.post('/api/investor/register', data);
  },
  logout() {
    return ApiService.delete('/api/logout')
  },
  register(data) {
    return ApiService.post('/api/register', data)
  },
  signup(params, data) {
    return ApiService.post(`/api/register-step2/${params.id}/user/${params.token}`, data)
  },
  forgotPassword(data) {
    return ApiService.post(`/api/forgot-password`, data)
  },
  resetPassword(data) {
    return ApiService.post(`api/reset-password`, data)
  },
  getUserProfile() {
    return ApiService.get(`api/user/profile`)
  },
  updateUserProfile(data) {
    return ApiService.update(`api/user/profile/edit`, data)
  },
  mailResetPassword(data) {
    return ApiService.post('api/user/send-mail-reset-password', data)
  },
  changePassword(data) {
    return ApiService.post(`api/user/profile/change-password`, data)
  },
  listShareholders() {
    return ApiService.get(`api/user/manager-investor`)
  },
  inviteShareholders(data) {
    return ApiService.post(`api/user/invite-investor`, data)
  },
  getInvestorProfile() {
    return ApiService.get('api/investor/profile')
  },
  updateInvestorProfile(data) {
    return ApiService.update('api/investor/profile/edit', data)
  },
  listStartups() {
    return ApiService.get('api/investor/manager-startup')
  },
  inviteStartups(data) {
    return ApiService.post('api/user/invite-startup', data)
  },
  cancelInviteStartups(data) {
    return ApiService.post('api/user/cancel-invite-startup', data);
  },
  cancelInviteInvestors(data) {
    return ApiService.post('api/user/cancel-invite-investor', data);
  },
  contact(data) {
    return ApiService.post(`api/contact`, data)
  },
  createFinancingInformation(data) {
    return ApiService.post('api/home/financing', data);
  },
  getLastFinancingDetail() {
    return ApiService.get('api/home/financing-check');
  },
  updateFinancingInformation(data) {
    return ApiService.post('api/home/financing-edit', data);
  },
  getCapitalPolicies() {
    return ApiService.get(`api/onboarding/capital-policies`);
  },
  getCapitalPolicy(id) {
    return ApiService.get(`api/onboarding/capital-policies/${id}`);
  },
  createOrUpdateCapitalPolicy(data, id) {
    return id ? ApiService.update(`api/onboarding/capital-policies/${id}`, data) : ApiService.post(`api/onboarding/capital-policies`, data);
  },
  deleteCapitalPolicy(id) {
    return ApiService.delete(`api/onboarding/capital-policies/${id}`);
  },
  importCapitalPolicy(file) {
    return ApiService.post(`api/onboarding/import-sample`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  },
  exportCapitalPolicy() {
    return ApiService.getFile('api/capital-policy/export', {responseType: 'blob'})
  },
  getShareholderStocks() {
    return ApiService.get(`api/onboarding/sum-stock-investors`);
  },
  getFinancingMethods() {
    return ApiService.get(`api/onboarding/financing-methods`);
  },
  readNews() {
    return ApiService.post(`api/read-news`);
  },
  getListNews(params) {
    return ApiService.get(`api/news/all`, params);
  },
  getOneNews(params) {
    return ApiService.get(`api/news/${params.id}/type/${params.type}`);
  },
  deleteAccount(data) {
    return ApiService.post(`api/user/deactivate-account`, data);
  },
  getSimulation() {
    return ApiService.get(`api/ma_simulation`);
  },
  getValueCorporate() {
    return ApiService.get(`api/home/value-corporate`);
  },
  getShareholders() {
    return ApiService.get(`api/home/shareholders`);
  },
  getRatioShareholder() {
    return ApiService.get(`api/home/ratio-shareholder`);
  },
  getHomeListDeals() {
    return ApiService.get(`api/home/list-deals`);
  },
  getHomeListBalances() {
    return ApiService.get(`api/home/list-balances`);
  },
  checkFreee() {
    return ApiService.get(`api/home/check-freee`);
  },
  updateHomeStep(data) {
    return ApiService.post(`api/user/flg-step`, data);
  },
  loginFree() {
    return ApiService.get(`api/login-freee`, {}, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    });
  },
  freeeCallback(query) {
    return ApiService.get(`api/freee-redirect` + query)
  },
  //investor home
  getInvestorAmountShares() {
    return ApiService.get('api/investor/home/amount-shares')
  },
  getInvestorSummary() {
    return ApiService.get('api/investor/home/summary-shareholders')
  },
  getInvestorFinancing() {
    return ApiService.get('api/investor/home/financing')
  },
  getAlertCash() {
    return ApiService.get('api/investor/home/alert-cash')
  },
  getInvestorNews() {
    return ApiService.get('api/investor/home/financing')
  },
  getInvestmentData() {
    return ApiService.get('api/investor/structure/full')
  },
  getStartupCapitalPolicy(id) {
    return ApiService.get(`api/investor/capital-policy/${id}`)
  },
  investorExportPolicy(id) {
    return ApiService.getFile(`api/investor/capital-policy/export/${id}`, {responseType: 'blob'})
  },
  exportSimulation(data) {
    return ApiService.getFile('api/ma_simulation/export-excel', {responseType: 'blob', params: data})
  },
  previewValueCorporate(data) {
    return ApiService.post('api/preview/value-corporate', data)
  },
  previewShareholders(data) {
    return ApiService.post(`api/preview/shareholders`, data);
  },
  previewRatioShareholder(data) {
    return ApiService.post(`api/preview/ratio-shareholder`, data);
  },
  investorSuggest(query) {
    return ApiService.get(`api/investor/suggest`, query);
  },
  startupSuggest(query) {
    return ApiService.get(`api/startup/suggest`, query);
  },
  getValueCorporateStartup(startup_id) {
    return ApiService.get(`api/investor/value-corporate/${startup_id}`);
  },
  getShareholdersStartup(startup_id) {
    return ApiService.get(`api/investor/shareholders/${startup_id}`);
  },
  getRatioShareholderStartup(startup_id) {
    return ApiService.get(`api/investor/ratio-shareholder/${startup_id}`);
  },
  getHomeListDealsStartup(startup_id) {
    return ApiService.get(`api/investor/list-deals/${startup_id}`);
  },
  getHomeListBalancesStartup(startup_id) {
    return ApiService.get(`api/investor/list-balances/${startup_id}`);
  },
}

export default UserService
