<template>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import { xAxisLabel } from "@/commons/plan_const"

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

import { lineChartOption } from "./../../commons/chart";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  name: 'LineChartComponent',
  components: {
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    data: {
      type: Array,
      // default: () => [1, 2, 3, 4]
    },
    labels: {
      type: Array,
      // default: ()=> ['A1', 'A2', 'A3', 'A4']
    },
  },
  data() {
    let chartOptions = lineChartOption;
    return {
      chartData: {
        labels: xAxisLabel,
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#fff',
            borderColor: '#339967',
            data: this.data,
            pointBorderColor: "#fff",
            pointBackgroundColor: "#339967",
            pointStyle: 'circle',
            pointRadius: 5,
            borderWidth: 2,
          }
        ]
      },
      chartOptions
    }
  }
}
</script>
