<template>
    <div class="unmargin investment-list">
        <v-tabs background-color="transparent">
            <v-tab href="#destinations" active-class="active-tab">
                投資先
            </v-tab>
            <v-tabs-slider color="#0D7B3F"></v-tabs-slider>
            <v-tab-item value="destinations" class="trans">
                <v-data-table
                    :headers="headers"
                    :items="investmentList"
                    no-data-text="データがありません。"
                    :items-per-page="-1"
                    :fixedHeader="true"
                    :height="'calc( 100vh - 100px )'"
                    hide-default-footer
                >
                    <template slot="body.append">
                        <tr class="total-class">
                            <td style="flex: 1" class="font-weight-bold">合計</td>
                            <td class="lb1"></td>
                            <td></td>
                            <td></td>
                            <td class="text-right lb1">¥<span class="primary-text font-weight-bold">{{ totalAmount }}</span></td>
                            <td class="lb1"></td>
                            <td class="text-right lb1">¥<span class="primary-text font-weight-bold">{{ totalCapital }}</span></td>
                            <td class="text-right lb1">¥<span class="primary-text font-weight-bold">{{ totalAsset }}</span></td>
                            <td class="lb1"></td>
                        </tr>
                    </template>
                    <template v-slot:[`item.companyName`]="{ item }">
                        <td class="td-ellipsis text-right font-weight-bold">
                            <router-link class="invest-link" :to="{name: 'Startup capital policy', params: {id: item.startup_id}}">
                                <div></div>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on" class="fs-12 ellipsis">{{ item.companyName }}</div>
                                    </template>
                                        <span>{{ item.companyName }}</span>
                                </v-tooltip>
                            </router-link>
                        </td>
                    </template>
                </v-data-table>
            </v-tab-item>
            <!-- <v-tab href="#profit">
                損益
            </v-tab>
            <v-tabs-slider color="#0D7B3F"></v-tabs-slider>
            <v-tab-item value="profit">
                Coming soon
            </v-tab-item> -->
        </v-tabs>
    </div>
</template>
<script>
import { numberWithCommas } from "@/commons/helpers.js";
import { UserService } from '@/services';
export default {
    name: 'InvestmentList',
    data() {
        return {
            prevRoute: null,
            investmentList: [],
            totalAmount: 0,
            totalCapital: 0,
            totalAsset: 0,
            headers: [
                {
                    text: '会社名',
                    value: 'companyName',
                    width: '20%'
                },
                {
                    text: '株式種類',
                    value: 'method',
                    align: 'center'
                },
                {
                    text: '株数',
                    value: 'shares',
                    align: 'right'
                },
                {
                    text: '出資時株価',
                    value: 'investmentPrice',
                    align: 'right'
                },
                {
                    text: '出資金額',
                    value: 'investmentAmount',
                    align: 'right'
                },
                {
                    text: '現在持株比率',
                    value: 'shareRatio',
                    align: 'right'
                },
                {
                    text: '現在時価総額',
                    value: 'marketCapital',
                    align: 'right'
                },
                {
                    text: '現在資産価値',
                    value: 'assetValue',
                    align: 'right'
                },
                {
                    text: '',
                    value: '',
                    width: '10%'
                }
            ]
        }
    },
    created() {
        this.getInvestmentData()
    },
    methods: {
        async getInvestmentData() {
            const { data } = await UserService.getInvestmentData()
            const list = typeof data.data === 'object' ? Object.values(data.data) : data.data;
            list.forEach(item => {
                const prevIndex = this.investmentList.findIndex(element => item.startup_name == element.companyName)
                if (prevIndex === -1) {
                    this.totalCapital += item.capitalization_amounts
                    this.totalAsset += item.value_issets
                }
                this.investmentList.push({
                    startup_id: item.startup_id,
                    companyName: item.startup_name,
                    method: item.method,
                    shares: item.number_of_shares,
                    investmentPrice: '¥' + numberWithCommas(item.stock_price),
                    investmentAmount: item.amount_shares > 0 ? '¥' + numberWithCommas(item.amount_shares) : '¥' + 0,
                    shareRatio: item.ratio_holders + '%',
                    marketCapital: '¥' + numberWithCommas(item.capitalization_amounts),
                    assetValue: '¥' + numberWithCommas(item.value_issets),
                })
                this.totalAmount += item.amount_shares > 0 ? item.amount_shares : 0
            })
            this.totalAmount = numberWithCommas(this.totalAmount)
            this.totalCapital = numberWithCommas(this.totalCapital)
            this.totalAsset = numberWithCommas(this.totalAsset)
        }
    }
}
</script>
<style lang="scss" scoped>
.investment-list {
    .invest-link {
        color: #333333 !important;
        text-decoration: none;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center
    }
}
.trans {
    background: #FBFBFB;
}
.unmargin {
    margin: 0 -121px;
}
.investment-list .lb1 {
    border-left: 1px solid #E2E2E2 !important;
}
.investment-list .primary-text {
    color: #334AC3;
}
.active-tab {
    font-weight: bold;
    color: #333333 !important;
}
.td-ellipsis {
    max-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>
<style lang="scss">
.investment-list .v-slide-group__wrapper {
    padding: 0 120px
}
.investment-list table {
    border-collapse: collapse;
}
.investment-list .v-data-table-header {
    background: #E6F2EC !important;
}
.investment-list .v-data-table-header > tr > th {
    text-align: center !important;
}
.investment-list .v-data-table-header > tr > th > span {
    font-weight: bold !important;
    color: #333333 !important;
}
.investment-list .v-data-table-header > tr > th > i {
    display: none !important;
}
.investment-list thead {
    border-bottom: 4px solid #E2E2E2 !important;
}
.investment-list tr td, .investment-list thead th {
    border-right: 1px solid #E2E2E2 !important;
}
.investment-list tr:last-of-type td {
    border-right: none !important;
}
.investment-list tr:nth-of-type(even) {
    background: #F8F8F8 !important;
}
.investment-list tbody tr:last-of-type {
    background: #E8EAF8 !important;
    border-right: none !important;
}
.investment-list tr:hover {
    background: #F2F9F6 !important;
}
.investment-list tr td:first-of-type {
    font-weight: bold;
}
.investment-list .v-tabs-slider-wrapper {
    height: 4px !important;
}
.total-class{
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}
.app-container {
    margin-bottom: 0 !important;
}
</style>
