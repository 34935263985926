<template>
    <div class="capital-policy-screen">
        <LoadingComponent v-if="loadingData"/>
        <div class="capital-header py-2 p__right--94 p__left--94">
            <div class="capital-action d-flex justify-space-between align-center font-weight-bold fs-14">
                <div class="d-flex align-center">
                    <div class="redirect-back fs-14" @click="$router.go(-1)">
                        <img :src="require('@/assets/images/icons/ic_arrow_lefts.svg')" alt="">
                        <div class="d-inline m__left--8">戻る</div>
                    </div>
                    <div class="title fs-18 font-weight-bold m__left--12">
                        {{ startupName }}
                    </div>
                </div>
                <button-component
                    class="ml-4"
                    :text="'エクスポート'"
                    :small=false
                    :func="exportPolicy"
                    :width="'116px'"
                    :height="'28px'"
                    :mode="'btn-secondary'"
                ></button-component>
            </div>
        </div>
        <div v-if="investorData && investorData.length !== 0" class="main-container" id="main-table" @scroll="handleScroll()">

        <table v-if="!loadingData" id="wrapper" class="wrapper text-center">
            <tr class="chart-row">
                <td class="w-200-px"><div class="w-200-px"></div></td>
                <td v-for="item in investorSummary" :key="item.round_name" colspan="2" class="chart-item round-item py-4">
                    <div class="execution-date fs-12 mb-3">
                        <span>実施日 : {{item.execution_date}}</span>
                    </div>
                    <div class="font-weight-bold">{{item.round_name}}</div>
                    <div class="chart-wrapper d-flex w--100 justify-center">
                        <doughnut-char-component class="doughnut" style="z-index: 2; width: 400px;" :labels="item.labels" :data="item.datas" :backgroundColors="chartColors" :width="200"/>
                         <div class="chart-text font-weight-bold fs-14">創業者<br/>{{item.ratio_founder}}<span class="fs-10">%</span></div>
                    </div>
                    <div class="chart-data d-flex justify-center px-3 py-3 mt-3 font-weight-bold fs-14">
                        <div class="value-title text-right p__right--6">
                            <div>株価</div>
                            <div>調達金額</div>
                            <div>Pre時価総額</div>
                            <div>Post時価総額</div>
                        </div>
                        <div class="value-value p__left--6">
                            <div>{{numberWithCommas(item.stock_price) + '円'}}</div>
                            <div>{{numberWithCommas(item.procurement_amount) + '円'}}</div>
                            <div>{{numberWithCommas(item.pre_amount) + '円'}}</div>
                            <div>{{numberWithCommas(item.post_amount) + '円'}}</div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr class="startup-head font-weight-bold">
                <td rowspan="2" style="border-bottom: 4px solid #E2E2E2; max-width: 120px">
                    <div>株主名</div>
                </td>
                <td colspan="2" v-for="index in investorSummary.length" :key="index">
                    <table>
                        <tr>
                            <td style="border-right: 2px solid #E2E2E2">顕在株式</td>
                            <td style="border-right: 2px solid #E2E2E2">潜在株式</td>
                            <td>潜在＋顕在</td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr class="startup-subhead font-weight-bold">
                <td colspan="2" v-for="index in investorSummary.length" :key="index">
                    <table>
                        <tr>
                            <td>
                                <table>
                                    <tr>
                                        <td>株数</td>
                                        <td>比率</td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td>株数</td>
                                        <td>比率</td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td>株数</td>
                                        <td>比率</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <template v-for="investor, index in investorData">
                <tr class="startup-stock" v-if="investor.name" @mouseover="hoverIndex = index" :key="investor.name" @mouseout="hoverIndex = -1">
                    <td class="font-weight-bold text-left pr-2 pl-2" style="width: 218px; text-overflow: ellipsis; overflow: hidden">
                        <div></div>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" style="width: 200px; text-overflow: ellipsis; overflow: hidden">{{ investor.name }}</div>
                            </template>
                                <span>{{ investor.name }}</span>
                        </v-tooltip>
                    </td>
                    <td colspan="2" v-for="round in investor.rounds" :key="round.round_name">
                        <table class="text-right">
                            <tr>
                                <td>
                                    <table>
                                        <tr>
                                            <td>{{ numberWithCommas(round.hStock.number_of_shares) }}</td>
                                            <td>{{ round.hStock.ratio_holder.toFixed(2) + '%' }}</td>
                                        </tr>
                                    </table>
                                </td>
                                <td>
                                    <table>
                                        <tr>
                                            <td>{{ numberWithCommas(round.lStock.number_of_shares) }}</td>
                                            <td>{{ round.lStock.ratio_holder.toFixed(2) + '%' }}</td>
                                        </tr>
                                    </table>
                                </td>
                                <td>
                                    <table>
                                        <tr>
                                            <td>{{ numberWithCommas(round.lStock.number_of_shares + round.hStock.number_of_shares) }}</td>
                                            <td>{{ round.percent_each_round + '%' }}</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </template>
            <table v-if="investorList && investorList.length !== 0 && !newRound" class="fixed-name" id="fixed-name">
                <tr class="w-200-px"><div class="fixed-chart"></div></tr>
                <td class="fixed-side">
                    <div><strong>株主名</strong></div>
                </td>
                <tr class="startup-stock" v-for="investor, index in investorData" :key="investor.name">
                    <td class="font-weight-bold text-left pr-2 pl-2" style="width: 218px; text-overflow: ellipsis; overflow: hidden"  :class="{ 'background-hover': hoverIndex === index }">
                        <div></div>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" style="width: 200px; text-overflow: ellipsis; overflow: hidden">{{ investor.name }}</div>
                            </template>
                                <span>{{ investor.name }}</span>
                        </v-tooltip>
                    </td>
                </tr>
            </table>
        </table>

    </div>
    <div v-if="(!investorData || investorData.length == 0) && !loadingData" class="no-data d-flex justify-center align-center">
        資本政策をまだ作成していません。
    </div>
    </div>
</template>
<script>
import DoughnutCharComponent from '@/components/charts/DoughnutChartComponent.vue'
import ButtonComponent from '@/components/common/ButtonComponent.vue'
import LoadingComponent from '@/components/common/Loading.vue'
import { UserService } from '@/services'
import { numberWithCommas, formatDate } from '@/commons/helpers.js'

export default {
    name: 'InvestorPolicy',
    components: {
        DoughnutCharComponent,
        ButtonComponent,
        LoadingComponent
    },
    data() {
        return {
            policyModal: false,
            policyFiles: null,
            roundNums: 3,
            newRound: false,
            rounds: [],
            roundModal: false,
            capitalChoice: 'all',
            specOption: {
                capital: false,
                composition: false,
                postComposition: false
            },
            investorList: [],
            investorData: [],
            investorSummary: [],
            loadingData: false,
            changeType: '',
            startupId: 0,
            hoverIndex: -1,
            chartColors: ['#66b28d', '#7cccd2', '#b49cf8', '#e7e7e7'],
            prevRoute: null,
            startupName: '',
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from.name || 'home'
        })
    },
    created() {
        this.getData();
    },
    methods: {
        handleScroll() {
            const offsetLeft = document.getElementById('main-table').scrollLeft
            const fixedName = document.getElementById('fixed-name')
            fixedName.style.left = offsetLeft + 'px'
        },
        checkWidth() {
            const wrapper = document.getElementById('wrapper')
            const table = document.getElementById('main-table')
            if (wrapper.offsetWidth <= table.offsetWidth) {
                const fixedName = document.getElementById('fixed-name')
                fixedName.style.display = 'none'
            }
        },
        numberWithCommas(number) {
            return numberWithCommas(number)
        },
        formatDate(date) {
            return formatDate(date)
        },
        getData() {
            this.loadingData = true
            this.startupId = this.$route.params.id || 0
            UserService.getStartupCapitalPolicy(this.startupId).then(res => {
                const data = res.data.data
                const rounds = data.investor_rounds
                this.startupName = data.startup_name
                if (rounds.length == 0) {
                    this.loadingData = false
                    return
                }
                rounds[rounds.length - 1].forEach(item => {
                    const prevIndex = this.investorList.findIndex(element => element.investor_name == item.investor_name)
                    if (prevIndex == -1 || this.investorList.length == 0) {
                        this.investorList.push({investor_name: item.investor_name})
                    }
                })
                const round_info = data.round_info

                //get all round
                round_info.forEach(round => {
                    this.rounds.push({
                        id: round.capital_table_id,
                        round_name: round.round_name
                    })
                })

                //format data of each shareholder
                this.investorList.forEach(name => {
                    let investor = {
                        name: name.investor_name,
                        rounds: []
                    }
                    rounds.forEach((round, round_index) => {
                        let healthyStock = {
                            number_of_shares: 0,
                            ratio_holder: 0
                        }
                        let latentStock = {
                            number_of_shares: 0,
                            ratio_holder: 0
                        }
                        round.forEach(item => {
                            if (item.investor_name == name.investor_name) {
                                if (item.type == 1) {
                                    healthyStock.number_of_shares += item.number_of_shares
                                    healthyStock.ratio_holder += item.ratio_holder
                                }
                                else {
                                    latentStock.number_of_shares += item.number_of_shares
                                    latentStock.ratio_holder += item.ratio_holder
                                }
                            }
                        })
                        let totalStockRound = healthyStock.number_of_shares + latentStock.number_of_shares;
                        investor.rounds.push({
                            round_name: round_info[round_index].round_name,
                            hStock: {...healthyStock},
                            lStock: {...latentStock},
                            percent_each_round: parseFloat(totalStockRound / round_info[round_index].sum_all_stock * 100).toFixed(2)
                        })
                    }),
                    this.investorData.push(investor)
                })

                // format chart data
                let chartArr = []
                let totalRemainStock = []
                rounds.forEach((round) => {
                    let chartData = []
                    let rStock = 0
                    this.investorList.forEach(investor => {
                        let stock = 0
                        round.forEach(item => {
                            if (item.investor_name == investor.investor_name && item.type == 1) {
                                stock += item.number_of_shares
                            }
                        })
                        chartData.push({
                            name: investor.investor_name,
                            stock
                        })
                    })
                    round.forEach(item => {
                        if (item.type == 1) {
                            rStock += item.number_of_shares
                        }
                    })
                    totalRemainStock.push(rStock)
                    chartData = chartData.sort((a, b) => b.stock - a.stock)
                    chartArr.push(chartData)
                })
                chartArr.forEach((item, chart_index) => {
                    let chartObj = {
                        labels: [],
                        datas: []
                    }
                    let remainStock = totalRemainStock[chart_index]
                    item.some((element, index) => {
                        if (index != 3) {
                            remainStock -= element.stock
                            chartObj.labels.push(element.name)
                            chartObj.datas.push(element.stock)
                        }
                        else {
                            chartObj.labels.push('その他')
                            chartObj.datas.push(remainStock)
                            return true
                        }
                    })
                    this.investorSummary.push({
                        ...round_info[chart_index],
                        ...chartObj
                    })
                })
                this.loadingData = false
            }).catch((err) => {
                this.$toast.error(err.message)
                this.loadingData = false
            })
        },
        async exportPolicy() {
            try {
                const response = await UserService.investorExportPolicy(this.$route.params.id)
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.download = `資本政策${this.$route.params.id}.xlsx`
                document.body.appendChild(fileLink);
                fileLink.click()
                this.$toast.success('エクスポートが完了しました')
            } catch (e) {
                this.$toast.error(e.data.message)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
table {
    border-collapse: collapse;
    table-layout: fixed;
}
.startup-head table,
.startup-subhead table,
.startup-stock table {
    table-layout: fixed;
    word-wrap: break-word;
    width: 100%;
}
.startup-stock:hover {
    background: #daffef !important;
}
table.wrapper {
    border-top: 1px solid #E2E2E2
}
td {
    padding: 0 8px;
    line-height: 1.7;
    white-space: nowrap;
}
td.chart-item,
.startup-head > td,
.startup-subhead > td,
.angel-subhead > td,
.manager-subhead > td {
    border-left: 4px solid #E2E2E2;
    background: #F8F8F8;
}
td.chart-item {
    background: white;
    min-width: 350px;
}
.startup-head > td > table > tr {
    border-bottom: 1px solid #E2E2E2;
}
tr.startup-head > td, tr.angel-subhead > td, tr.manager-subhead > td{
    border-top: 4px solid #E2E2E2;
    padding: 0;
}
tr.startup-subhead > td, tr.angel-subhead > td, tr.manager-subhead > td {
    border-bottom: 4px solid #E2E2E2;
}
.angel-subhead > td:first-of-type,
.manager-subhead > td:first-of-type,
.startup-head > td:first-of-type {
    border-left: none;
}
tr.startup-stock, tr.angel-stock, tr.manager-stock {
    border-bottom: 1px solid #E2E2E2;
}
tr.manager-stock:last-of-type,
.startup-stock > td:last-of-type,
.angel-stock > td:last-of-type,
.manager-stock > td:last-of-type {
    border: none
}
.startup-stock > td,
.angel-stock > td,
.manager-stock > td {
    border-right: 4px solid #E2E2E2;
    padding: 0;
    table > tr > td {
        border-right: 2px solid #E2E2E2;
    }
    table > tr > td > table > tr > td {
        border-right: 1px solid #E2E2E2;
    }
    table > tr > td:last-of-type {
        border-right: none
    }
    table > tr > td > table > tr > td:last-of-type {
        border-right: none
    }
}
.startup-stock > td > table > tr > td,
.angel-stock > td > table > tr > td,
.manager-stock > td > table > tr > td {
    padding: 0;
}
.startup-subhead > td,
.angel-subhead > td,
.manager-subhead > td {
    padding: 0;
    table > tr > td {
        border-right: 2px solid #E2E2E2;
        padding: 0;
    }
    table > tr > td > table > tr > td {
        border-right: 1px solid #E2E2E2;
        padding: 0;
    }
    table > tr > td:last-of-type {
        border-right: none
    }
    table > tr > td > table > tr > td:last-of-type {
        border-right: none
    }
}
.capital-header {
    background: #F8F8F8;
    margin: 0 -121px;
}
.fixed-name {
    position: absolute;
    top: 0;
    z-index: 3;
    td {
        background: #F8F8F8;
        border-right: 4px solid #E2E2E2 !important;
    }
    .fixed-side {
        border: 4px solid rgb(226, 226, 226);
        padding: 14.5px 8px;
        border-left: none !important;
    }
    .fixed-chart {
        height: 524px;
        background: #F8F8F8;
        width: calc(100% + 2px);
        border-right: 4px solid rgb(226, 226, 226);
        border-top: 1px solid rgb(226, 226, 226);
    }
}
/* .capital-choices {
    .custom-radio {
        display: block;
        position: relative;
        padding-left: 38px;
        margin-bottom: 8px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
         .checkmark {
            position: absolute;
            top: 10px;
            left: 10px;
            height: 18px;
            width: 18px;
            border-radius: 50%;
            border: 1px solid $key_line_color_1;
            background-color: $white
        }
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        input:checked ~ .checkmark:after {
            display: block;
        }
        .checkmark:after {
            top: 3px;
            left: 3px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $corporate_color_1;
        }
    }
    .custom-checkbox {
        display: block;
        position: relative;
        padding-left: 38px;
        margin-bottom: 8px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
         .checkmark {
            position: absolute;
            top: 10px;
            left: 10px;
            height: 18px;
            width: 18px;
            border-radius: 2px;
            border: 1px solid $key_line_color_1;
            background-color: $white
        }
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        input:checked ~ .checkmark:after {
            display: block;
        }
        .checkmark:after {
            top: 2px;
            left: 2px;
            width: 12px;
            height: 12px;
            border-radius: 2px;
            background-color: $corporate_color_1;
        }
    }
    .blur {
        color: #C2C2C2;
    }
} */
.background-hover {
    background: #daffef !important;
}
.custom-button {
    color: $corporate_color_1;
    border: 1px solid $corporate_color_1;
    border-radius: 4px;
    cursor: pointer;
    width: 116px;
    height: 28px;
    background: $white;
}
.execution-date {
    font-weight: 400;
    span {
        background: #F8F8F8;
        border-radius: 4px;
        padding: 4px
    }
}
.chart-wrapper {
    position: relative;
    .chart-text {
        position: absolute;
        bottom: 42%;
        z-index: 1;
    }
}
.chart-data {
    background: #F8F8F8;
}

.no-data {
    width: 100%;
    height: calc(100vh - 135px);
    color: #888;
    font-size: 32px;
}
</style>
<style scoped lang="scss">
.main-container {
    margin : 0 -121px;
    overflow-x: auto;
    position: relative;
    padding-bottom: 4px;
    margin-bottom: -40px;
    height: calc(100vh - 96px);
}
.main-container::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

.main-container::-webkit-scrollbar-thumb {
  background: #E2E2E2;
  border-radius: 9999px;
  border: 1px solid white;
  background-clip: padding-box;
}

.main-container::-webkit-scrollbar-track {
  background: white;
  border-radius: 4px;
  width: 2px;
}
.doughnut > div {
    max-width: 200px;
    /* max-height: 200px; */
    height: 100%;
    canvas {
        height: 100%;
    }
}
</style>
<style lang="scss" scoped>
.v-dialog__content {
    z-index: 10005 !important;
}
.v-dialog__content--active {
    z-index: 10007 !important;
}
.v-overlay {
    z-index: 10004 !important;
}
</style>
