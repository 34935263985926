<template>
    <div>
        <layout-app :isLogin="false"></layout-app>
        <div class="app-container">
            <div class="content login-content">
                <div class="logo">
                    <img :src="logo" alt="">
                </div>

            <div class="description-form fs-16 font-weight-bold m__top--48 color__primary_black">
                新しいパスワードを入力してください。
            </div>
            <div class="form-group m__top--48">
                <label class="m__top--32 m__bottom--8">新パスワードを入力してください</label>
                <input
                    v-model="form.password"
                    class="form-control large"
                    placeholder="パスワード"
                    type="password"
                    :class="{ 'error' : submitted && $v.form.password.$error }"
                >
                <div v-if="submitted && $v.form.password.$error">
                    <div v-if="!$v.form.password.required" class="error-text">
                        {{ $t('validation.reset_password.password.required')}}
                    </div>
                    <div v-else-if="!$v.form.password.minLength" class="error-text">
                        {{ $t('validation.reset_password.password.min-length')}}
                    </div>
                    <div v-else-if="!$v.form.password.maxLength" class="error-text">
                        {{ $t('validation.reset_password.password.max-length')}}
                    </div>
                    <div v-else-if="$v.form.password.minLength">
                        <div v-if="!$v.form.password.alphaNum" class="error-text">
                            {{ $t('validation.reset_password.password.alpha-number')}}
                        </div>
                    </div>
                </div>
                <label class="m__top--32 m__bottom--8">確認のためもう一度新パスワードをご入力ください</label>
                <input
                    v-model="form.confirm_password"
                    class="form-control large"
                    placeholder="パスワード"
                    type="password"
                    :class="{ 'error' : submitted && $v.form.confirm_password.$error }"
                >
                <div v-if="submitted && $v.form.confirm_password.$error">
                    <div v-if="!$v.form.confirm_password.required" class="error-text">
                        {{ $t('validation.reset_password.confirm_password.required')}}
                    </div>
                    <div v-else-if="!$v.form.confirm_password.sameAs" class="error-text">
                        {{ $t('validation.reset_password.confirm_password.same-as')}}
                    </div>
                </div>
            </div>

            <div class="m__top--48">
                <button-component
                    v-if="!$store.getters.isLoading"
                    :text="textButton"
                    :small=false
                    :func="changePassword"
                    :width="widthButton"
                ></button-component>
                <div v-if="$store.getters.isLoading" class="donut"></div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import ButtonComponent from "@/components/common/ButtonComponent.vue";
import LayoutApp from "@/components/layouts/Navbar.vue";
import { UserService } from "@/services";
import { required, minLength, sameAs, alphaNum, maxLength } from "vuelidate/lib/validators";


export default {
    name: "login-component",
    components : {
        ButtonComponent,
        LayoutApp
    },
    data(){
        return {
            logo : require('@/assets/images/svgs/logo_carte.svg'),
            textButton : "再設定",
            widthButton : "160px",
            form: {
                password: '',
                confirm_password: ''
            },
            submitted: false
        }
    },
    validations() {
        return {
            form: {
                password: {
                    required,
                    alphaNum,
                    minLength: minLength(8),
                    maxLength: maxLength(255),
                },
                confirm_password: {
                    required,
                    sameAs: sameAs(function() {
                        return this.form.password
                    })
                }
            }
        }
    },
    methods: {
        changePassword() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            const data = {
                password: this.form.password,
                password_confirmation: this.form.confirm_password,
                token: this.$route.query.token
            }
            UserService.resetPassword(data)
            .then(() => {
                this.submitted = false;
                this.$toast.success(this.$t("messages.reset_password_success"))
                this.$router.push({name: 'Login'})
            })
            .catch(() => {
                this.submitted = false;
            })
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            const isAuthenticated = vm.$store.getters.accessToken || !!localStorage.getItem('accessToken')
            if (vm.$route.query.token) {
                if (isAuthenticated) {
                    vm.$router.push({name: 'Logged reset password', query: {token: vm.$route.query.token}})
                }
            } else {
                vm.$router.push({name: 'home'})
            }
        })
    }
}
</script>
<style scoped lang="scss">
.description-form {
    line-height: 32px;
}
</style>
