<template>
  <div class="box-contact">
    <div class="box-content-contact">
        <img :src="logo" alt="" class="m__top--96">
        <p class="fs-32 font-weight-bold m__top--48">退会が完了しました。</p>
        <p class="fs-20 font-weight-regular m__top--16">今までのご利用ありがとうございました。<br>
        再びお客様のお役に立てる日をお待ちしております。</p>
        <div class="d-flex justify-center m__top--48">
          <button-component
              :text="'OK'"
              :small=false
              :func="redirectLogin"
              :mode="'btn-primary'"
              :width="'150px'">
            </button-component>
        </div>
    </div>
  </div>
</template>
<script>
import ButtonComponent from "@/components/common/ButtonComponent.vue";
import {
  ACTION_USER_LOGOUT
} from '@/stores/auth_users/actions'
export default {
  name: "DeleteAccount",
  components : {
        ButtonComponent,
    },
  data() {
    return {
        logo : require('@/assets/images/svgs/logo_carte.svg'),
    }
  },
  methods: {
    redirectLogin() {
      this.$store.dispatch(ACTION_USER_LOGOUT)
        .then(() => {
          this.$router.go(0)
        })
        .catch(() => {})
    }
  }
  
}
</script>
<style scoped lang="scss">
.box-contact {
    margin-top: 64px;
    .box-content-contact {
        background: #FFFFFF;
        box-shadow: 0 4px 8px rgba(173, 182, 177, 0.1);
        border-radius: 4px;
        padding: 24px 40px 120px;
        text-align: center;
    }
}
</style>